/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Init from "./init";
import { useState } from "react";
import Rodape from "../home/rodape";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Step3 from "./step3";
import header from "../home/header";
import "../style.css";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";

import { Dados } from "../pojos/Dados";

export default function Steps() {
  const [activeStep, setActiveStep] = useState(0);
  const [dados, setDados] = React.useState(new Dados());
  const [cantNext, setCantNext] = React.useState(false);
  const [erros, setErros] = React.useState<string[]>([]);
  const [stepsSkiped, setStepsSkiped] = React.useState<number[]>([]);
  const [steps,setSteps] = React.useState<
    {
      id?: number;
      label?: string;
      stepProps?: { completed?: boolean; };
    }[]
  >([]);
  const [lastStep, setLastStep] = React.useState(4);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const importAndClose = () => {
    const localData = localStorage.getItem("dados");
    if (localData) {
      setDados(JSON.parse(localData));
    }
    setOpen(false);
  };

  const montaSteps = () => {
    for (let i = 0; i < 10; i++) {
      const step: {
        id?: number;
        label?: string;
        
      } = {};

      step.id = i;
      step.label = "";
      steps.push(step);
    }
  };



  const skipSteps = () => {
    let skiped= [];
    if (!dados.objetivo) {
      return;
    }
    if (!dados.objetivo.equilibrio) {
      skiped.push(3);
    } 
    if (!dados.objetivo.internet) {
      skiped.push(4);
    } 
    if (!dados.objetivo.privacidade) {
      skiped.push(5);
    }
    if (!dados.objetivo.tempo) {
      skiped.push(6);
    }
    if (!dados.objetivo.conversar) {
      skiped.push(7);
    }
    if (!dados.objetivo.folga) {
      skiped.push(8);
    }
    if (skiped.length === 6) {
      skiped.push(9);
    }

    let num = 3;
    for(let i = 3; i < 9; i++){
      if(!skiped.includes(i)){
        num = i;
      }
    }
    setLastStep(num);
    setStepsSkiped(skiped);
    setSteps(steps);
  };

  const enableNext = () => {
    var continuar = true;
    setErros([]);
    var err = [];
    if (activeStep === 1) {
      if (!dados.familia || dados.familia === "") {
        continuar = false;
        err.push("Familia não pode ser vazio");
      }
      if (dados.membros.length === 0) {
        continuar = false;
        err.push("Precisa adicionar membros");
      } else {
        let memberNoname = dados.membros.some(
          (membro) => !membro.nome || membro.nome === ""
        );
        if (memberNoname) {
          continuar = false;
          err.push("Nome do membro não pode ser vazio");
        }
      }
    }
    if (activeStep === 2) {
      if (
        !dados.objetivo.conversar &&
        !dados.objetivo.equilibrio &&
        !dados.objetivo.folga &&
        !dados.objetivo.internet &&
        !dados.objetivo.privacidade &&
        !dados.objetivo.tempo
      ) {
        continuar = false;
        err.push("Precisa selecionar pelo menos um objetivo");
      }
    }
    setCantNext(false);
    if (!continuar) {
      setErros(err);
    }
    return continuar;
  };

  React.useEffect(() => {
    const localData = localStorage.getItem("dados");
    if (localData) {
      let dt = JSON.parse(localData);
      if (dt.membros) {
        handleClickOpen();
      }
    }
    if (!steps || steps.length === 0) {
      montaSteps();
    }
  }, []);
  const updateDados = () => {
    enableNext();
    skipSteps();
  };

  const saveDados = () => {
    localStorage.setItem("dados", JSON.stringify(dados));
    updateDados();
  };

  const handleNext = () => {
    saveDados();
    if (!enableNext()) {
      return false;
    }
    let nextStep = activeStep + 1;
    if (nextStep > 2) {
      if (nextStep === 3 && !dados.objetivo.equilibrio) {
        nextStep = nextStep + 1;
      }
      if (nextStep === 4 && !dados.objetivo.internet) {
        nextStep = nextStep + 1;
      }
      if (nextStep === 5 && !dados.objetivo.privacidade) {
        nextStep = nextStep + 1;
      }
      if (nextStep === 6 && !dados.objetivo.tempo) {
        nextStep = nextStep + 1;
      }
      if (nextStep === 7 && !dados.objetivo.conversar) {
        nextStep = nextStep + 1;
      }
      if (nextStep === 8 && !dados.objetivo.folga) {
        nextStep = nextStep + 1;
      }
    }
    setErros([]);
    setActiveStep(nextStep);
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Rolagem para o topo
    enableNext();
  };

  const handleBack = () => {
    let nextStep = activeStep - 1;
    if (nextStep > 2) {
      if (nextStep === 8 && !dados.objetivo.folga) {
        nextStep = nextStep - 1;
      }
      if (nextStep === 7 && !dados.objetivo.conversar) {
        nextStep = nextStep - 1;
      }
      if (nextStep === 6 && !dados.objetivo.tempo) {
        nextStep = nextStep - 1;
      }
      if (nextStep === 5 && !dados.objetivo.privacidade) {
        nextStep = nextStep - 1;
      }
      if (nextStep === 4 && !dados.objetivo.internet) {
        nextStep = nextStep - 1;
      }
      if (nextStep === 3 && !dados.objetivo.equilibrio) {
        nextStep = nextStep - 1;
      }
    }
    setErros([]);
    setActiveStep(nextStep);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Rolagem para o topo
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <Init />;
      case 1:
        return <Step3 setDados={setDados} dados={dados} />;
      case 2:
        return <Step4 setDados={setDados} dados={dados} skipSteps={skipSteps}/>
      case 3:
        return <Step5 setDados={setDados} dados={dados} />;
      case 4:
        return <Step6 setDados={setDados} dados={dados} />;
      case 5:
        return <Step7 setDados={setDados} dados={dados} />;
      case 6:
        return <Step8 setDados={setDados} dados={dados} />;
      case 7:
        return <Step9 setDados={setDados} dados={dados} />;
      case 8:
        return <Step10 setDados={setDados} dados={dados} />;
      case 9:
        return <Step11 dados={dados} />;
      default:
        return "Etapa desconhecida";
    }
  };

  return (
    <div className="bg-CinzaAzul ">
      {header()}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Dialog className="mobile-lg:w-[100vw]"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Importar Configurações"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja importar as configurações anteriores?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Não
            </Button>
            <Button onClick={importAndClose} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ width: "60vw" }}>
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}></Typography>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  <div className="bg-white h-12 w-12 mb-1 ml-5 rounded-full flex justify-center items-center ">
                    <NavigateBeforeIcon style={{ color: "#123456" }} />
                  </div>
                </Button>
              )}
                <Box sx={{ flex: "1 1 auto"}}>{erros.map((erro, index) => {
                      return (
                        <span
                          className="flex text-red pt-3 max-w-[100%]" 
                          key={index}
                        >
                          {erro}
                        </span>
                      );
                    })}</Box>


              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== steps.length - 1 && (
                <div>
                  {activeStep === lastStep && activeStep > 2 ?(  <button className="mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] flex justify-center items-center font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9] " onClick={handleNext} disabled={cantNext}>
                      FINALIZAR
                    </button>
                  ):     (<Button onClick={handleNext} disabled={cantNext}>
                    <div className="bg-white h-12 w-12 mb-1 ml-5 rounded-full flex justify-center items-center">
                      <NavigateNextIcon style={{ color: "#123456" }} />
                    </div>
                    </Button>
                  )}
                </div>
              )}
            </Box>
          </React.Fragment>

          <Stepper
            activeStep={activeStep}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {steps.map((step, index) => {
              return (
                <Step
                  key={index}
                  disabled={stepsSkiped.includes(index)}
                  last={index === lastStep}
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <StepLabel optional={false}  StepIconProps={{
                    classes: {
                      error: "MuiStepIcon-root-Mui-error",
                    }
                  }}></StepLabel>
                </Step>
              );
            })}
            <style>{`

        /* Remova o conector do Stepper */

  
        .MuiStepConnector-root {
          display: none;
          width: 1vw;
        }
        .MuiStepIcon-text {
          font-size: 0;
          width: 1vw;
        }
        .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
          color: white;
          width: 1vw;
      }

      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: #2b1392;
        width: 1vw;
    }
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
      color: #9085bf;
      width: 1vw;
    }
    .css-a1sg1c-MuiButtonBase-root-MuiButton-root:hover,
    .css-a1sg1c-MuiButtonBase-root-MuiButton-root:active {
      background-color: transparent;
    }

    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover,
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:active {
      background-color: transparent;
    }

    .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover,
    .css-78trlr-MuiButtonBase-root-MuiIconButton-root:active {
      background-color: transparent;
    }

    .text-red {
      color: red;
      text-align: right;
    }

      `}</style>
          </Stepper>
        </Box>
      </div>
      <div className="mt-32">{Rodape()}</div>
    </div>
  );
}

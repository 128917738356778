import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step8Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step8: React.FC<Step8Props> = ({ dados, setDados }) => {
  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };

  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.tempo;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.tempo.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.tempo.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: { ...dados.pergunta, tempo: [...dados.pergunta.tempo, ""] },
    });
  };

  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
    <div className="flex mt-10">
      <div>
        <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
      </div>

      <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Quando, onde, com quem e por quanto tempo
        </span>
      </div>

      <div id="cards" className="m-0 p-0">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="distracao"
          titulo="Manter as refeições sem tela."
          porque={
            <div>
              <p>
                Crianças e pais podem querer usar dispositivos durante as
                refeições para entretenimento ou distração. No entanto, isso{" "}
                <u>
                  <a
                    href="https://www.healthychildren.org/English/family-life/Media/Pages/Food-and-TV-Not-a-Healthy-Mix.aspx"
                    title="https://www.healthychildren.org/English/family-life/Media/Pages/Food-and-TV-Not-a-Healthy-Mix.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    pode levar as crianças não serem capazes de ler seus
                    próprios sinais de saciedade
                  </a>
                </u>{" "}
                e acabar comendo porções maiores bem como{" "}
                <u>
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9317201/"
                    title="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9317201/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    alimentos menos saudáveis
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Tenha uma cesta ou caixa onde os dispositivos possam ser
                colocados antes das refeições e que vai ser deixada longe da
                mesa. Dessa forma, eles não serão uma tentação. 
              </p>
              <p>
                Você também pode tentar alterar as configurações de Wi-Fi para
                que os dispositivos não funcionem durante o período das
                refeições - ou outros momentos livres de internet que desejar. 
              </p>
              <p>
                 Para tornar as refeições mais interessantes, encontre um jogo
                de conversa ou falem sobre seus "altos" e "baixos" do dia e
                também façam perguntas abertas. Você pode ter muito mais
                detalhes do que apenas perguntar "como foi a escola hoje?"
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="sono"
          titulo="Manter os quartos sem tela à noite."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/26779564/"
                    title="https://pubmed.ncbi.nlm.nih.gov/26779564/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crianças e adolescentes que possuem televisão, celular e
                    tablet nos quartos apresentam risco aumentado para
                    distúrbios do sono.
                  </a>
                </u>
              </p>
              <p>
                Já tem até{" "}
                <u>
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/24394730/"
                    title="https://pubmed.ncbi.nlm.nih.gov/24394730/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    associação entre o número de dispositivos no quarto e a
                    qualidade do sono de adolescentes
                  </a>
                </u>
                . 
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Recarregue os dispositivos durante a noite, de preferência, fora
                do quarto do seu filho e em um local previsível da casa - um
                escritório, por exemplo.
              </p>
              <p>
                Altere também as configurações de rede Wi-Fi ou da família para
                que o Wi-Fi seja desligado em um determinado horário da noite.
              </p>
              <p>
                Se seu filho já tem o hábito de usar vídeos para adormecer,
                tente mudar para áudiolivros, música relaxante, meditação em
                áudio ou aplicativos para dormir.{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CelyAwVM95_/?igshid=MzRlODBiNWFlZA=="
                    title="https://www.instagram.com/p/CelyAwVM95_/?igshid=MzRlODBiNWFlZA=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                você pode ver indicações de podcasts de histórias.
              </p>
              <p>
                Na hora antes de dormir, tenha uma rotina que pode incluir um
                banho morno, leitura de alguns livros, aconchego e luzes mais
                fracas.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="dirigir"
          titulo="Não usar o celular enquanto estiver dirigindo."
          porque={
            <div>
              <p>
                O{" "}
                <u>
                  <a
                    href="https://agenciabrasil.ebc.com.br/radioagencia-nacional/geral/audio/2022-09/uso-do-celular-ao-dirigir-ja-e-3a-causa-de-acidentes-de-transito"
                    title="https://agenciabrasil.ebc.com.br/radioagencia-nacional/geral/audio/2022-09/uso-do-celular-ao-dirigir-ja-e-3a-causa-de-acidentes-de-transito"
                    target="_blank"
                    rel="noreferrer"
                  >
                    uso do celular ao dirigir já é a 3ª causa de acidentes de
                    trânsito
                  </a>
                </u>
                .
              </p>
              <p>
                Como responsável, evite usar o telefone enquanto dirige e dê o
                exemplo.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Certifique-se de ativar os textos "não perturbe" enquanto
                estiver dirigindo (disponível na maioria dos telefones).
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="ambiente"
          titulo="Manter momentos de caminhada ao livre ou nos ambientes sem telefones."
          porque={
            <div>
              <p>
                A caminhada distraída - porque seu filho está vendo algo no
                tablet, jogando ou falando com um amigo ou porque você está
                mandando uma mensagem, assistindo uns stories ou um vídeo que te
                mandaram - podem causar o fenômeno de
                <u>
                  <a
                    href="https://www.omag.org/news/2018/1/2/distracted-walking-a-major-working-safety-concern#:~:text=Common%20risks%20associated%20with%20distracted,and%20injuries%20to%20someone%20else."
                    title="https://www.omag.org/news/2018/1/2/distracted-walking-a-major-working-safety-concern#:~:text=Common%20risks%20associated%20with%20distracted,and%20injuries%20to%20someone%20else."
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    “cegueira desatenta”
                  </a>
                </u>
                ; o cérebro humano só pode se concentrar adequadamente em uma
                tarefa por vez. Portanto, quando você está enviando mensagens de
                texto ou falando ao telefone e tentando andar, não pode dar
                atenção total a ambas as tarefas. 
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Enquanto caminha com seu bebê pela rua ou em torno de uma loja,
                fale em voz alta com ele. Descreva o que você acha que ele está
                vendo e o que você está fazendo.
              </p>
              <p>
                Para crianças pequenas e pré-escolares que estão construindo seu
                vocabulário, peça-lhes que busquem coisas que você nomeie. Ou dê
                a eles um trabalho como colocar itens resistentes no carrinho.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="adiar"
          titulo="Adiar o uso de redes sociais até os 13 anos ou mais, com o apoio dos pais."
          porque={
            <div>
              <p>
                As plataformas de mídia social são espaços de expressão pessoal
                e podem não ser muito claras nos seus regulamentos. O conteúdo
                nem sempre é adequado para crianças. De fato,{" "}
                <u>
                  <a
                    href="https://pt-br.facebook.com/help/157793540954833"
                    title="https://pt-br.facebook.com/help/157793540954833"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </u>
                ,{" "}
                <u>
                  <a
                    href="https://about.instagram.com/pt-br/blog/announcements/new-ways-to-verify-age-on-instagram"
                    title="https://about.instagram.com/pt-br/blog/announcements/new-ways-to-verify-age-on-instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </u>
                ,{" "}
                <u>
                  <a
                    href="https://support.tiktok.com/pt_BR/safety-hc/account-and-user-safety/for-parents-and-guardians"
                    title="https://support.tiktok.com/pt_BR/safety-hc/account-and-user-safety/for-parents-and-guardians"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TikTok
                  </a>
                </u>{" "}
                e até o{" "}
                <u>
                  <a
                    href="https://support.google.com/youtubekids/answer/7124142?hl=pt-BR"
                    title="https://support.google.com/youtubekids/answer/7124142?hl=pt-BR"
                    target="_blank"
                    rel="noreferrer"
                  >
                    YouTube
                  </a>
                </u>{" "}
                limitam o acesso aos seus serviços antes dos 13 anos.
              </p>
              <p>
                Está claramente escrito em seus termos de uso, e
                <u>
                  <a
                    href="https://data.parliament.uk/writtenevidence/committeeevidence.svc/evidencedocument/science-and-technology-committee/social-media-and-mental-health/written/81105.pdf"
                    title="https://data.parliament.uk/writtenevidence/committeeevidence.svc/evidencedocument/science-and-technology-committee/social-media-and-mental-health/written/81105.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    crianças com menos de 13 anos desenvolvem mais problemas com
                    o uso da mídia quando iniciam a mídia social muito cedo.
                  </a>
                </u>
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Tenha em mente porque esses sites não são recomendados antes dos
                13 anos. Uma das razões é que é difícil antes dessa idade julgar
                os riscos e saber se alguém tem boas ou más intenções. Mas há
                muitos usos digitais alternativos interessantes antes dos 13
                anos, como sites, jogos ou aplicativos de arte.
              </p>
              <p>
                Se seu filho iniciar uma conta de mídia social, esteja pronto
                para apoiá-lo e agir como um mentor. Eles precisarão
                especialmente de ajuda para estabelecer limites, descobrir e
                gerenciar conflitos online ou conteúdo chocante e identificar
                informações erradas. E para mais informações de configurações,
                recomendações e o que fazer caso algo aconteça com o seu filho
                online, consulte nosso{" "}
                <u>
                  <a
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="escola"
          titulo="Ter um plano sobre dispositivos na escola."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.proquest.com/openview/d83d3f73b0b1d9f8483d6e667c4ff07e/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y"
                    title="https://www.proquest.com/openview/d83d3f73b0b1d9f8483d6e667c4ff07e/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y"
                    target="_blank"
                    rel="noreferrer"
                  >
                    O uso excessivo de dispositivos digitais pode fazer com que
                    as crianças sofram danos irreversíveis em seus cérebros em
                    desenvolvimento e limitem sua capacidade de sucesso escolar.
                  </a>
                </u>
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Se seu filho tiver permissão para trazer um telefone para a
                escola, discuta os seguintes pontos:
              </p>
              <ul className="marcadores">
                <li>Onde eles vão guardar?</li>
                <li>
                  Como eles vão resistir a verificar o dispositivo durante as
                  aulas?
                </li>
                <li>
                  Qual é a política da escola sobre o uso do telefone e da
                  internet?
                </li>
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="dia"
          titulo="Escolher quais dias da semana são bons para telas e mídia."
          porque={
            <div>
              <p>
                Ter dias e horários específicos para o uso da tela em casa
                facilita a rotina e também evita ter que negociar com seus
                filhos todos os dias.
              </p>
              <p>
                Algumas famílias mantêm atividades divertidas na tela
                (videogames, filmes, TV) apenas nos finais de semana; outras
                planejam usar a tecnologia todos os dias, mas apenas em horários
                específicos.
              </p>
              <p>
                Pode ser quando estão trabalhando, estudando, pesquisando juntos
                ou numa noite de cinema em família.
              </p>
              <p>
                Não há uma maneira perfeita de fazer isso, então escolha o que
                achar melhor para sua família.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Criar um lembrete visual dos dias e horários de uso da tela pode
                ajudar a manter sua família no caminho certo. Esses horários
                podem ser listados em um pedaço de papel ou calendário e
                afixados na geladeira ou em um local para todos verem.
              </p>
              <p>
                Antes dos dias sem tela, guarde os tablets, console de jogos ou
                outros dispositivos que não serão usados nos próximos dias. Se
                as crianças as virem, elas podem ser estimuladas a querer
                usá-las.
              </p>
              <p>
                Para adolescentes que usam computadores e laptops para fazer a
                lição de casa, considere agendar um "tempo sem tela" todas as
                tardes ou noites. Isso pode dar a eles uma pausa saudável das
                telas e telefones.
              </p>
              <p>
                Se quiser mais alternativas e informações sobre isso, consulte
                nosso{" "}
                <u>
                  <a
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="plano"
          titulo="Ter um plano de lição de casa e telas."
          porque={
            <div>
              <p>
                A lição de casa às vezes pode parecer uma tarefa impossível para
                as crianças. Mas quanto mais eles enrolam para fazer, mais
                difícil parece se tornar.
              </p>
              <p>
                Permitir uma atividade online ou na tela, como falar com amigos,
                jogar ou ver um filme ou episódio que desejam, após a lição de
                casa pode ser uma boa motivação para fazerem mais cedo. Também
                pode garantir que a lição de casa continue sendo uma prioridade.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Faça uma lista de verificação que mostre as tarefas de casa e
                quando seus filhos podem usar a tecnologia - seu celular,
                computador, tablet ou TV. Isso pode ajudá-los a entender que,
                uma vez feito o dever de casa, ele pode ir e relaxar.
              </p>
              <p>
                Remova os dispositivos conectados durante o horário da lição de
                casa se seus filhos estiverem fazendo um trabalho que não exija
                que eles estejam online.
              </p>
              <p>
                Existe também a opção de restringir programas durante o tempo do
                dever de casa caso seu filho precise estar online. Para fazer
                essa configuração, consulte nosso{" "}
                <u>
                  <a
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="tela"
          titulo="Usar apenas uma tela por vez."
          porque={
            <div>
              <p>
                Multitarefa com a tecnologia enquanto tenta fazer a lição de
                casa ou outras tarefas desafiadoras leva a distração e erros.
                Tente mantê-los separados.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Se as crianças estiverem fazendo a lição de casa on-line,
                planeje fechar todas as guias do navegador que distraiam e
                silenciar o telefone. 
              </p>
              <p>
                Certifique-se de que as tarefas estejam concluídas antes de
                verificar as redes sociais.
              </p>
              <p>Sempre escolham um dispositivo apenas: </p>
              <ul className="marcadores">
                <li>
                  Se está assistindo TV, não está no tablet, nem no celular, nem
                  no computador.
                </li>
                <li>
                  Se está no celular, não está usando o computador, nem
                  assistindo TV, nem no tablet.
                </li>
                <li>
                  Se está no computador, evite usar o celular, tablet ou TV.
                </li>
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="evitar"
          titulo="Evitar telas antes da escola."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.theatlantic.com/ideas/archive/2023/06/ban-smartphones-phone-free-schools-social-media/674304/"
                    title="https://www.theatlantic.com/ideas/archive/2023/06/ban-smartphones-phone-free-schools-social-media/674304/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    O uso do telefone pode ter um efeito cumulativo, duradouro e
                    deletério nas habilidades dos adolescentes de se concentrar
                    e se dedicar
                  </a>
                </u>
                , impactando no desempenho escolar.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Se puder, tome café da manhã juntos em algum lugar sem tela. Por
                que não usar esse tempo para conversar sobre o dia que vamos
                viver?
              </p>
              <p>
                Você pode também alocar ações como atividade física e até ajudar
                em casa nesse período de forma que seu filho faça tudo o que
                precisa fazer para ficar com a cabeça livre para focar na escola
                e, quem sabe, ver algo na internet na volta.
              </p>
              <p>
                Se quiser, consulte nossa aula de rotina no nosso{" "}
                <u>
                  <a
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="semana"
          titulo="Planejar um dia sem tela a cada semana."
          porque={
            <div>
              <p>
                Fazer pausas regulares para uso de mídia pode ser um hábito
                familiar saudável e revigorante.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Que tal escolher um dia da semana em que toda a família esteja
                sem tela? Planejem juntos o que mais vocês gostariam de fazer em
                família nesse tempo livre. Anote este dia em um calendário que
                todos possam ver. Você também pode fazer uma lista onde os
                membros da família podem escrever o que querem fazer.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="silenciar"
          titulo='Silenciar telefones colocando-os em "não perturbe" durante o tempo da família e o tempo de brincadeira'
          porque={
            <div>
              <p>
                Interrupções de telefones podem distrair e dificultar a
                interação uns com os outros.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                As configurações "Não perturbe" e "Foco" são fáceis de encontrar
                nos dispositivos. Se você tem dúvidas, basta ver o vídeo{" "}
                <u>
                  <a
                    href="https://www.instagram.com/gabriellakohatsu/"
                    title="https://www.instagram.com/gabriellakohatsu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
              <p>
                Exercite o hábito de ativá-los quando quiser algum tempo
                ininterrupto com a família.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="impedir"
          titulo="Impedir que o uso de telas interfira no sono."
          porque={
            <div>
              <ul className="marcadores">
                <li>
                  Usar telas antes de dormir pode diminuir a quantidade de sono
                  do seu filho.
                </li>
                <li>
                  A luz azul das telas reduz um hormônio no cérebro do seu filho
                  chamado melatonina, que é necessário para ajudar seu filho a
                  dormir. Alertas vibratórios e sonoros podem acordar crianças e
                  adolescentes do sono.
                </li>
                <li>
                  Crianças e adolescentes podem acordar para usar dispositivos
                  no meio da noite ou no início da manhã.
                </li>
              </ul>
            </div>
          }
          como={
            <div>
              <p>
                Rotina sugerida: desligue as telas/dispositivos uma hora antes
                de dormir.
              </p>
              <p>
                Evite colocar o telefone próximo a cama, deixe-o carregar em
                outro quarto. Em vez disso, use um despertador para acordar de
                manhã.
              </p>
              <p>Substitua a tela por outra atividade na hora de dormir.</p>
              <ul className="marcadores">
                <li>
                  As crianças mais novas gostam de rotinas: leiam uma história
                  de ninar juntos.
                </li>
                <li>
                  Crianças mais velhas: substitua a mídia por música, um podcast
                  ou um livro impresso.
                </li>
              </ul>
              <p>Se estiver usando ou assistindo algo antes de dormir.</p>
              <ul>
                <li>Não jogue ou assista a jogos ou programas assustadores.</li>
                <li>
                  Diminua o brilho da tela. Defina o telefone para as
                  configurações noturnas após um determinado período. 
                </li>
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="dormir"
          titulo="Evitar telas durante a hora antes de dormir."
          porque={
            <div>
              <p>
                Usar uma tela – computador, tablet, telefone ou TV – antes de
                dormir pode causar problemas de sono . A luz azul interrompe os
                ciclos de sono e a estimulação mental nos mantém acordados.
              </p>
              <p>
                Mesmo que pareça que as telas acalmam seu filho para dormir, o
                sono é de qualidade inferior. Isso pode interferir no
                comportamento e foco no dia seguinte
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Considere uma rotina definida para dormir que não inclua telas.
                Em vez disso, tente ler livros, cantar músicas, contar histórias
                ou simplesmente falar sobre o dia. Isso pode ajudar as crianças
                (e pais) a evitar a tentação das telas antes de dormir. Os
                adolescentes podem precisar de uma rotina de higiene do sono,
                como tomar banho ou um chá quentinho ou ler um livro.
              </p>
              <p>
                Diminua o brilho da tela. Defina o telefone para as
                configurações noturnas após um determinado período. Isso lembra
                que é hora de dormir.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="intencional"
          titulo="Ser mais intencional sobre o uso de cada dispositivo e da internet."
          porque={
            <div>
              <p>
                Deslizar e rolar é fácil, assim como ficar preso em conteúdo sem
                fim.
              </p>
              <p>
                Aprender a usar as tecnologias como ferramenta, de maneira
                orientada a um propósito, pode ajudar a melhorar a qualidade do
                conteúdo. Também ajuda a evitar assistir compulsivamente ou
                perder tempo.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                <strong>Como?</strong>
              </p>
              <p>
                Quando você conversar com seus filhos ou observar os resultados
                do rastreamento do tempo de tela do seu dispositivo,
                concentre-se no que mais usa e no porquê.
              </p>
              <p>
                Fale sobre o que você gostaria de fazer menos e quais
                aplicativos ou programas seus filhos querem jogar nas próximas
                semanas.
              </p>
              <p>
                Apenas ter um plano pode ajudar o uso das telas a ser um pouco
                menos automático e despercebido.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="priorizar"
          titulo="Priorizar conteúdos lentos, criativos, pró-sociais e positivos."
          porque={
            <div>
              <p>
                A internet e os conteúdos positivos ajudam as crianças a
                aprender novas habilidades, como cozinhar, pintar ou fazer
                filmes.
              </p>
              <p>
                Alguns aplicativos que se autodenominam "educacionais", no
                entanto, não são nada disso.
              </p>
              <p>
                Aplicativos gratuitos podem ter muitos anúncios, pressionam as
                crianças a fazer compras no aplicativo ou coletam dados de seu
                filho.
              </p>
              <p>
                Os pais têm muitas opções, então leia as avaliações e confira os
                aplicativos antes de permitir que as crianças os usem.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Um bom exemplo de como usar a internet com um propósito positivo
                é ter chamadas de vídeo regulares com as pessoas queridas.{" "}
                <u>
                  <a
                    href="https://www.sciencedirect.com/science/article/abs/pii/S2212868916300277"
                    title="https://www.sciencedirect.com/science/article/abs/pii/S2212868916300277"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Até bebês e crianças pequenas podem começar a aprender dessa
                    maneira
                  </a>
                </u>
                . Marque uma data semanal para fazer a chamada com os parentes.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="orientar"
          titulo="Fazer orientações e configurações sobre como gastar dinheiro na internet com segurança."
          porque={
            <div>
              <p>
                As crianças podem não entender que um dinheiro digital é um
                dinheiro real.
              </p>
              <p>
                Algumas{" "}
                <u>
                  <a
                    href="https://www.techspot.com/news/98980-13-year-old-spent-64000-parents-money-mobile.html"
                    title="https://www.techspot.com/news/98980-13-year-old-spent-64000-parents-money-mobile.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    crianças gastaram milhares de dólares
                  </a>
                </u>{" "}
                em compras na internet sem os pais terem noção prévia.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Converse com seus filhos sobre dinheiro e a possibilidade de
                gastar dinheiro pela internet. Pode ser difícil para as crianças
                entenderem por que não é uma boa ideia gastar dinheiro online
                até que saibam como ganhar, economizar e gastar dinheiro.
              </p>
              <p>
                Defina nas suas contas pessoais, aplicativos e contas de jogos
                para exigir permissão dos pais antes das compras ou confirmação
                por senha.
              </p>
              <p>
                Para o passo a passo de cada configuração e nossas
                recomendações, consulte nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental.
                  </a>
                </u>
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="experimento"
          titulo="Fazer experimentos em família para experimentar novos programas, aplicativos e desinstalar outros."
          porque={
            <div>
              <p>
                Crianças e adolescentes também estão muito cientes do uso de
                tecnologia de seus pais. A maneira mais eficaz de ajudar seus
                filhos a mudar seu comportamento é trabalhar com eles para
                experimentar novos aplicativos melhores (ou tentar desinstalar
                aplicativos que parecem desperdiçadores de tempo).
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Pergunte ao seu filho se ele gostaria de experimentar um novo
                aplicativo ou uma série de vídeos com você. Pode ser qualquer
                tópico, desde observação de pássaros até atenção plena. Encontre
                bons programas e aplicativos lendo resenhas independentes. Para
                quem lê inglês, uma das nossas inspirações é o site{" "}
                <u>
                  <a
                    href="https://www.commonsensemedia.org/"
                    title="https://www.commonsensemedia.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Common Sense Media
                  </a>
                </u>
                .
              </p>
              <p>
                Depois de experimentar o aplicativo por duas semanas, fale sobre
                o que você e seu filho gostam ou não. Se você desinstalou um
                aplicativo por algumas semanas, fale sobre como foi. Você pode
                perguntar: O que foi bom ou ruim em usar menos esse aplicativo?
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="cinema"
          titulo="Planejar noites de cinema em família."
          porque={
            <div>
              <p>
                <strong>Co-visualização</strong> (dividir o momento consumindo
                na tela com um dos pais ou adulto).
              </p>
              <ul className="marcadores">
                <li>A co-visualização permite interação e discussão.</li>
                <li>
                  As crianças mais novas aprendem melhor com a mídia, programas
                  educacionais e vídeos quando são co-visualizados e há
                  interação entre pais e filhos.
                </li>
              </ul>
              <p>
                Sabe quando tem jogo da copa do mundo e a família inteira se
                reúne para assistir junto? Nesse momento, tempo de tela nem é
                uma questão.
              </p>
              <p>
                A tela pode ser uma importante fonte de união. As crianças
                aprendem mais sobre como elas usarão quando compartilham a
                experiência com um adulto. Ou vários.
              </p>
              <p>
                Decidir juntos sobre um filme, programa de TV ou jogo que todos
                possam desfrutar é uma chance de se conectar, conversar, rir e
                aprender.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Por que não escolher um horário fixo (sexta-feira à noite, por
                exemplo) quando todos assistem a um filme juntos? Vocês podem se
                revezar na escolha do filme ou pedir a todos que escrevam uma
                sugestão antes de decidir qual assistir.
              </p>
              <p>
                Considere pedir a todos os membros da família que coloquem seus
                telefones fora da sala enquanto assistem ao conteúdo juntos.
                Isso ajudará a reduzir a distração.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="vizualizar"
          titulo="Co-visualização de mídia para conectar e ajudar nossos filhos a aprender."
          porque={
            <div>
              <p>
                A co-visualização permite que você se conecte e discuta tópicos
                que talvez não soubesse como abordar num outro momento se não
                estivessem compartilhando o que estão vendo juntos. 
              </p>
              <p>
                As crianças mais novas aprendem melhor com o que estão
                assistindo quando são co-visualizados e os pais falam sobre o
                que está na tela. 
              </p>
              <p>
                Isso ajuda as crianças a fazer conexões e ver as coisas da
                perspectiva de outra pessoa. Também pode ajudá-los a sair das
                telas.
              </p>
              <p>
                Crianças mais velhas e adolescentes também aprendem com o que
                veem, incluindo quais tipos de comportamentos são e não são
                apropriados para pessoas de sua idade.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Aqui estão algumas perguntas a serem feitas ao co-visualizar com
                crianças:
              </p>
              <ul>
                <li>Como você se sente assistindo a esse programa?</li>
                <li>
                  Onde mais você viu algo assim? Essa história te lembra alguma
                  coisa? Você já viu esse tipo de situação com seus amigos?
                </li>
                <li>De qual personagem você gostaria de ser amigo? Por quê?</li>
                <li>O que você acha que vai acontecer depois?</li>
                <li>Qual foi sua parte favorita?</li>
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="assistir"
          titulo="Jogar em aplicativos e jogos online ou assistir a vídeos juntos em família."
          porque={
            <div>
              <p>
                <strong>Co-play</strong> (jogar videogame e usar aplicativos com
                um dos pais ou adulto).
              </p>
              <ul>
                <li>
                  As crianças mais novas aprendem melhor com a mídia quando
                  compartilham a experiência com um adulto.
                </li>
                <li>
                  Ajuda os pais a se manterem conectados com seus filhos e
                  adolescentes.
                </li>
                <li>
                  Permite que os pais tenham uma melhor noção de como seu filho
                  está gastando seu tempo.
                </li>
              </ul>
              <p>
                Jogar videogames ou aplicativos juntos é divertido e ajuda você
                a ver os mundos digitais de seus filhos.
              </p>
              <p>
                Dá-lhe uma melhor noção de como seu filho está gastando seu
                tempo.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Peça aos seus filhos para mostrar seu jogo favorito, o que eles
                construíram ou realizaram e como aprenderam sobre isso.
              </p>
              <p>
                Se você vir conteúdo negativo, como estereótipos ou violência,
                pergunte o que seus filhos acham disso. Eles têm a visão deles e
                vocês podem conversar mais sobre o assunto.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="dispositivos"
          titulo="Definir onde, em nossa casa, devemos usar dispositivos tecnológicos."
          porque={
            <div>
              <p>
                Se a família não definir onde pode usar o celular, o tablet,
                assistir TV ou ficar no computador, qualquer lugar pode ser
                lugar e isso pode trazer problemas como{" "}
                <u>
                  <a
                    href="https://hugogloss.uol.com.br/buzz/pobre-jennifer-durante-reuniao-em-video-com-colegas-de-trabalho-mulher-vai-ao-banheiro-esquece-de-desligar-a-camera-e-viraliza-entenda-o-caso/"
                    title="https://hugogloss.uol.com.br/buzz/pobre-jennifer-durante-reuniao-em-video-com-colegas-de-trabalho-mulher-vai-ao-banheiro-esquece-de-desligar-a-camera-e-viraliza-entenda-o-caso/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    durante a reunião online, a moça foi ao banheiro, esqueceu
                    de desligar a câmera e o microfone e viralizou
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Deixe claro para a sua família onde pode usar. A televisão pode
                ficar na sala, a Alexa pode ficar no balcão da cozinha, por
                exemplo, para vocês verem receitas, os celulares podem ser
                usados no escritório, na mesa de estudos ou onde preferirem.
              </p>
              <p>
                Para ajuda na definição, assista o módulo Tempo de Tela, no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="carregar"
          titulo="Definir onde os dispositivos móveis (celulares, tablets, notebooks) serão carregados."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://a.co/d/8YGHQe3"
                    title="https://a.co/d/8YGHQe3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quanto menor a criança é, menos a gente pode contar
                  </a>
                </u>{" "}
                com o “autocontrole” dela. A gente precisa ensiná-los a não
                virar a noite em seus dispositivos.
              </p>
              <p>
                Logo, desde novos você pode introduzir a ideia de que os
                dispositivos não dormem com a gente e que eles também precisam
                dormir para estarem carregador no dia seguinte.
              </p>
              <p>
                Durante a noite, recarregue os dispositivos{" "}
                <strong>fora do quarto do seu filho.</strong>
              </p>
              <ul>
                <li>
                  Mensagens e chamadas recebidas interferem no sono do seu
                  filho.
                </li>
                <li>
                  A luz emitida pelo carregamento dos dispositivos ainda pode
                  afetar a qualidade do sono do seu filho.
                </li>
                <li>
                  Recarregar dispositivos fora do quarto ajuda as crianças a
                  evitarem a tentação de usar ou verificar os dispositivos
                  constantemente enquanto deveriam estar dormindo.
                </li>
              </ul>
            </div>
          }
          como={
            <div>
              <p>
                Defina um espaço que vai ser a caminha dos dispositivos móveis
                em que os celulares, tablets, relógios e consoles serão
                carregadas. Inclua na rotina da noite de forma a deixar claro
                que está na hora de ir diminuindo o ritmo.
              </p>
              <p>
                É melhor se os dispositivos carregarem fora dos quartos. Pode
                ser um escritório ou na sala.
              </p>
              <p>
                Para ajuda na definição, assista o módulo Tempo de Tela, no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="naousar"
          titulo="Definir onde não usar dispositivos eletrônicos."
          porque={
            <div>
              <p>
                A fim de facilitar para as crianças, vocês podem definir apenas
                onde não usaram nenhum dispositivo como: andando -{" "}
                <u>
                  <a
                    href="https://gauchazh.clicrbs.com.br/comportamento/noticia/2015/12/perigo-cerca-os-zumbis-digitais-pedestres-distraidos-pelo-celular-4938838.html"
                    title="https://gauchazh.clicrbs.com.br/comportamento/noticia/2015/12/perigo-cerca-os-zumbis-digitais-pedestres-distraidos-pelo-celular-4938838.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    a fim de evitar acidentes
                  </a>
                </u>{" "}
                - nos banheiros, nos quartos, enquanto trocam de roupa ou tem
                alguém próximo pelado,{" "}
                <u>
                  <a
                    href="https://glamour.globo.com/lifestyle/trending/noticia/2020/10/crianca-tira-fotos-da-mae-sem-roupa-e-compartilha-nas-redes-sociais.ghtml"
                    title="https://glamour.globo.com/lifestyle/trending/noticia/2020/10/crianca-tira-fotos-da-mae-sem-roupa-e-compartilha-nas-redes-sociais.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    como as próprias mães
                  </a>
                </u>
                , durante as refeições, quando estivermos recebendo a família em
                casa ou os visitando…
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Assim como explicamos para as crianças a olhar para os dois
                lados antes de atravessar, nós incutimos o pensamento de
                segurança na internet também conversando sempre e com exemplos.
              </p>
              <p>
                Para ajuda na definição, assista o módulo Tempo de Tela, no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="horario"
          titulo="Definir quais são os horários do dia em que devemos dar atenção às pessoas e deixar nossos dispositivos de lado."
          porque={
            <div>
              <p>
                Se a gente, além de limitar o espaço, não limitar a duração,
                todo momento pode ser de tempo de tela.{" "}
                <u>
                  <a
                    href="https://a.co/d/2dWJd9n"
                    title="https://a.co/d/2dWJd9n"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A fim de evitar que seu filho seja sugado pela tela
                  </a>
                </u>
                , defina horários e marcos do dia que também devem ser
                protegidos.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Alguns momentos como o banho, a hora de dormir, os momentos das
                refeições, durante suas leituras podem ser protegidos do uso dos
                telefones, computadores, jogos ou TV. Listem juntos todos que
                são mais importantes que suas telas e os protejam para que não
                sejam interrompidos por elas.
              </p>
              <p>
                Para ajuda na definição, assista o módulo Tempo de Tela, no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="acordar"
          titulo="A família pode acordar que os aplicativos e jogos escolhidos serão apropriados e serão temas de conversas, bem como daremos preferência aos aplicativos e conteúdos que estimulam a criatividade, raciocínio lógico, leitura e atividades físicas."
          porque={
            <div>
              <p>
                Alguns conteúdos{" "}
                <u>
                  <a
                    href="https://www.techtudo.com.br/listas/2023/09/live-npc-xixi-e-mais-8-vezes-em-que-as-trends-do-tiktok-foram-longe-demais.ghtml"
                    title="https://www.techtudo.com.br/listas/2023/09/live-npc-xixi-e-mais-8-vezes-em-que-as-trends-do-tiktok-foram-longe-demais.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    podem ser perigosos para crianças e adolescentes
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Faça uma triagem do que irá disponibilizar nos dispositivos dos
                seus filhos e aplique as ferramentas de controle parental.
                Lembre-se de que as crianças devem ser protegidas na internet e
                é isso o que os pais que fazem as barreiras com ferramentas de
                controle parental criam.
              </p>
              <p>
                Para ver nossas aulas e instruções consulte o nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="conteudo"
          titulo="Definir os conteúdos na TV, no tablet e no computador que serão sempre permitidos."
          porque={
            <div>
              <p>
                Quando a gente fala de proteger crianças e adolescentes na
                internet significa abrir caminho para o que pode e é permitido.
                A fim de protegê-los de tudo o que não é permitido por não ser
                adequado ou nocivo.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Use o algoritmo e as configurações a favor da sua família. Nos
                perfis de cada filho, os ajude a listar, identificar, seguir
                canais e perfis com vídeos, conteúdos e assuntos que podem ver. 
              </p>
              <p>
                A gente fala bastante disso na aula de configurações do perfil
                da Netflix no nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="consumir"
          titulo="Definir quais conteúdos na TV, no tablet e no computador que, de vez em quando, podem ser consumidos."
          porque={
            <div>
              <p>
                Alguns conteúdos talvez você não queria que seu filho assista
                sozinho. No entanto, são vídeos, filmes, assuntos que você veria
                com ele a fim de explicar o que é correto, o que não é, do que
                discorda e porquê.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Liste eles. Todos. E deixe um espaço para ver o conteúdo com seu
                filho.
              </p>
              <p>
                Um exemplo são filmes de super heróis. Às vezes, pela própria
                classificação, não seria adequado a idade do seu filho. Mas com
                você, ele pode assistir porque você está do lado para guiá-lo na
                experiência.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="tempo"
          valor="proibir"
          titulo="Definir os conteúdos na TV, no tablet e no computador que serão proibidos e evitamos na nossa família."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.childrenscommissioner.gov.uk/resource/a-lot-of-it-is-actually-just-abuse-young-people-and-pornography/"
                    title="https://www.childrenscommissioner.gov.uk/resource/a-lot-of-it-is-actually-just-abuse-young-people-and-pornography/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A exposição à pornografia é generalizada e normalizada – a
                    tal ponto que as crianças não podem “optar por não
                    participar”
                  </a>
                </u>
                . 
              </p>
              <p>
                E este uso problemático pode ter{" "}
                <u>
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6352245/"
                    title="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6352245/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    efeitos adversos no desenvolvimento sexual e no
                    funcionamento sexual, especialmente entre a população jovem
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Além de criar os perfis dos seus filhos com a idade deles e
                dentro do guarda chuva de proteção dos pais, faça as restrições
                de conteúdo em todos os ambientes que eles utilizarem.
              </p>
              <p>Verifique antes de dar o acesso a eles.</p>
              <p>
                Depois disso, deixe espaço para que eles possam falar com você
                quando virem conteúdo assim na internet. Lembre-se de quando se
                trata desse assunto, quanto menor seu filho é, mais vítima ele
                é.
              </p>
              <p>E que precisa ser protegido.</p>
              <p>
                Para saber mais sobre como abordar esse tema, veja o próximo
                tópico deste site ou verifique como fazer as configurações no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.tempo.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="tempo"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};
export default Step8;

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import FaixaEtaria from "../faixaetaria";
import { Membro } from "../pojos/Membro";
import { Dados } from "../pojos/Dados";
import { Equilibrio } from "../pojos/Equilibrio";
import { Internet } from "../pojos/Internet";
import { Privacidade } from "../pojos/Privacidade";
import { Tempo } from "../pojos/Tempo";

interface Step3Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step3: React.FC<Step3Props> = ({ dados, setDados }) => {
  const atualizarMembro = (index: number, novoMembro: Membro) => {
    const novoobjetivo = {...dados.objetivo};
    novoobjetivo.equilibrio = true;
    novoobjetivo.internet = true;
    novoobjetivo.privacidade = true;
    novoobjetivo.tempo = true;
    novoobjetivo.conversar = true;
    novoobjetivo.folga = true;
    
    novoMembro.equilibrio = new Equilibrio();
    if(novoMembro.faixa < 4) {
      novoMembro.equilibrio.rotina = true;
      novoMembro.equilibrio.assutar = true;
      novoMembro.equilibrio.atividade = true;
    }
    if(novoMembro.faixa > 3 ) {
      novoMembro.equilibrio.esnobar = true;
    }

    if(novoMembro.faixa > 2 ) {
      novoMembro.equilibrio.lembrete= true;
    }
 
    if(novoMembro.faixa > 1 ) {
      novoMembro.equilibrio.jogo= true;
    }
 
    novoMembro.internet = new Internet();
    if(novoMembro.faixa > 0 && novoMembro.faixa < 4) {
      novoMembro.internet.brigar= true;
      novoMembro.internet.cyberbullying= true;
    }
    if(novoMembro.faixa > 0 ){
      novoMembro.internet.reconhecer= true;
      novoMembro.internet.consciente= true;
    }

    novoMembro.privacidade= new Privacidade();
    if(novoMembro.faixa < 3 ){
      novoMembro.privacidade.desenvolvimento= true;
    }
    novoMembro.tempo= new Tempo();
    if(novoMembro.faixa > 3 ){
      novoMembro.tempo.dirigir= true;
      novoMembro.tempo.priorizar = true;
    }

    if(novoMembro.faixa > 0 ){
      novoMembro.tempo.ambiente = true;
      novoMembro.tempo.orientar = true;
      novoMembro.tempo.experimento = true;
      novoMembro.tempo.vizualizar = true;
      novoMembro.tempo.assistir = true;
    }
    if(novoMembro.faixa < 4 ){
      novoMembro.tempo.adiar = true;
      novoMembro.tempo.escola = true;
      novoMembro.tempo.dia = true;
      novoMembro.tempo.evitar = true;
      novoMembro.tempo.acordar = true;
      novoMembro.tempo.conteudo = true;
    }
    if(novoMembro.faixa < 4 && novoMembro.faixa > 1 ){
      novoMembro.tempo.plano = true;
    }

    if(novoMembro.faixa > 2 ){
      novoMembro.tempo.silenciar = true
    }

    dados.membros[index] = novoMembro;
    setDados((dados) => {
      return { ...dados,objetivo:novoobjetivo, membros: dados.membros };
    });
  };


  const adicionarMembro = () => {
    const novosMembros = [...dados.membros, new Membro()];
    const novosDados = { ...dados, membros: novosMembros };
    setDados(novosDados);
  };

  return (
    
    <div className="flex flex-col gap-5 min-h-[46vh] 
    sm:ml-[-10vw] 
    md:ml-[-10vw] 
    tablet:ml-[-14vw]  
    mobile-lg:ml-[-16vw]  mobile-lg:mt-[-2vh]
    mobile-md:ml-[-10vw]  mobile-md:mt-[-2vh]
    ">
      <span className="flex justify-center text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] tablet:ml-[14vw] mobile-md:text-[2rem]">
        Minha família
      </span>

      <span className="flex justify-left text-xl font-codecProBold text-Marinho pt-2 max-w-[100%]  mobile-lg:mt-[-2vh] mobile-md:mt-[-2vh]">
        Digite o nome da sua família:
      </span>
      <input
        type="text"
        id="familia"
        className="border-RoyalRoxo border-solid border-[2px] mt-[-10px] rounded-none w-[16vw] h-[45px] pl-3 
        sm:w-[30vw] 
        md:w-[30vw] 
        tablet:w-[40vw]  
        mobile-lg:w-[90vw]
        mobile-md:w-[80vw]
        "
        defaultValue={dados.familia}
        onChange={(e) => {
          const novosDados = { ...dados, familia: e.target.value };
          setDados(novosDados);
        }}
      ></input>

      <span className="flex justify-left text-xl font-codecProBold text-Marinho pt-2 max-w-[100%]  
      mobile-lg:mt-[-2vh]
      mobile-md:mt-[-2vh]
      ">
        Minha família:
      </span>

      <Button
        variant="contained"
        endIcon={<AddCircleOutlineIcon />}
        className="w-[15vw] h-min[45px] 
        sm:w-[30vw] 
        md:w-[25vw] 
        note:w-[25vw] 
        tablet:w-[40vw]  
        mobile-lg:w-[90vw]
        mobile-md:w-[80vw]
        "
        onClick={adicionarMembro}
      >
        ADICIONAR MEMBRO DA FAMÍLIA
      </Button>
     
      {dados.membros && (dados.membros.map((membro, index) => (
        <FaixaEtaria 
          key={index}
          membro={membro}
          deletaMembro={() => {
            const novosMembros = [...dados.membros];
            novosMembros.splice(index, 1);
            const novosDados = { ...dados, membros: novosMembros };
            setDados(novosDados);
          }}
          atualizarMembro={(membro: Membro) => {
            atualizarMembro(index, membro);

          }}
        />
      )))}
    </div>
  );
};

export default Step3;

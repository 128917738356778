export class Folga {
  [key: string]: any;
  atividade : boolean;
  sequencia : boolean;
  essencial: boolean;
  final: boolean;
  evento: boolean;
  logica: boolean;
  adicionais: string[];

  constructor(){
    this.atividade= true;
    this.sequencia= true;
    this.essencial= true;
    this.final= true;
    this.evento= true;
    this.logica= true;
    this.adicionais= [];
  }
}

import { Checkbox } from "@mui/material";
import proteka from "../home/proteka.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import { Dados } from "../pojos/Dados";
import React from "react";
interface Step4Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
  skipSteps: () => void;
}

const Step4: React.FC<Step4Props> = ({ dados, setDados, skipSteps }) => {
  const [opTodos, setOpTodos] = useState(false);
  React.useEffect(() => {
    skipSteps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados]);

  function funcao_marcar() {
    const checkbox = document.getElementById(
      "marcar_todos"
    ) as HTMLInputElement;
    const flagMarcartodos = document.getElementById("flag_marcartodos");

    if (checkbox && flagMarcartodos) {
      if (opTodos) {
        flagMarcartodos.innerText = "Desmarcar Todos";
        checkbox.checked = true;
        setOpTodos(true);
        dados.objetivo.conversar = true;
        dados.objetivo.equilibrio = true;
        dados.objetivo.folga = true;
        dados.objetivo.internet = true;
        dados.objetivo.privacidade = true;
        dados.objetivo.tempo = true;
        setDados(dados);
      } else {
        flagMarcartodos.innerText = "Marcar Todos";
        checkbox.checked = false;
        setOpTodos(false);
        dados.objetivo.conversar = false;
        dados.objetivo.equilibrio = false;
        dados.objetivo.folga = false;
        dados.objetivo.internet = false;
        dados.objetivo.privacidade = false;
        dados.objetivo.tempo = false;
        setDados(dados);
      }
      
    }
    skipSteps();
  }
  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
      <div className="flex mt-10">
        <div>
          <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
        </div>

        <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Nossos objetivos:
        </span>
      </div>

      <div className="flex ml-[2vw]">
        <Checkbox
          id="marcar_todos"
          onClick={funcao_marcar}
          checked={opTodos}
          onChange={() => setOpTodos(!opTodos)}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
        />
        <span
          className="flex text-xl font-codecProBold text-Marinho items-center mt-1"
          id="flag_marcartodos"
        >
          Desmarcar Todos
        </span>
      </div>

      <div
        id="container"
        className="flex gap-6 flex-wrap justify-center w-max-[100vw] mt-[2vh] 
        sm:w-[80vw] 
        tablet:grid tablet:w-[80vw] 
        mobile-lg:w-[90vw] mobile-lg:flex-col 
        mobile-md:w-[90vw] mobile-md:flex-col"
      >
        <div
          id="equilibrio"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[25rem] rounded-[1em] p-5 
          sm:h-auto sm:w-[35vw] 
          md:h-auto 
          note:h-auto 
          tablet:w-full tablet:h-auto
          mobile-lg:w-[88vw] mobile-lg:h-auto
          mobile-md:w-[88vw] mobile-md:h-auto
          ">
          <div className="flex">
            <Checkbox
              id="opcao_equilibrio"
              checked={dados.objetivo.equilibrio}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: { ...dados.objetivo, equilibrio: e.target.checked },
                });
 
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              EQUILÍBRIO NO USO
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify">
            <p>
            Reconheça a importância de equilibrar o tempo gasto em telas com as responsabilidades e atividades necessárias no dia a dia. Compreendemos que o uso excessivo de telas pode impedir as crianças de realizar o que é necessário e importante. Ao discutir abertamente o uso de telas, incluindo a compreensão do custo de oportunidade, enfatizamos como o tempo excessivo nas telas pode impactar negativamente o cumprimento de tarefas, obrigações escolares, interações sociais e outras atividades.&nbsp;
            <u>
                <a
                  href="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                  title="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                >Crie tempo de tela em torno das atividades familiares, e não o
                  contrário
                </a>
              </u>
              &nbsp;para garantir um equilíbrio saudável e priorizar as experiências e momentos compartilhados em família.
            </p>
          </span>
        </div>

        <div
          id="dentro_internet"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[400px] rounded-[1em] p-5 sm:h-auto sm:w-[35vw] md:h-auto note:h-auto tablet:w-full tablet:h-auto mobile-lg:w-[88vw] mobile-lg:h-auto mobile-md:w-[90vw] mobile-md:flex-col mobile-md:h-auto"
        >
          <div className="flex">
            <Checkbox
              id="opcao_dentro"
              checked={dados.objetivo.internet}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: { ...dados.objetivo, internet: e.target.checked },
                });
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />

            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              DENTRO E FORA DA INTERNET
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify ">
            <p>
              Gerencie sua pegada digital,&nbsp;
              <u>
                <a
                  href="https://agenciabrasil.ebc.com.br/saude/noticia/2021-09/exposicao-excessiva-de-criancas-em-redes-sociais-pode-causar-danos"
                  title="https://agenciabrasil.ebc.com.br/saude/noticia/2021-09/exposicao-excessiva-de-criancas-em-redes-sociais-pode-causar-danos"
                >
                  proteja seu futuro.
                </a>
              </u>
              &nbsp;Pense antes de compartilhar, seja online ou offline. Dê exemplo
              de uso responsável de telas e da internet para sua família. Seja
              em relação ao tempo de uso, compartilhamento de informações, fotos
              entre outros dados. Converse com os seus familiares sobre ameaças
              online, super compartilhamentos digitais de localização, de rotina
              e sobre práticas seguras. Lembre-se de que a segurança digital é
              um esforço contínuo para manter sua privacidade e bem-estar
              digital. Pense antes de postar e sobre quem pode ter acesso ao que
              coloca nas redes. Mantenha-se consciente das consequências
              duradouras e proteja você e a sua família.
            </p>
          </span>
        </div>

        <div
          id="privacidade"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[400px] rounded-[1em] p-5 sm:h-auto sm:w-[35vw] md:h-auto note:h-auto tablet:w-full tablet:h-auto mobile-lg:w-[88vw] mobile-lg:h-auto mobile-md:w-[90vw] mobile-md:flex-col mobile-md:h-auto"
        >
          <div className="flex">
            <Checkbox
              id="opcao_privacidade"
              checked={dados.objetivo.privacidade}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: {
                    ...dados.objetivo,
                    privacidade: e.target.checked,
                  },
                });
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              PRIVACIDADE E SEGURANÇA DIGITAL
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify ">
          Promova a segurança digital ao conversar sobre regras e práticas seguras. Ensine seu filho a navegar no mundo digital com responsabilidade. Utilize uma combinação de ferramentas, como configurações de privacidade, denúncia de conteúdo impróprio e discussões constantes sobre comportamento online. Assim como aprendemos a olhar para os dois lados ao atravessar a rua desde criança, também é importante ensinar sobre não falar com estranhos pessoalmente e não divulgar detalhes de nossas vidas online. Inclua tópicos de segurança virtual para orientar seus filhos e garantir uma experiência online mais segura.
          </span>
        </div>

        <div
          id="quando"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[400px] rounded-[1em] p-5 sm:h-auto sm:w-[35vw] md:h-auto note:h-auto tablet:w-full tablet:h-auto mobile-lg:w-[88vw] mobile-lg:h-auto mobile-md:w-[90vw] mobile-md:flex-col mobile-md:h-auto"
        >
          <div className="flex">
            <Checkbox
              id="opcao_quando"
              checked={dados.objetivo.tempo}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: { ...dados.objetivo, tempo: e.target.checked },
                });
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              QUANDO, ONDE, COM QUEM E POR QUANTO TEMPO
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify ">
            <p>
              A internet pode ser um ambiente tão perigoso quanto sair à noite
              nas ruas. Por isso, é essencial estabelecer, esclarecer, orientar
              e obter informações sobre o comportamento online do seu filho,
              assim como fazemos com suas atividades fora de casa. As telas não
              precisam dominar todos os momentos e espaços do nosso dia;
              reservar tempo para interações familiares é de extrema importância
              para a saúde e o desenvolvimento do seu filho. Ter áreas livres de
              tela em sua casa é benéfico para o desenvolvimento da criança. É
              fundamental concordar e estabelecer, como família, espaços onde o
              uso de telas não é permitido.
            </p>
          </span>
        </div>

        <div
          id="notas"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[400px] rounded-[1em] p-5 sm:h-auto sm:w-[35vw] md:h-auto note:h-auto tablet:w-full tablet:h-auto mobile-lg:w-[88vw] mobile-lg:h-auto mobile-md:w-[90vw] mobile-md:flex-col mobile-md:h-auto"
        >
          <div className="flex">
            <Checkbox
              id="opcao_notas"
              checked={dados.objetivo.conversar}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: { ...dados.objetivo, conversar: e.target.checked },
                });
                skipSteps();
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              NOTAS E EXCEÇÕES PARA CONVERSAR
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify ">
          Todas as orientações aqui são para serem aplicadas no dia a dia, na rotina, no cotidiano, incluindo o uso de telas e a internet. No entanto, na vida, nós temos as exceções. Aqui a gente tratará delas, abordando como gerenciar o uso de telas e a internet em situações específicas. Por exemplo, quando viajamos de carro ou de avião, em restaurantes, ao encontrar os avós ou quando ocorrer algo que esteja fora do nosso controle habitual e exigir decisões sobre o uso de telas e a internet. Vamos explorar maneiras de lidar com essas situações e tomar decisões adequadas, mantendo um equilíbrio saudável no uso das telas e da internet.
          </span>
        </div>

        <div
          id="feriados"
          className="flex flex-col items-start bg-BoxLilas w-[27vw] h-[400px] rounded-[1em] p-5 sm:h-auto sm:w-[35vw] md:h-auto note:h-auto tablet:w-full tablet:h-auto mobile-lg:w-[88vw] mobile-lg:h-auto mobile-md:w-[90vw] mobile-md:flex-col mobile-md:h-auto"
        >
          <div className="flex">
            <Checkbox
              id="opcao_feriados"
              checked={dados.objetivo.folga}
              onChange={(e) => {
                setDados({
                  ...dados,
                  objetivo: { ...dados.objetivo, folga: e.target.checked },
                });

              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            <span className="flex text-2xl font-codecProBold text-Marinho mt-3 leading-none">
              FERIADOS, RECESSOS E FÉRIAS
            </span>
          </div>
          <span className="flex text-[14px] text-Marinho mt-2 p-2 text-justify">
          Além das exceções, também enfrentamos períodos em que nossa rotina precisa ser reajustada, como durante as férias. Quando deixamos de ir à escola e ao trabalho, por exemplo, temos mais tempo disponível, e, se não estivermos bem organizados, podemos acabar passando mais tempo nas telas e na internet do que gostaríamos. Aqui, vamos listar tudo o que é essencial para nós mesmos, mesmo durante esses períodos, garantindo que mantenhamos um equilíbrio saudável, mesmo que tenhamos mais tempo para usar a internet. Vamos aprender a aproveitar de forma consciente e responsável esse período, encontrando um equilíbrio entre o tempo nas telas e outras atividades importantes para nós.
          </span>
        </div>
      </div>
      
    </div>
  );
};

export default Step4;

import React, { useState } from "react";
import checkedImg from "../steps/img/checked.svg";
import { Membro } from "../pojos/Membro";

interface CardEquilibrioProps {
  titulo: String;
  porque?: JSX.Element;
  como?: JSX.Element;
  atencao?: JSX.Element;
  objetivo: string;
  valor: string;
  membros: Membro[];
  atualizarMembros: (membros: Membro[]) => void;
}

const CardEquilibrio: React.FC<CardEquilibrioProps> = ({
  titulo,
  porque,
  como,
  atencao,
  objetivo,
  valor,
  membros,
  atualizarMembros,
}) => {
  const getTextoFaixa = (faixa: number): string => {
    switch (faixa) {
      case 0:
        return "0 a 24 meses";
      case 1:
        return "2 a 5 anos";
      case 2:
        return "6 a 11 anos";
      case 3:
        return "12 a 17 anos";
      case 4:
        return "18 a 59 anos";
      default:
        return "60 anos ou mais";
    }
  };

  const [mostrarPorque, setMostrarPorque] = useState(false);
  const [mostrarAtencao, setMostrarAtencao] = useState(false);

  const [marcarTodos, setMarcarTodos] = useState(true);
  const marcaDesmarca = (propsbtn: any) => {
    membros.forEach(membro => {
      membro[objetivo][valor] = !marcarTodos;
    });
    setMarcarTodos(!marcarTodos);
    atualizarMembros(membros);
  }

  React.useEffect(() => {
    membros.filter(membro => membro[objetivo][valor]).length === membros.length ? setMarcarTodos(true) : setMarcarTodos(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atualizarMembros]);
 
  const ToggleButton: React.FC<{ nome: string; faixaEtaria: string; value:boolean; membro: Membro}> = ({
    nome,
    faixaEtaria,
    membro,
    value
  }) => {
    const [checked, setChecked] = useState(value);

    const toggle = (propsbtn: any) => {
      membro[objetivo][valor] = !checked;
      setChecked(!checked);
      atualizarMembros(membros);
    };

    return (
      <div>
        <label className="flex items-center ml-4 mt-2 cursor-pointer ">
          <div
            className={`flex items-center justify-center pt-[5px] pr-[10px] pb-[5px] pl-[10px] mb-5  rounded-[15px] duration-300 relative ${
              checked ? "bg-[#2B139254]" : "bg-[#eae8f5]"
            }`}
          >
            {checked && (
              <div className="flex justify-center items-center bg-[#2b1392] w-6 h-6 rounded-full absolute top-[5px] right-[10px] transform translate-x-1/2 -translate-y-1/2">
                <img className="flex h-auto" src={checkedImg} alt="" />
              </div>
            )}
            <div className="flex flex-col w-24 mb-2 m-0 pt-1">
              <span
                className={`flex justify-center items-center text-[#1E134D] font-codecProBold text-[14px]  h-5 leading-none `}
              >
                {" "}
                {nome}
              </span>
              <span className="flex justify-center items-center text-center text-[#1E134D] font-codecProBold text-[14px] w-[3rem] ml-6 h-5 leading-none displaytable">
                {" "}
                {faixaEtaria}
              </span>
            </div>
            <input type="checkbox" className="hidden" onClick={toggle} />
          </div>
        </label>
      </div>
    );
  };

  return (
    <div className="w-[56vw] border-2 border-RoyalRoxo mt-10 ml-[2vw] bg-white 
    sm:w-[80vw] sm:ml-[1vw] 
    tablet:w-[85vw] tablet:ml-[1vw] 
    mobile-lg:w-[88vw] mobile-lg:ml-[0vw]
    mobile-md:w-[88vw] mobile-md:ml-[0vw]">
      <h1 className="ml-[1.875rem] mt-[1.875rem] text-RoyalRoxo text-[1.75rem] font-codecProBold mr-5 mobile-lg:text-[1.5rem]">
        {titulo}
      </h1>
     
      <div className="">
        {porque && <button
          className="border-2 border-RoyalRoxo mt-[40px] ml-7 bg-white pt-[3px] pr-[9px] pl-[11px] font-codecProBold text-RoyalRoxo text-[1rem] font-medium mobile-lg:ml-[7px] mobile-md:ml-[15px] mobile-md:text-[0.9rem]"
          onClick={() => setMostrarPorque(!mostrarPorque)}
        >
          {mostrarPorque ? "Fechar" : "Por quê?/Como?"}{" "}
        </button>
        } {atencao &&
        <button
        className="border-2 border-RoyalRoxo mt-[40px] ml-7 bg-white pt-[3px] pr-[9px] pl-[11px] font-codecProBold text-RoyalRoxo text-[1rem] font-medium"
        onClick={() => setMostrarAtencao(!mostrarAtencao)}
        >
        {mostrarAtencao ? "Fechar" : "Atenção"}{" "}
        </button>
        }  
        <button className="border-2 border-RoyalRoxo mt-[40px] ml-[17px] bg-white pt-[3px] pr-[9px] pl-[11px] font-codecProBold text-RoyalRoxo text-[1rem] font-medium mobile-md:text-[0.9rem]"
         onClick={marcaDesmarca}
        >
          {marcarTodos ?  "Desmarcar" : "Marcar" }{" "}todos
        </button>
      </div>

      {mostrarPorque && (
        <div className="m-auto mt-4 bg-[#eae8f5] w-[95%] h-auto rounded-[10px] p-5 text-RoyalRoxo">
          <div>
            <b>Por quê?</b>
            {porque}
            <br />
          </div>
          <div>
            <b>Como?</b>
            {como}
          </div>
        </div>
      )}

  {mostrarAtencao && (
        <div className="m-auto mt-4 bg-[#eae8f5] w-[95%] h-auto rounded-[10px] p-5 text-RoyalRoxo">
          <div>
            {atencao}
            <br />
          </div>
         </div>
      )}

      <div className="flex flex-wrap my-5 m-auto mr-5 ml-2">
        {membros.map((membro,index) => (
          <ToggleButton
          key={index}
            nome={membro.nome}
            faixaEtaria={getTextoFaixa(membro.faixa)}
            value={membro[objetivo][valor]}
            membro={membro}
          />
        ))}
      </div>
    </div>
  );
};

export default CardEquilibrio;

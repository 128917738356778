import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step7Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step7: React.FC<Step7Props> = ({ dados, setDados }) => {
  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };

  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.privacidade;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.privacidade.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.privacidade.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: {
        ...dados.pergunta,
        privacidade: [...dados.pergunta.privacidade, ""],
      },
    });
  };

  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
    <div className="flex mt-10">
      <div>
        <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
      </div>

      <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Privacidade e segurança digital
        </span>
      </div>

      <div id="cards" className="m-0 p-0">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="compartilhamento"
          titulo="Tentar evitar o excesso de compartilhamento."
          porque={
            <p>
              A informação digital é fácil de compartilhar e espalhar. A
              divulgação de detalhes privados por meio das redes sociais,
              mensagens de texto ou e-mails — como fotos, informações pessoais
              confidenciais ou de contato — pode se tornar embaraçosa e
              prejudicial ao bem-estar de uma criança. Isso inclui o
              compartilhamento excessivo de fotos de seus filhos online também.
            </p>
          }
          como={
            <div>
              <p>
                Antes de postar uma foto ou informação online, pais,
                adolescentes e crianças devem se fazer as seguintes perguntas:
              </p>
              <ul>
                <li className="ml-8">
                  Eu me importaria se todos vissem esta informação/foto, mesmo
                  se fosse eu nela?
                </li>
                <li className="ml-8">
                  Todas as pessoas na foto estão de acordo com a publicação
                  online?
                </li>
              </ul>
              <p>
                Se alguma resposta for não, não publique. Se a foto envolver
                crianças, adicione as questões disponíveis{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CaXKraXOuaj/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    title="https://www.instagram.com/p/CaXKraXOuaj/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="improprio"
          titulo="Evitar, sempre que possível, conteúdo impróprio."
          porque={
            <div>
              <p>
                A internet é um caminho para o mundo, o que tem de bom e de
                ruim. Infelizmente, muito conteúdo impróprio pode aparecer até
                mesmo nos ambientes de vídeos que crianças menores consomem.
                Isso pode incluir pornografia, informações falsas, drogas e
                álcool, dietas extremas e desafios perigosos de mídia social.
                Além disso,{" "}
                <u>
                  <a
                    href="https://g1.globo.com/ms/mato-grosso-do-sul/noticia/2022/05/06/veja-4-sinais-de-alerta-que-podem-indicar-aliciamento-sexual-de-criancas-e-adolescentes-em-jogos-on-line.ghtml"
                    title="https://g1.globo.com/ms/mato-grosso-do-sul/noticia/2022/05/06/veja-4-sinais-de-alerta-que-podem-indicar-aliciamento-sexual-de-criancas-e-adolescentes-em-jogos-on-line.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    podem tentar induzir as crianças a compartilhar informações
                  </a>
                </u>
                .
              </p>
              <br />
              <p>
                É importante que seus filhos saibam evitar ambientes e conteúdos
                perigosos na internet, mas também que conversem com você sobre
                isso para que saibam que você está preparado para ajudá-los.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Faça as configurações de controle parental ou{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CbLHFHTOYej/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    title="https://www.instagram.com/p/CbLHFHTOYej/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    use os recursos já existentes
                  </a>
                </u>{" "}
                para evitar os conteúdos inapropriados.
              </p>
              <p>
                Mostre aos seus filhos como sinalizarem ou{" "}
                <u>
                  <a
                    href="https://new.safernet.org.br/denuncie"
                    title="https://new.safernet.org.br/denuncie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    denunciar
                  </a>
                </u>
                em publicações e páginas ou anúncios que considerem impróprios.
              </p>
              <p>
                Se seu filho parece ser influenciado por mensagens negativas,
                como teorias da conspiração ou ideias extremistas, pergunte
                quais pessoas ou páginas ele segue e leia-as. Dessa forma, você
                pode ter uma conversa mais informada e ajudar seus filhos a
                pensar criticamente sobre essas mensagens.
              </p>
              <p>
                Você também pode configurar para ele pesquisar de forma mais
                segura e se orientar em mais passos a passos e configurações no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="desenvolvimento"
          titulo="Limitar o uso de telas para nossa criança pequena para evitar que o consumo interfira no desenvolvimento, nas habilidades sociais e emocionais."
          porque={
            <div>
              <p>
                O uso excessivo de tela, assistir a conteúdo impróprio e de
                ritmo acelerado, exposição à tela sem supervisão e nenhum
                envolvimento dos pais são prejudiciais para as funções
                executivas de uma criança, desenvolvimento de habilidades de
                atenção, aprendizado e desenvolvimento da linguagem e interações
                pais-filhos.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Não se sinta pressionado a iniciar o uso de tecnologia cedo com
                seu filho. É interessante adiar as telas,{" "}
                <u>
                  <a
                    href="https://srcd.onlinelibrary.wiley.com/doi/abs/10.1111/cdev.12166"
                    title="https://srcd.onlinelibrary.wiley.com/doi/abs/10.1111/cdev.12166"
                    target="_blank"
                    rel="noreferrer"
                  >
                    exceto bate-papo por vídeo
                  </a>
                </u>
                , até que seu filho tenha 18 a 24 meses de idade.
              </p>
              <p>
                Limite também a não mais que uma hora por dia para seu filho de
                2 a 5 anos.
              </p>
              <p>
                Priorize o brincar. Você não precisa de brinquedos caros. Seu
                filho pode brincar com blocos, bonecos ou te ajudar em casa.
                Convide, se possível, outras crianças para brincar com seu
                filho.
              </p>
              <p>
                Assistam juntos e conversem sobre o que está sendo visto. Isso
                ajuda seu filho a entender o que está vendo e ouvindo.
              </p>
              <p>Escolha programas com menos cores vibrantes e mais lentos.</p>
              <p>
                Encontre mais indicações de filmes, séries, jogos dentro e fora
                das tecnologias e o passo a passo para tudo apresentado acima no
                nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="configuracao"
          titulo="Explorar as opções e configurações de controle parental e as definir no mais alto nível de segurança."
          porque={
            <div>
              <p>
                As opções e configurações de controle parental são{" "}
                <u>
                  <a
                    href="https://www.jusbrasil.com.br/topicos/27363674/artigo-29-da-lei-n-12965-de-23-de-abril-de-2014"
                    title="https://www.jusbrasil.com.br/topicos/27363674/artigo-29-da-lei-n-12965-de-23-de-abril-de-2014"
                    target="_blank"
                    rel="noreferrer"
                  >
                    asseguradas por lei
                  </a>
                </u>{" "}
                para que os pais possam proteger seus filhos na internet dos
                conteúdos inapropriados. Elas podem ser confusas, mas ajudam a
                manter seus filhos seguros online.
              </p>
              <p>
                Os pais devem saber onde, como e o que seus filhos fazem online
                para que possam protegê-los enquanto usam a internet. Então
                definir cuidadosamente as configurações pode tranquilizar os
                pais de que seus filhos navegam com segurança.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Crie um espaço seguro para que seu filho possa acessar a
                internet e entenda melhor o raciocínio{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CcMhChruuj3/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    title="https://www.instagram.com/p/CcMhChruuj3/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
              <p>
                Defina primeiro qual dispositivo e plataformas seu filho pode
                usar, o que ele pode fazer e consumir. Depois, aplique as
                configurações para assegurar que o que definiu aconteça.
              </p>
              <p>
                Revise as configurações conforme seu filho cresce, se desenvolve
                e se torna mais preparado.
              </p>
              <p>
                Se quiser nossa ajuda para isso, consulte nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="digital"
          titulo="Falar sobre maneiras de ser um bom cidadão digital."
          porque={
            <div>
              <p>
                Desde muito novos, as crianças estão construindo ideias sobre o
                que é aceitável (ou não) fazer online. Os pais podem ajudar a
                construir uma base sólida de gentileza digital e autocontrole,
                iniciando a discussão desde a primeira infância enquanto sua
                criança o vê usando os dispositivos e a internet.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Para começar a ensinar seu filho sobre cidadania digital,
                consulte o nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="privacidade"
          valor="familia"
          titulo="Definir e seguir regras de segurança para quem conversamos online."
          porque={
            <div>
              <p>
                Muita gente está online. Isso significa que pais e filhos podem
                conhecer todo tipo de pessoa com quem provavelmente não teriam
                falado pessoalmente.
              </p>
              <p>
                Às vezes, isso é positivo – como encontrar grupos de apoio para
                algo que esteja passando. Mas também pode levar a problemas,
                como quando alguém mal está interessado em você ou alguém da sua
                família e usa a internet como caminho mais fácil para chegar em
                vocês.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Converse sempre sobre como na internet nem sempre a pessoa pode
                ser quem diz ser. Dê o exemplo no seu uso da internet e converse
                sobre as pessoas que conheceram apenas na internet para
                entenderem melhor o contexto.
              </p>
              <p>
                Ensine seu filho a primeiro conversar na internet apenas com
                pessoas que ele conhece pessoalmente.
              </p>
              <p>
                Depois, ensine-o a como lidar com estranhos que conhecer na
                internet. Proteja informações que podem atingir você, seus
                filhos ou a sua família.
              </p>
              <p>
                Certifique que as suas crianças saibam a idade de quem está
                falando com elas.
              </p>
              <p>
                Para mais instruções sobre privacidade, segurança, o que evitar
                de compartilhar na internet e o que fazer caso seu filho esteja
                sendo aliciando online, consulte nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.privacidade.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="privacidade"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};

export default Step7;

import { Membro } from "./Membro";
import { Objetivo } from "./Objetivo";
import { Pergunta } from "./Pergunta";

export class Dados {
  [key: string]: any;
  familia: string;
  membros: Membro[];
  objetivo: Objetivo;
  pergunta: Pergunta;

  constructor() {
    this.familia = "";
    this.membros = [];
    this.objetivo = new Objetivo();
    this.pergunta = new Pergunta();
  }
}

export class Internet {
  [key: string]: any;
  diadia: boolean;
  brigar: boolean;
  consciente: boolean;
  reconhecer: boolean;
  consumir: boolean;
  prejudicial: boolean;
  respeitar: boolean;
  cyberbullying: boolean;
  educado : boolean;
  adicionais: string[];

  constructor(){
    this.diadia= true;
    this.brigar= false;
    this.consciente= false;
    this.reconhecer= false;
    this.consumir= true;
    this.prejudicial= true;
    this.educado= true;
    this.respeitar= true;
    this.cyberbullying= false;
    this.adicionais= [];
  }
}

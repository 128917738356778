import proteka from "../home/proteka.png";
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';
import { Dados } from "../pojos/Dados";
import GroupsIcon from '@mui/icons-material/Groups';
import { useEffect, useState } from "react";
import { Membro } from "../pojos/Membro";
import axios from 'axios';


interface Step11Props {
  dados: Dados; 
}
const Step11: React.FC<Step11Props> = ({ dados}) => {
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [enter, setEnter] = useState<boolean>(false);
  const [lastMembro, setLastMembro] = useState<Membro | undefined>(undefined);
 
  const handleClick = (membro?: Membro) => async () => {
    let sendata = {...dados};
    if (membro) {
      sendata.membros = [membro]
    } 
    const response = await axios.post('https://tempodetela.com.br/api/gem_pdf.php', sendata, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    setPdfData(url);
    setLastMembro(membro);
  };
  const doDownload = async () => {
    let sendata = {...dados};
    if (lastMembro) {
      sendata.membros = [lastMembro]
    } 
    const response = await axios.post('https://tempodetela.com.br/api/gem_pdf.php', sendata, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    if (lastMembro) {
      link.setAttribute('download', "tempo de tela - "+lastMembro.nome+'.pdf');
    }else{
      link.setAttribute('download', 'tempo de tela.pdf');
    }
    document.body.appendChild(link);
    link.click();

  }

  useEffect(() => {
    if (enter) return;
    setEnter(true);
    const fetchData = async () => {
      
      let sendata = dados;
      try{
      const response = await axios.post('https://tempodetela.com.br/api/gem_pdf.php', sendata, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setPdfData(url);
      setLastMembro(undefined);
    }catch (e) {
      //Like a ninja
    }
    };

      fetchData();
    

    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <div className="min-h-[46vh] w-max-[100vw] 
  sm:ml-[-10vw] 
  tablet:ml-[-14vw] tablet:w-[85vw] tablet:text-justify 
  mobile-lg:ml-[-13vw] mobile-lg:w-[85vw]
  mobile-md:ml-[-13vw] mobile-md:w-[85vw]">
      <div className="flex mt-10">
        <div>
          <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
        </div>

        <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] 
        mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw]
        mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
                Revisão
            </span>
            
            </div>
        <div className="text-Marinho text-xl">
          <p>Estas orientações são apenas os primeiros passos antes de dar a tecnologia para seu(s) filho(s), para dar sequência a utilização saudável e segura das telas é indispensável que sejam aplicadas configurações de segurança para ajudar você a proteger o seu filho na internet e nos mais variados aplicativos que ele usa.</p>
          <p>Com o plano deste site aplicado, você saberá quais tecnologias seu filho irá usar, por quanto tempo fará isso e quais conteúdos irá consumir, ou seja, terá em mãos todos os dispositivos e aplicativos para configurar ferramentas de segurança e aplicar o controle parental.</p>
          <br/>
          <strong>Para como proteger o seu filho nas telas e na internet, acesse o nosso </strong><u><a href="https://novoguiadecontroleparental.club.hotmart.com/" title="https://novoguiadecontroleparental.club.hotmart.com/"  target="_blank"
                  rel="noreferrer"><strong data-renderer-mark="true">guia de controle parental.</strong></a></u>
          <br/><br/>
          <p><strong>1.</strong> Considere todas as mudanças que virão a partir de toda a discussão feita e vá com calma.</p>
          <p><strong>2.</strong> Planeje a melhor forma de apoiar e ajudar as outras pessoas dentro da sua casa, de modo a seguir as orientações do site. Volte sempre que achar necessário.</p>
          <p><strong>3.</strong> IMPRIMA O DOCUMENTO E DEIXE AS ORIENTAÇÕES EM LOCAL VISÍVEL!</p>
        </div>
      <div id="familiares" className="mt-7 h-auto flex flex-wrap">
        <button key={"todos"} className="flex-shrink-0 min-w-1/6 mr-2 p-1 pl-4 pr-4 bg-[#b2acce] rounded-md border-[1px] border-RoyalRoxo text-RoyalRoxo hover:bg-[#b7ace6]" onClick={handleClick()}><GroupsIcon />Todos </button>
        {dados.membros.map((membro, index) => (
          <button key={index}  className="flex-shrink-0 min-w-1/6 mr-2 p-1 pl-4 pr-4 bg-[#b2acce] rounded-md border-[1px] border-RoyalRoxo text-RoyalRoxo hover:bg-[#b7ace6]" onClick={handleClick(membro)}><PersonIcon />{membro.nome} </button>
        ))}

      </div>
      <div className="mt-[1px] w-full h-[60vh] bg-white border-2 border-RoyalRoxo p-2">
      {pdfData && (
         <div className="h-[100%]">
          <iframe 
          id="myPdfIframe"
            src={pdfData+ "#zoom=100"} 
            width="100%" 
            height="100%" 
            style={{border: "none"}}
            title="PDF in an iFrame">
              Seu navegador não suporta iframes.
          </iframe>
          
        </div>
      )}
      </div>
      <button className="mt-2 mb-2 p-1 pl-4 pr-4 bg-RoyalRoxo text-white hover:bg-[#4020c9]"  onClick={() => (document.getElementById('myPdfIframe') as HTMLIFrameElement)?.contentWindow?.print()}>Imprimir <PrintIcon /></button>
      {" "}
      <button className="mt-2 mb-2 p-1 pl-4 pr-4 bg-RoyalRoxo text-white hover:bg-[#4020c9]"  onClick={doDownload}>Baixar <SaveIcon /></button>

    </div>
  );
}


export default  Step11;
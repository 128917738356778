import React, { useState } from "react";
import checkedImg from "../steps/img/checked.svg";
import { Membro } from "../pojos/Membro";

interface CardAdicionalProps {
  membros?: Membro[];
  pergunta: string;
  objetivo: string;
  setPergunta:  (perguntaOld: string, perguntaNew: string) => void;
  atualizarMembros?: (membros: Membro[]) => void;
}

const CardAdicional: React.FC<CardAdicionalProps> = ({
  membros,
  pergunta,
  objetivo,
  setPergunta,
  atualizarMembros,
}) => {

  const getTextoFaixa = (faixa: number): string => {
    switch (faixa) {
      case 0:
        return "0 a 24 meses";
      case 1:
        return "2 a 5 anos";
      case 2:
        return "6 a 11 anos";
      case 3:
        return "12 a 17 anos";
      case 4:
        return "18 a 59 anos";
      default:
        return "60 anos ou mais";
    }
  };

  const ToggleButton: React.FC<{
    nome: string;
    faixaEtaria: string;
    value: boolean;
    membro: Membro;
  }> = ({ nome, faixaEtaria, membro, value }) => {
    const [checked, setChecked] = useState(value);
  
    const toggle = () => {
      setChecked((prevChecked) => {
        // Use o valor anterior para garantir a sincronia
        const newChecked = !prevChecked;
        // Atualize o estado e, em seguida, o membro
        setChecked(newChecked);
        if (newChecked) {
          membro[objetivo].adicionais.push(pergunta);
        } else {
          if(membro[objetivo].adicionais.includes(pergunta)){
            membro[objetivo].adicionais.splice(membro[objetivo].adicionais.indexOf(pergunta), 1);
          }
        }
        if (membros) {
            atualizarMembros!(membros);
        }
        return newChecked;
      });
    };
  
    return (
      <div>
        <label className="flex items-center ml-4 mt-2 cursor-pointer">
          <div
            className={`flex items-center justify-center pt-[5px] pr-[10px] pb-[5px] pl-[10px] mb-5  rounded-[15px] duration-300 relative ${
              checked ? "bg-[#2B139254]" : "bg-[#eae8f5]"
            }`}
          >
            {checked && (
              <div className="flex justify-center items-center bg-[#2b1392] w-6 h-6 rounded-full absolute top-[5px] right-[10px] transform translate-x-1/2 -translate-y-1/2">
                <img className="flex h-auto" src={checkedImg} alt="" />
              </div>
            )}
            <div className="flex flex-col w-24 mb-2 m-0 pt-1">
              <span
                className={`flex justify-center items-center text-[#1E134D] font-codecProBold text-[14px]  h-5 leading-none `}
              >
                {" "}
                {nome}
              </span>
              <span className="flex justify-center items-center text-center text-[#1E134D] font-codecProBold text-[14px] w-[3rem] ml-6 h-5 leading-none displaytable">
                {" "}
                {faixaEtaria}
              </span>
            </div>
            <input type="checkbox" className="hidden" onClick={toggle} />
          </div>
        </label>
      </div>
    );
  };

  return (
    <div className="w-[56vw] border-2 border-RoyalRoxo mt-10 ml-[2vw] bg-white sm:w-[80vw] sm:ml-[1vw] tablet:w-[85vw] tablet:ml-[1vw] mobile-lg:w-[88vw] mobile-lg:ml-[0vw] mobile-md:w-[88vw] mobile-md:ml-[0vw] mobile-md:text-[0.9rem] mobile-md:flex mobile-md:flex-wrap">
      <span className="ml-[1.875rem] mt-[1.875rem] text-RoyalRoxo text-[1.75rem] font-codecProBold">
        Assunto:
      </span>
      <input
        type="text"
        className="ml-[1.475rem] mt-[1.875rem] text-RoyalRoxo text-[1.3rem] border-b-[1px] border-RoyalRoxo font-semibold mr-3 w-[40vw] h-auto mobile-md:w-[85%]"
        value={pergunta}
        onChange={(e) => setPergunta(pergunta, e.target.value)}
      />

      <div className="flex flex-wrap my-5 m-auto mr-5 ml-2">
        {membros &&
          membros.map((membro, index) => (
            <ToggleButton
              key={index}
              nome={membro.nome}
              faixaEtaria={getTextoFaixa(membro.faixa)}
              value={membro[objetivo].adicionais.includes(pergunta)}
              membro={membro}
            />
          ))}
      </div>
    </div>
  );
};

export default CardAdicional;

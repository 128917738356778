import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step5Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step5: React.FC<Step5Props> = ({ dados, setDados }) => {
  /* 
    adicionais: string[];*/

  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.equilibrio;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.equilibrio.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.equilibrio.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: {
        ...dados.pergunta,
        equilibrio: [...dados.pergunta.equilibrio, ""],
      },
    });
  };
  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };

  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
    <div className="flex mt-10">
      <div>
        <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
      </div>

      <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Equilíbrio de uso
        </span>
      </div>

      <div id="cards" className="m-0 p-0 ">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="rotina"
          titulo="Ter uma rotina planejada."
          porque={
            <p>
              <u>
                <a
                  href="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                  title="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                >
                  Crie tempo de tela em torno das atividades familiares, e não o
                  contrário
                </a>
              </u>
              , para garantir um equilíbrio saudável e priorizar as experiências
              e momentos compartilhados em família. Aqui nós vamos deixar
              espaços preferenciais para as atividades essenciais para a saúde
              como: sono, higiene pessoal, alimentação, brincadeiras, atividades
              físicas.
            </p>
          }
          como={
            <p>
              Definindo algumas tarefas não negociáveis que devem ser feitas
              antes do tempo de tela; planejando um dia com seus filhos com
              atividades e definindo horários em que as telas podem ser usadas.
              É importante que ao definir horários específicos para as
              atividades se tenha comprometimento para segui-los. Para melhor
              entendimento, assista a aula rotina do&nbsp;
              <u>
                <a
                  href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                  title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                >
                  guia de controle parental
                </a>
              </u>
              .
            </p>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="assutar"
          titulo="Conversar sobre conteúdos que podemos ter visto que foram assustadores, perturbadores ou  que nos incomodaram de alguma forma."
          porque={
            <p>
              Conversar com os filhos sobre conteúdos perturbadores é crucial
              para promover um ambiente seguro e de apoio, permitindo-lhes
              processar emoções, compreender o mundo e construir relações de
              confiança. Afinal, quanto mais chocante é um conteúdo, mais ele
              tende a se espalhar rapidamente na internet. Falar sobre isso em
              família nos permite compartilhar e encontrar soluções juntos
              quando surge um problema.
            </p>
          }
          como={
            <div>
              <ul>
                <li>
                  Converse com seus filhos sobre coisas que eles viram, leram ou
                  conversaram na internet. Faça perguntas como:
                </li>
                <ul className="list-disc pl-6 pt-3 pb-3">
                  <li> Você já viu um vídeo violento na sua rede social?</li>
                  <li>
                    {" "}
                    Você já leu comentários maldosos sobre você ou alguém que
                    você gosta? Já fez algum?
                  </li>
                  <li>
                    {" "}
                    Você já clicou acidentalmente em algo e se arrependeu?
                  </li>
                </ul>
                Ensine aos seus filhos que eles podem bloquear ou denunciar
                conteúdo perturbador.​ Também incentive a procurar conteúdo
                positivo e não apenas seguir o que a plataforma escolher para
                eles. Peça-lhes que compartilhem coisas engraçadas ou
                inspiradoras.
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="esnobar"
          titulo="Explicar para quem estiver na sua frente o que está fazendo caso pegue um dispositivo para que não se sinta ignorada."
          porque={
            <div>
              <p>
                As crianças pequenas estão constantemente nos observando e
                ouvindo. Por isso, desde mesmo muito novos, vale a pena
                ensiná-los a pensar e falar sobre o nosso uso de tecnologia, a
                fim de não ignorá-los.
              </p>
              <br />
              <p>
                Isso significa conversar com eles sobre como usamos telefones e
                computadores como ferramentas para organizar nossas vidas e nos
                comunicar com os entes queridos.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Tente "falar em voz alta" para narrar o que você está fazendo no
                seu dispositivo: "Apenas respondendo à mamãe — ela está fazendo
                compras agora" ou "Estou marcando um encontro para brincar com
                seu priminho Pedro".
              </p>
              <br />
              <p>
                Tanta organização da família acontece através de algum
                dispositivo. Quando sua atenção estiver voltada para o seu
                telefone ou computador, diga aos seus filhos o que você está
                fazendo. Se quiser ver mais sugestões, veja o{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/Cw8w-nnA-Un/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    vídeo
                  </a>
                </u>
                .
              </p>
              <br />
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="familia"
          titulo="Planejar uma atividade sem tela para fazer em família todos os dias."
          porque={
            <div>
              <p>
                Algumas das melhores lembranças e maiores risadas com a família
                vêm de momentos cotidianos: compartilhar uma refeição, brincar
                com um bichinho de estimação ou conversar no carro ou no ônibus,
                por exemplo. Este tempo juntos desempenha um papel importante na
                aprendizagem e desenvolvimento do seu filho.
              </p>
              <br />
              <p>
                Para adultos, desligar o telefone permite que você esteja
                totalmente presente. E não são apenas seus filhos que se
                beneficiam dessa época. Também pode aumentar sua saúde mental e
                a confiança nos pais.{" "}
                <u>
                  <a
                    href="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    title="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>
                , compartilho com você um material do nosso guia de controle
                parental com mais de 100 atividades sem telas para você se
                inspirar.
              </p>
              <br />
            </div>
          }
          como={
            <p>
              No mundo acelerado e conectado, a ideia de planejar atividades em
              família longe de telas de celular e computador pode parecer um
              desafio. No entanto, fazê-lo permite que a família se reconecte de
              uma maneira mais profunda e significativa. As atividades em
              família sem telas podem começar com algo tão simples quanto um
              jantar sem smartphones. Também é importante comunicar o propósito
              de desconectar-se das telas de maneira positiva, enfatizando os
              benefícios da conexão familiar e da construção de relacionamentos
              mais próximos. Planeje uma atividade sem tela no mesmo horário
              todos os dias ou torne-a mais espontânea como parte da rotina
              diária de vocês. Você pode consultar a aula de rotina do{" "}
              <u>
                <a
                  href="https://novoguiadecontroleparental.club.hotmart.com/"
                  title="https://novoguiadecontroleparental.club.hotmart.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  guia de controle parental.
                </a>
              </u>
              .
            </p>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="desligar"
          titulo="Criar o hábito de desligar a tv que não está sendo usada por ninguém."
          porque={
            <p>
              Muitas famílias têm o hábito de deixar a TV ligada quando ninguém
              está assistindo. Além de usar eletricidade extra, isso{" "}
              <u>
                <a
                  href="https://journals.sagepub.com/doi/abs/10.1177/0142723716639499"
                  title="https://journals.sagepub.com/doi/abs/10.1177/0142723716639499"
                  target="_blank"
                  rel="noreferrer"
                >
                  pode contribuir para atrasos no desenvolvimento da linguagem
                </a>
              </u>
              . Essa é uma oportunidade de ensinar aos seus filhos sobre
              economia de energia, hábitos saudáveis em relação ao consumo de
              mídia e redução da exposição excessiva a conteúdos indesejados.
              Além de promover a interação social ou outras atividades mais
              produtivas.
            </p>
          }
          como={
            <div>
              <p>
                Crie um novo hábito de desligar a TV quando não estiver em uso.
                Se você estiver se sentindo distraído, olhe ao redor e veja
                quais telas ou sons podem estar interrompendo seus pensamentos.
                Em seguida, desligue-os. Se você tem uma programação diária,
                pode encontrar um horário em que você e sua família possam
                cozinhar, ler ou sair ao ar livre. As crianças gostam de rotinas
                previsíveis e consistentes.
              </p>
              <br />
              <p>
                Para obter ideias sem tela para usar no momento, crie um pote de{" "}
                <u>
                  <a
                    href="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    title="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ideias de atividades
                  </a>
                </u>
                . Anote uma variedade de atividades diferentes e coloque-as na
                jarra. Pense em coisas como artes e ofícios, ir à biblioteca,
                praticar esportes ou qualquer um dos hobbies favoritos de seu
                filho.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="atividade"
          titulo="Participar de outras atividades disponíveis em nossa cidade, vizinhança ou algum lugar que frequentamos."
          porque={
            <div>
              <p>
                As crianças que estão mais conectadas às suas comunidades têm
                melhor saúde mental.
              </p>
              <br />
              <p>
                Passeios regulares ao seu{" "}
                <u>
                  <a
                    href="https://www.sesc.com.br/"
                    title="https://www.sesc.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    centro comunitário local
                  </a>
                </u>
                , bibliotecas ou áreas naturais podem construir esse senso de
                conexão. Essas e outras atividades, como participar de eventos
                de voluntariado, podem ajudar a construir um hábito saudável –
                se afastando regularmente da tecnologia e da internet para se
                conectar com o mundo real.
              </p>
            </div>
          }
          como={
            <p>
              Confira as atividades para crianças da sua cidade. No{" "}
              <u>
                <a
                  href="https://proteka.notion.site/Perfis-para-se-inspirar-f4dde7b3ff45483f8ea4304b06565218"
                  title="https://proteka.notion.site/Perfis-para-se-inspirar-f4dde7b3ff45483f8ea4304b06565218"
                  target="_blank"
                  rel="noreferrer"
                >
                  material de apoio do guia de controle parental
                </a>
              </u>{" "}
              nós temos indicações de perfis que indicam conteúdos, lugares e
              passeios para os pais levarem seus filhos por cidades de todo o
              país. Ao redescobrir a alegria de estar juntos, compartilhando
              momentos sem distrações digitais, as famílias podem criar memórias
              duradouras e fortalecer o relacionamento uns com os outros.
            </p>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="aplicativo"
          titulo="Ter menos aplicativos em nossos dispositivos."
          porque={
            <p>
              Uma grande variedade de aplicativos na tela inicial de um
              dispositivo pode ser tentadora de usar{" "}
              <a
                href="https://medium.com/thrive-global/how-technology-hijacks-peoples-minds-from-a-magician-and-google-s-design-ethicist-56d62ef5edf3"
                target="_blank"
                rel="noreferrer"
              >
                <u>sem nem pensar</u>
              </a>
              . Isso pode levar a mais tempo nos dispositivos.
            </p>
          }
          como={
            <div>
              <p>
                Sente-se com seus filhos para limpar as telas iniciais em seus
                dispositivos. Desinstale todos os aplicativos que eles não estão
                usando, não gostam ou que consomem muito tempo e atenção.
              </p>
              <br />
              <p>
                Você pode colocar aplicativos que eles não desejam desinstalar,
                mas não precisam estar imediatamente visíveis, em pastas. Isso
                também libera espaço para uma imagem de tela inicial bonita ou
                engraçada. Acesse o módulo de configurações do nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                , caso precise.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="lembrete"
          titulo="Configurar lembretes da tela de bloqueio."
          porque={
            <p>
              Às vezes, usamos nossos dispositivos sem nem pensar nisso,
              pegando-os por hábito. Um lembrete visual pode ajudar a reduzir a
              quantidade de rolagem sem sentido que você ou seus filhos fazem.
            </p>
          }
          como={
            <p>
              Encontre uma imagem ou meme engraçado, ou uma imagem ou citação
              inspiradora e salve-a como tela de bloqueio. Então, sempre que
              você pegar o telefone sem pensar, receberá um lembrete para fazer
              outra coisa ou usar o dispositivo intencionalmente.
            </p>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="limite"
          titulo="Definir limites de tempo de tela."
          porque={
            <div>
              <p>
                Na primeira infância,{" "}
                <u>
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6736327/"
                    title="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6736327/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    assistir 1 hora ou menos de conteúdos de entretenimento tem
                    uma conexão direta com resultados positivos de saúde
                  </a>
                </u>
                . Ensinar as crianças a autorregular o uso da mídia – em vez de
                você fazê-lo – pode ajudá-las a ter mais autocontrole à medida
                que envelhecem.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Limite o tempo de tela de forma fácil associando a uma atividade
                específica: um filme, dois desenhos, uma partida de jogo, por
                exemplo, caso você não use as configurações de controle
                parentais disponíveis para automatizar isso para você. Converse
                com seus filhos sobre seus limites de tempo e como é importante
                que usem desses recursos para ajudá-los a priorizar o que é mais
                importante enquanto usam a tecnologia sem deixar de cuidar de
                outras atividades essenciais.
              </p>
              <p>
                Peça ao seu filho para definir um cronômetro ou faça as
                configurações junto. Ele podem concordar em desligar o
                dispositivo ou a TV quando o próximo episódio terminar ou quando
                o relógio atingir um determinado horário.{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CXOQvtcLyN9/"
                    title="https://www.instagram.com/p/CXOQvtcLyN9/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                você pode ver um exemplo de configuração específica para
                automatizar o controle do tempo de tela dentro do youtube. Para
                outras configurações, consulte o{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="saudavel"
          titulo="Garantir que o tempo de tela não interfira na atividade física e na alimentação saudável."
          porque={
            <div>
              <p>
                <a
                  href="https://bvsms.saude.gov.br/bvs/publicacoes/guia_atividade_fisica_populacao_brasileira.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>
                    A atividade física é importante para todas as crianças,
                    inclusive para os bebês
                  </u>
                </a>
                .
              </p>
              <br />
              <p>
                Muito tempo de tela está associado a um maior risco de
                obesidade, principalmente quando o tempo de uso de telas e da
                internet substitui o tempo do jogo ao ar livre e da atividade
                física.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Priorize tempo para a atividade física. Isso pode incluir
                caminhadas, exercícios e movimentos divertidos, como dançar ou
                brincar. Desligue os dispositivos durante as refeições.
                Aproveite o tempo para falar sobre como foi o dia do seu filho
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="equilibrio"
          valor="jogo"
          titulo="Prevenir hábitos pouco saudáveis de jogos de vídeo."
          porque={
            <div>
              <p>
                Jogar videogame pode ser uma parte divertida e normal do(a)
                seu(ua) filho(a). Mas há crianças que têm dificuldade em
                controlar o tempo que passam jogando.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Defina regras em torno dos jogos e converse sobre elas.
                Estabeleça horários para jogar, por exemplo, após a escola ou o
                dever; revise os jogos favoritos do seu filho e converse sobre
                eles. Joguem juntos isso pode ajudar a entender os jogos,
                explicar coisas que acontecem durante a partida e a estabelecer
                limites. Além disso, esteja ciente dos jogos que seu filho está
                baixando e jogando com ferramentas de controle parental como{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CovTRydOv3-/"
                    title="https://www.instagram.com/p/CovTRydOv3-/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    neste exemplo
                  </a>
                </u>
                . Evite jogos violentos e busque substituí-los, mantenha
                dispositivos em áreas comuns e promova atividades ao ar livre.
                Converse com seu pré-adolescente/adolescente sobre como alguns
                videogames podem ocultar anúncios ou mensagens nos jogos. Eles
                também podem coletar informações pessoais, como sua localização
                e microfones e deixá-los expostos a terceiros. Para outras
                configurações de segurança, consulte o{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.equilibrio.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="equilibrio"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};

export default Step5;

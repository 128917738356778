export default function Ternos() {
  return (
    <div className="rubik-500">
      <br />
      <br />
      <h1>Termos de uso e avisos legais</h1>
      <br />
      <span>
        Ao realizar qualquer prática referente às informações oferecidas em
        nosso site ou em qualquer um dos infoprodutos aqui ofertados, ou em caso
        esteja em dúvida, o bom senso deve ser usado. Se não o fizer, o usuário
        está agindo por sua conta e risco. O comprador ou leitor ou utilizador
        do site de tempo de tela da PROTEKA, assume todos os riscos sobre
        qualquer coisa que possa aprender aqui. Este website não é
        responsabilizado por nenhuma piora, ou por nenhum outro problema que o
        usuário possa vir a apresentar. A ideia básica é deixar o(a)
        consumidor(a) mais feliz e preparado para enfrentar as situações da vida
        relacionadas ao uso da internet e das tecnologias do dia a dia. Todos os
        dados informados (nome da família, nome de cada familiar, faixas de
        idade.) somente serão utilizados com o propósito de adequar as
        informações que serão atribuídas relacionadas a idade e gerar o
        relatório final.
      </span>
      <br />
      <br />

      <h2>Política de reembolso</h2>
      <br />
      <span>
        Caso não obtenha resultados satisfatórios ou em caso de insatisfação com
        o conteúdo após o pagamento de qualquer infoproduto aqui oferecido, o
        usuário poderá solicitar o reembolso do valor gasto via e-mail, que será
        efetuado sem quaisquer perguntas ou empecilhos dentro do prazo de
        garantia (7 dias).
      </span>
      <br />
      <br />
      <span>
        O visitante que comprar qualquer infoproduto do site tempo de tela ou
        PROTEKA estará automaticamente concordando com todos os termos aqui
        descritos. É de extrema e única responsabilidade informar corretamente
        os seus dados cadastrais, principalmente informar um e-mail válido para
        que possa receber as informações necessárias para o acesso do
        infoproduto contratado.
      </span>
      <br />
      <br />
      <span>
        A partir do momento em que o comprador tenha preenchido o e-mail
        incorretamente, é de sua única responsabilidade entrar em contato
        através do endereço: suporte@proteka.com.br para informar o e-mail
        correto, junto com todas as suas informações cadastrais, para o envio
        das informações necessárias após o pagamento. Ficando dessa forma o
        website livre de qualquer responsabilidade sobre a informação incorreta
        de dados pessoais e endereços eletrônicos. O pagamento dos infoprodutos
        é processado de forma segura através da Hotmart, empresa do Grupo
        Buscapé. Os dados de acesso ao conteúdo contratado só serão enviados
        após a confirmação do pagamento.
      </span>
      <br />
      <br />
      <br />
      <h2>Políticas de Privacidade</h2>
      <br />
      <span>
        Todas as suas informações pessoais recolhidas serão usadas para ajudar a
        tornar a sua visita ao nosso site o mais produtiva e agradável possível.
        A garantia da confidencialidade dos dados pessoais dos utilizadores do
        nosso site é importante para a PROTEKA. Todas as informações pessoais
        relativas a membros, assinantes, clientes ou visitantes que usem a
        calculadora de tempo de tela ou qualquer outro site da PROTEKA serão
        tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26
        de outubro de 1998 (Lei n.º 67/98). A informação pessoal recolhida pode
        incluir o seu nome, e-mail, número de telefone e/ou telemóvel, morada,
        data de nascimento e/ou outros. O uso das aplicações e serviços PROTEKA
        pressupõe a aceitação deste Acordo de privacidade. A equipe da PROTEKA
        reserva-se ao direito de alterar este acordo sem aviso prévio. Deste
        modo, recomendamos que consulte a nossa política de privacidade com
        regularidade de forma a estar sempre atualizado(a).
      </span>
      <br />
      <br />
      <br />

      <h3>Os anúncios</h3>
      <br />
      <span>
        Tal como outros websites, coletamos e utilizamos informação contida nos
        anúncios. A informação contida nos anúncios inclui o seu endereço IP
        (Internet Protocol), o seu ISP (Internet Service Provider, como o Sapo,
        Clix, ou outro), o browser que utilizou ao visitar o nosso website (como
        o Internet Explorer ou o Firefox), o tempo da sua visita e que páginas
        visitou dentro do nosso website.
      </span>
      <br />
      <br />
      <br />

      <h3>Os Cookies e Web Beacons</h3>
      <br />
      <span>
        Utilizamos cookies para armazenar informação, tais como as suas
        preferências pessoais quando visita o nosso website. Isso poderá incluir
        um simples popup ou uma ligação em vários serviços que providenciamos,
        tais como fóruns. Em adição também utilizamos publicidade de terceiros
        no nosso website para suportar os custos de manutenção. Alguns destes
        publicitários poderão utilizar tecnologias como os cookies e/ou web
        beacons quando publicitam no nosso website, o que fará com que esses
        publicitários (como o Google através do Google AdSense) também recebam a
        sua informação pessoal, como o endereço IP, o seu ISP, o seu browser,
        etc. Esta função é geralmente utilizada para geotargeting (mostrar
        publicidade de Lisboa apenas aos leitores oriundos de Lisboa por ex.) ou
        apresentar publicidade direcionada a um tipo de utilizador (como mostrar
        publicidade de restaurante a um utilizador que visita sites de culinária
        regularmente, por ex.). Você detém o poder de desligar os seus cookies,
        nas opções do seu browser, ou efetuando alterações nas ferramentas de
        programas Antivírus, como o Norton Internet Security. No entanto, isso
        poderá alterar a forma como interage com o nosso website ou com outros
        websites. Isso poderá afetar ou não permitir que faça logins em
        programas, sites ou fóruns da nossa e de outras redes.
      </span>
      <br />
      <br />
      <br />

      <h3>Ligações a Sites de terceiros</h3>
      <br />
      <span>
        A PROTEKA possui ligações com outros sites, os quais, a nosso ver, podem
        conter informações/ferramentas úteis para os nossos visitantes. A nossa
        política de privacidade não é aplicada a sites de terceiros, pelo que,
        caso visite outro site a partir do nosso, deverá ler a sua política de
        privacidade. Não nos responsabilizamos pela política de privacidade ou
        conteúdo presente nesses outros sites.
      </span>
      <br />
      <br />

      <span>Suporte : suporte@proteka.com.br</span>
      <br />

      <span>CNPJ: 40.153.459.0001/40 </span>

      <br />
      <br />
    </div>
  );
}

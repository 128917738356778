export class Objetivo {
  [key: string]: any;
  equilibrio: boolean;
  internet: boolean;
  privacidade: boolean;
  tempo: boolean;
  conversar: boolean;
  folga: boolean;

  constructor(){
    this.equilibrio= false;
    this.internet= false;
    this.privacidade= false;
    this.tempo= false;
    this.conversar= false;
    this.folga= false;
  }
}

import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

const YouTubeVideo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getHeight = () => {
    if (windowWidth >= 150 && windowWidth <= 320) return '195';
    if (windowWidth >= 321 && windowWidth <= 375) return '300';
    if (windowWidth >= 376 && windowWidth <= 460) return '360';
    if (windowWidth >= 461 && windowWidth <= 768) return '480';
    if (windowWidth >= 769 && windowWidth <= 1024) return '480';
    if (windowWidth >= 1025 && windowWidth <= 1280) return '480';
    if (windowWidth >= 1281 && windowWidth <= 1440) return '480';
    if (windowWidth >= 1441 && windowWidth <= 1535) return '480';
    if (windowWidth >= 1360 && windowWidth <= 1366) return '480';

    // Default height for larger screens
    return '480';
  };

  const getWidth = () => {
    if (windowWidth >= 150 && windowWidth <= 320) return '195';
    if (windowWidth >= 321 && windowWidth <= 375) return '300';
    if (windowWidth >= 376 && windowWidth <= 460) return '360';
    if (windowWidth >= 461 && windowWidth <= 768) return '600';
    if (windowWidth >= 769 && windowWidth <= 1024) return '700';
    if (windowWidth >= 1025 && windowWidth <= 1280) return '700';
    if (windowWidth >= 1281 && windowWidth <= 1440) return '800';
    if (windowWidth >= 1441 && windowWidth <= 1535) return '900';
    if (windowWidth >= 1360 && windowWidth <= 1366) return '700';

    // Default height for larger screens
    return '800';
  };

  const opts = {
    height: getHeight(),
    width: getWidth(), // Largura do vídeo em relação ao contêiner pai
  };

  return (
    <div className="flex flex-col gap-5">
      <span className="flex justify-center text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%]">
        {" "}
        Tutorial{" "}
      </span>

      <div className="flex justify-center pt-6 max-w-[100%]">
        <YouTube videoId="MPRgDzwUu6I" opts={opts} />
      </div>
    </div>
  );
};

export default YouTubeVideo;

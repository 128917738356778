import { Equilibrio } from "./Equilibrio";
import { Internet } from "./Internet";
import { Privacidade } from "./Privacidade";
import { Tempo } from "./Tempo";
import { Conversar } from "./Conversar";
import { Folga } from "./Folga";

export class Membro {
  [key: string]: any;
  nome: string;
  faixa: number;
  equilibrio: Equilibrio;
  internet: Internet;
  privacidade: Privacidade;
  tempo: Tempo;
  conversar: Conversar;
  folga: Folga;

  constructor(){
    this.nome= "";
    this.faixa= 0;
    this.equilibrio= new Equilibrio();
    this.internet= new  Internet();
    this.privacidade= new Privacidade();
    this.tempo= new Tempo();
    this.conversar= new Conversar();
    this.folga= new Folga();
  }
  get(key: string): any {
    return this[key];
  }
}
export class Equilibrio {
  [key: string]: any;
  rotina: boolean;
  assutar: boolean;
  esnobar: boolean;
  familia: boolean;
  desligar: boolean;
  atividade: boolean;
  aplicativo: boolean;
  lembrete: boolean;
  limite: boolean;
  saudavel: boolean;
  jogo: boolean;
  adicionais: string[];

  constructor(){
    this.rotina= false;
    this.assutar= false;
    this.esnobar= false;
    this.familia= true;
    this.desligar= true;
    this.atividade= false;
    this.aplicativo= true;
    this.lembrete= false;
    this.limite= true;
    this.saudavel= true;
    this.jogo= false;
    this.adicionais= [];
  }
}

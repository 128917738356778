  export class Pergunta {
    equilibrio: string[];
    internet: string[];
    privacidade: string[];
    tempo: string[];
    conversar: string[];
    folga: string[];
 
   constructor() {
     this.equilibrio=[];
     this.internet=[];
     this.privacidade=[];
     this.tempo=[];
     this.conversar=[];
     this.folga=[];
   }
 }
 

import logo from './logo_proteka.png'
import incial from './07v_F.webp'
import encontra from './03v_F.webp'
import junte from './02v_F.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Link } from 'react-router-dom'
import Rodape from './rodape'



export default function  Home(){ 
    return (
 <div className="flex flex-wrap">
      
      <div className="bg-BgHome h-screen w-screen flex flex-wrap order-1 mobile-lg:flex-col mobile-md:flex-col">
        
      <div className={`block mt-[5vh] w-1/2 text-white text-2xl font-codecProBold 
      sm:mt-[5vh] 
      md:mt-[5vh] 
      mobile-lg:mt-[5vh] mobile-lg:w-[90vw]
      mobile-md:mt-[5vh] mobile-md:w-[90vw]
      mobile-sm:mt-[5vh] mobile-sm:w-[90vw]
      mobile-md:ml-4
      tablet:mt-[5vh] 
      mobile:w-screen mobile:text-xl order-1`}>
            <span className=' ml-[5vw] mobile:mt-[5vh] mobile:ml-[5vh] mobile:order-1'>
              TEMPO DE TELA</span>
        </div>       
       

        <div className={`flex w-1/2  order-2 
        sm:w-[45vw] 
        md:w-[45vw] 
        note:w-[45vw] 
        tablet:w-[40vw] 
        mobile-lg:w-[40vw] 
        mobile-md:w-[40vw] 
        mobile-sm:w-[40vw] 
        mobile:absolute mobile:justify-center mobile:w-screen`}>

              <img className={`w-[50vw] h-auto mt-20 ml-[-5rem]
              mobile:h-[auto] mobile:w-[80%] mobile:max-w-[22rem] mobile:mt-[30vh] mobile:mb-[5%] mobile:order-3 
              note:h-[70%] note:w-auto note:mt-[15vh] 
              mobile-lg:mt-[27vh] mobile-lg:h-[35%] mobile-lg:w-auto mobile-lg:ml-4 mobile-lg:absolute
              mobile-md:mt-[34vh] mobile-md:h-[30%] mobile-md:w-auto mobile-md:ml-[10vw] mobile-md:absolute
              mobile-sm:mt-[27vh] mobile-sm:h-[25%] mobile-sm:w-auto mobile-md:sm-[0vw] mobile-sm:absolute
              tablet:mt-[32vh] tablet:h-[22rem] tablet:w-auto tablet:ml-[-16rem] tablet:absolute
              `} src={incial} alt=''/>
            </div>

            <div className={`flex w-1/2 text-Amarelo text-[5rem] font-bold font-codecProBold order-3 mt-[-8rem]
            sm:mt-[10vh] 
            md:mt-[0vh]  md:text-[4rem]
            mobile:w-screen mobile:text-4xl  mobile:order-2 mobile:absolute note:text-[4rem]
            mobile-lg:w-[90vw] mobile-lg:h-[auto] mobile-lg:text-[4rem]
            tablet:w-[90vw] tablet:h-[auto] tablet:text-[4rem]
            mobile-md:w-[90vw] mobile-md:h-[auto] mobile-md:text-[4rem]
            mobile-sm:w-[90vw] mobile-sm:h-[auto] mobile-sm:text-[3rem]
            `}>
             <span className={`ml-[10%] w-[60%]  mt-[-30rem] 
             sm:mt-[-55vh] sm:w-[70%] sm:text-[3.6rem] 
             md:mt-[-50vh] md:w-[70%] 
             lg:mt-[-47vh] lg:w-[70%] 
             xl:mt-[-47vh] xl:w-[70%] 
             note:mt-[-50vh] note:w-[70%] 
             tablet:mt-[15vh]  tablet:w-[90vw]  tablet:text-[3.2rem] tablet:ml-10
             mobile-lg:mt-[20vh]  mobile-lg:w-[80vw]  mobile-lg:text-[2.8rem] mobile-lg:ml-[5vw]
             mobile-md:mt-[22vh]  mobile-md:w-[90vw]  mobile-md:text-[2.6rem] 
             mobile-sm:mt-[-10vh]  mobile-sm:w-[90vw]  mobile-sm:text-[2.8rem] 
             mobile:w-[80%] mobile:mt-[12vh] leading-none `}>Crie um espaço seguro para sua família na internet</span>
              </div>            

             <div className={`flex w-1/2 mobile:w-screen order-4 mobile:order-4 mobile:ml-[25vw]`}>
              <img className={`w-[auto] h-[8vh] ml-[37vw] pb-5 
              mobile:h-[6vh] mobile:ml-[45vw] mobile:mt-[20vh] mobile:absolute 
              note:mt-[-7vh] note:ml-[31vw] 
              tablet:ml-[37em] tablet:w-[14vw] tablet:h-[3rem] tablet:mt-[37vh]
              sm:ml-[55%]
              md:ml-[55%]
              mobile-lg:ml-[19rem] mobile-lg:w-[13vw] mobile-lg:h-auto mobile-lg:mt-[34vh]
              mobile-md:ml-[16rem] mobile-md:w-[14vw] mobile-md:h-auto mobile-md:mt-[30vh]
              mobile-sm:ml-[15rem] mobile-sm:w-[15vw] mobile-sm:h-[auto] mobile-sm:mt-[16vh] mobile-sm:absolute
              `} src={logo} alt='' />
            </div>

            
            <div className={
                `flex ml-[5vw] bg-Amarelo text-white text-[3rem] text-bold rounded-full font-bold  justify-center items-center 
                h-[4rem] mt-[-25vh] cursor-pointer font-codecProBold p-[2rem]  order-5
                sm:mt-[-20vh] sm:mb-10 
                md:mt-[-20vh] md:mb-10
                note:mt-[-25vh] 
                tablet:mt-[45vh]  tablet:ml-[-25vh]  
                mobile:w-[85vw] mobile:order-5 mobile:mt-[83vh] mobile:absolute mobile:text-5xl
                mobile-lg:mt-[77vh] mobile-lg:ml-[-90vw] mobile-lg:w-[80vw]
                mobile-md:mt-[80vh] mobile-md:ml-[-90vw] mobile-md:w-[80vw] mobile-md:text-[2.0rem] size-min-context
                mobile-sm:mt-[14vh] mobile-sm:ml-[5vw] mobile-sm:w-[80vw] mobile-sm:text-[2.2rem] size-min-context
                
              `}><Link to={'/steps/'} className="nav-link">Começar</Link>
              <div className="bg-BgHome h-12 w-12 mb-1 ml-5 rounded-full flex justify-center items-center "> 
              <Link to={'/steps/'}><PlayArrowIcon /> </Link>
              </div>
            </div>

      </div>

      <div className="bg-CinzaAzul flex flex-wrap order-2 w-[100%]">
        
        <h2 className={`text-7xl font-bold font-codecProBold text-BgHome w-screen mt-[6vh] mb-[6vh] ml-[5vw] 
        sm:text-5xl sm:ml-[5rem] 
        md:text-5xl
        note:text-5xl
        tablet:text-5xl
        mobile-lg:text-5xl
        mobile-md:text-5xl
        `}>O que você encontra aqui</h2>

        <div className={`ml-[0vw] gap-10 grid grid-cols-2 
        sm:gap-0 sm:grid-cols-1
        md:gap-0
        note:gap-0
        mobile-lg:gap-0 mobile-lg:grid-cols-1        
        tablet:gap-0 tablet:grid-cols-1
        mobile-md:gap-0 mobile-md:grid-cols-1        
        `}>

              <div className={`ml-[5vw] gap-10 grid grid-cols-2 
              sm:gap-0 sm:grid-cols-2 sm:m-auto
              md:gap-4
              note:gap-6
              tablet:gap-6 tablet:grid-cols-1
              mobile-lg:gap-6 mobile-lg:grid-cols-1
              mobile-md:gap-6 mobile-md:grid-cols-1
              `}>
                    
                    <div className={`flex w-[25vw] h-[30vh] bg-BoxLilas rounded-xl justify-center items-center text-4xl font-bold font-codecProBold text-Marinho 
                    sm:text-2xl sm:w-[40vw] 
                    md:text-2xl md:w-[30vw] md:h-[13rem]
                    note:text-2xl note:w-[28vw] note:h-[12rem] note:mb-7
                    tablet:text-xl tablet:w-[90vw] tablet:h-auto
                    mobile-lg:text-xl mobile-lg:w-[82vw] mobile-lg:h-auto
                    mobile-md:text-xl mobile-md:w-[84vw] mobile-md:h-auto
                    `}>
                      <span className='p-[2vh]'>LISTA DE PRIORIDADES PARA ESCOLHER A TELA MAIS SEGURA</span>
                    </div>
                  
                    <div className={`flex w-[25vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-4xl font-bold font-codecProBold text-Marinho 
                    sm:text-2xl sm:w-[40vw] sm:ml-4  sm:mb-5
                    md:text-2xl md:w-[30vw] md:ml-[5.5rem] md:h-[13rem]
                    note:text-2xl note:w-[28vw] note:ml-24 note:h-[12rem]
                    tablet:text-xl tablet:w-[90vw] tablet:ml-[0] tablet:h-auto
                    mobile-lg:text-xl mobile-lg:w-[82vw] mobile-lg:ml-[0] mobile-lg:h-auto
                    mobile-md:text-xl mobile-md:w-[84vw] mobile-md:ml-[0] mobile-md:h-auto
                    `}>
                      <span className='p-[2vh]'>DICAS PRÁTICAS PARA AJUDAR A FAZER O PLANO FUNCIONAR</span>
                    </div>
                    
                    <div className={`flex w-[25vw] h-[30vh] bg-BoxLilas rounded-xl justify-center items-center text-4xl font-bold font-codecProBold text-Marinho 
                    sm:text-2xl sm:w-[40vw] 
                    md:text-2xl md:w-[30vw] md:h-[13rem]
                    note:text-2xl note:w-[28vw] note:mt-[-3vh] note:h-[12rem]
                    tablet:text-xl tablet:w-[90vw] tablet:ml-[0] tablet:h-auto
                    mobile-lg:text-xl mobile-lg:w-[82vw] mobile-lg:h-auto
                    mobile-md:text-xl mobile-md:w-[84vw] mobile-md:h-auto
                    `}>
                      <span className='p-[2vh]'>OPÇÃO DE SALVAR SEU PLANO E RETORNAR SEMPRE QUE DESEJAR</span>
                    </div>
                  
                    <div className={`flex w-[25vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-4xl font-bold font-codecProBold text-Marinho 
                    sm:text-2xl sm:w-[40vw] sm:ml-4
                    md:text-2xl md:w-[30vw] md:ml-[5.5rem] md:h-[13rem]
                    note:text-2xl note:w-[28vw] note:ml-24 note:mt-[-3vh] note:h-[12rem]
                    tablet:text-xl tablet:w-[90vw] tablet:ml-[0] tablet:h-auto
                    mobile-lg:text-xl mobile-lg:w-[82vw] mobile-lg:ml-[0] mobile-lg:h-auto
                    mobile-md:text-xl mobile-md:w-[84vw] mobile-md:ml-[0] mobile-md:h-auto
                    `}>
                      <span className='p-[2vh]'>CAPACIDADE DE IMPRIMIR OU COMPARTILHAR SEU PLANO FINALIZADO</span>
                  </div>
              
              </div>

              <div className={`flex w-[40vw] ml-[10vw] 
              sm:w-[70%] sm:ml-[17vw] sm:mt-[5vw]
              md:w-[70%] md:ml-[17vw]
              lg:w-[95%] lg:ml-[5vw]
              xl:w-[90%] xl:ml-[5vw]
              note:w-[80%] note:ml-[12vw]
              tablet:w-[70%] tablet:ml-[17vw] tablet:mt-[5vw]
              mobile-lg:w-[70%] mobile-lg:ml-[12vw] mobile-lg:mt-6
              mobile-md:w-[70%] mobile-md:ml-[14vw] mobile-md:mt-6
              `}>
                <img className='w-auto ' src={encontra} alt='' />
            </div>  

      </div>   

        <h2 className="text-6xl font-sans text-BgHome w-screen flex justify-center mt-[5vh] mb-[5vh] 
        note:text-[2.2rem] 
        tablet:text-[2.2rem] 
        mobile-lg:text-[2.2rem]  mobile-lg:ml-[-1.5rem]
        mobile-md:text-[2.2rem] 
        ">IMPORTANTE</h2>

        <div className={`grid grid-cols-4 gap-4 
        sm:grid-cols-2 sm:m-auto sm:gap-10 
        md:gap-2
        tablet:grid-cols-1
        mobile-lg:grid-cols-1
        mobile-md:grid-cols-1
        `}>

              <div className={`flex w-[20vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-xl font-normal text-Marinho 
              sm:w-[40vw] sm:ml-0 
              md:w-[22vw] md:mr-0 md:h-[40vh]
              lg:w-[22vw] lg:mr-0 lg:h-[40vh]
              note:w-[20vw] note:mr-0 note:h-[42vh] note:text-[1rem]
              tablet:w-[90vw] tablet:ml-[5vw] tablet:h-[25vh] 
              mobile-lg:w-[82vw] mobile-lg:h-auto mobile-lg:ml-[5vw]
              mobile-md:w-[84vw] mobile-md:h-auto mobile-md:ml-[5vw]
              `}>
                <span className='p-[2vh]'>As informações contidas neste guia não devem ser usadas como substitutas para os cuidados médicos e do aconselhamento do seu pediatra.</span>
              </div>

              <div className={`flex w-[20vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-xl font-normal text-Marinho 
              sm:w-[40vw] sm:ml-0 
              md:w-[22vw] md:mr-0 md:h-[40vh]
              lg:w-[22vw] lg:mr-0 lg:h-[40vh]
              note:w-[20vw] note:mr-0 note:h-[42vh] note:text-[1rem]
              tablet:w-[90vw] tablet:ml-[5vw] tablet:h-[25vh] 
              mobile-lg:w-[82vw] mobile-lg:h-auto mobile-lg:ml-[5vw]
              mobile-md:w-[84vw] mobile-md:h-auto mobile-md:ml-[5vw]
              `}>
                <span className='p-[2vh]'>Pode haver variações no tratamento que seu pediatra pode recomendar com base em fatos e circunstâncias individuais.</span>
              </div>

              <div className={`flex w-[20vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-xl font-normal text-Marinho 
              sm:w-[40vw] sm:ml-0 
              md:w-[22vw] md:mr-0 md:h-[40vh]
              lg:w-[22vw] lg:mr-0 lg:h-[40vh]
              note:w-[20vw] note:mr-0 note:h-[42vh] note:text-[1rem]
              tablet:w-[90vw] tablet:ml-[5vw] tablet:h-[25vh] 
              mobile-lg:w-[82vw] mobile-lg:h-auto mobile-lg:ml-[5vw]
              mobile-md:w-[84vw] mobile-md:h-auto mobile-md:ml-[5vw]
              `}>
                <span className='p-[2vh]'>Se você não é casado(a), pense no papel que deseja que a tecnologia desempenhe em sua casa antes de iniciar as discussões com seu(s) filho(s).</span>
              </div>

              <div className={`flex w-[20vw] h-[30vh] ml-[20%] bg-BoxLilas rounded-xl justify-center items-center text-xl font-normal text-Marinho 
              sm:w-[40vw] sm:ml-0 md:w-[22vw] 
              md:mr-0 md:h-[40vh]
              lg:w-[22vw] lg:mr-0 lg:h-[40vh] 
              note:w-[20vw] note:mr-0 note:h-[42vh] note:text-[1rem]
              tablet:w-[90vw] tablet:ml-[5vw] tablet:h-[25vh] 
              mobile-lg:w-[82vw] mobile-lg:h-auto mobile-lg:ml-[5vw]
              mobile-md:w-[90vw] mobile-md:h-auto mobile-md:ml-[5vw]
              `}>
                <span className='p-[2vh]'>Se você é divorciado(a), faça o possível para conversar com seu(ua) ex-cônjuge sobre porque deseja instruir seu(s) filho(s) desta forma e ajudá-lo(s) a estabelecer limites saudáveis com a tecnologia.</span>
              </div>
                  
        </div>
            
            <div className={`flex w-full justify-center gap-10 pt-[10vh] mb-32 
            sm:flex-wrap sm:mb-[0] 
            md:flex-wrap md:mb-[0]
            note:flex-wrap note:mb-[0]
            mobile-lg:flex-wrap mobile-lg:mb-[0]
            mobile-md:flex-wrap mobile-md:mb-[0]
            `}>

              <div className='flex bg-CinzaAzul-300 
              sm:h-[auto] 
              md:h-[auto]
              note:h-[auto]
              mobile-lg:h-[auto]
              mobile-md:h-[auto]
              '>
                <img className={`w-[50vw] 
                sm:w-auto sm:h-auto sm:absolute sm:max-w-[100%] sm:max-h-[50%] sm:mt-[-5vh] sm:ml-[-15vw] 
                md:w-auto md:h-auto md:absolute md:max-w-[80%] md:max-h-[35%] md:mt-6 md:ml-[-30vw]
                lg:w-auto lg:h-auto lg:absolute lg:max-w-[50%] lg:max-h-[50%] lg:mt-6 lg:ml-[-30vw]
                xl:w-auto xl:h-auto xl:absolute xl:max-w-[50%] xl:max-h-[50%] xl:mt-6 xl:ml-[-30vw]
                note:w-auto note:h-auto note:absolute note:max-w-[45%] note:max-h-[50%] note:mt-0 note:ml-[-30vw]
                tablet:w-auto tablet:h-auto tablet:absolute tablet:max-w-[90%] tablet:max-h-[70%] tablet:mt-[-5vw] tablet:ml-[-27vw]
                mobile-lg:w-[80%] mobile-lg:ml-[6vw] mobile-lg:mt-[-5vh]
                mobile-md:w-[80%] mobile-md:ml-[12vw] mobile-md:mt-[-5vh]
                `} src={junte} alt='' />
              </div>

              <div className='flex flex-col bg-CinzaAzul w-[30vw] sm:h-[auto] md:h-[auto]'>

                <span className='text-Marinho pt-6 text-6xl w-[30vw] font-codecProBold 
                sm:w-[90vw] sm:ml-[-10vw] sm:mt-[45vh]
                md:w-[50vw] md:ml-[10vw]
                lg:w-[50vw] lg:ml-[13vw]
                xl:w-[50vw] xl:ml-[13vw]
                note:w-[50vw] note:ml-[10vw] note:text-[2.5rem]
                tablet:w-[90vw] tablet:ml-[-4rem] tablet:text-[2.5rem] tablet:mt-[30rem]
                mobile-lg:w-[90vw] mobile-lg:ml-[-26vw] mobile-lg:text-[2.5rem]
                mobile-md:w-[90vw] mobile-md:ml-[-23vw] mobile-md:text-[2.3rem]
                '> Junte-se a nós</span>
                
                <span className='text-Marinho pt-10 text-4xl w-[30vw] 
                sm:ml-[-20vw] sm:w-[90vw] sm:text-3xl sm:max-w-[70vw]
                md:ml-[10vw] md:w-[50vw] md:text-3xl md:max-w-[45vw]
                lg:ml-[13vw] lg:w-[40vw]
                xl:ml-[13vw] xl:w-[40vw]
                note:ml-[10vw] note:w-[40vw] note:text-[2rem]
                tablet:ml-[-30vw] tablet:w-[80vw] tablet:text-[1.5rem] tablet:text-justify tablet:mt-[0rem]
                mobile-lg:ml-[-26vw] mobile-lg:w-[75vw] mobile-lg:text-[1.5rem] mobile-lg:text-justify mobile-lg:mt-[-3vh]
                mobile-md:ml-[-22vw] mobile-md:w-[75vw] mobile-md:text-[1.5rem] mobile-md:text-justify mobile-md:mt-[-3vh]
                '>
                  Nosso objetivo é apoiar os pais nessa jornada de manter seus filhos seguros online e promover um tempo de tela seguro e positivo. 
                  Com o nosso guia passo a passo, você terá recursos para criar um plano personalizado, abordando temas como controle do tempo de 
                  tela, conteúdo apropriado e diálogo sobre segurança, privacidade e bem-estar digital.
                </span>

                <div className={
                `flex mt-[5vh] bg-BgHome text-white text-2xl text-bold rounded-full font-bold  justify-center items-center 
                w-[17vw] h-[8vh] cursor-pointer font-codecProBold pt-2 p-1 
                sm:w-[35vw] sm:ml-[0vw] sm:mt-12 sm:mb-20 sm:ml-[-5vw]
                md:w-[35vw] md:ml-[0vw] md:mt-12 md:mb-20
                lg:w-[35vw] lg:ml-[0vw] lg:mt-12 lg:mb-20
                xl:w-[35vw] xl:ml-[0vw] xl:mt-12 xl:mb-20
                note:w-[35vw] note:ml-[-7vw] note:mt-12 note:mb-20
                tablet:w-[80vw] tablet:ml-[-30vw] tablet:mt-12 tablet:mb-20
                mobile-lg:w-[80vw] mobile-lg:ml-[-25vw] mobile-lg:mt-12 mobile-lg:mb-20
                mobile-md:w-[80vw] mobile-md:ml-[-25vw] mobile-md:mt-12 mobile-md:mb-20 mobile-md:h-auto size-max-context
                `}><Link to={'/steps/'} className="nav-link">COMEÇAR AGORA</Link>


              <div className="bg-white h-12 w-12 mb-1 ml-5 rounded-full flex justify-center items-center"> 
              <Link to={'/steps/'}><PlayArrowIcon style={{ color: "#123456" }}/> </Link>
              </div>

              </div>

              </div>

            </div>
            <div className='w-full'>
            {Rodape()}
            </div>
      </div>
    </div>
    )
}

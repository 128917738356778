export class Privacidade {
  [key: string]: any;
  compartilhamento: boolean;
  improprio: boolean;
  desenvolvimento: boolean;
  configuracao: boolean;
  digital: boolean;
  familia: boolean;
  adicionais: string[];

  constructor(){
    this.compartilhamento= true;
    this.improprio= true;
    this.desenvolvimento= false;
    this.configuracao= true;
    this.digital= true;
    this.familia= true;
    this.adicionais=[];
  }
}

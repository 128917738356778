import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step6Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step6: React.FC<Step6Props> = ({ dados, setDados }) => {
  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.internet;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.internet.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.internet.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: {
        ...dados.pergunta,
        internet: [...dados.pergunta.internet, ""],
      },
    });
  };

  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };

  /*

adicionais: string[];*/
  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
      <div className="flex mt-10">
        <div>
          <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
        </div>

        <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Dentro e fora da internet
        </span>
      </div>

      <div id="cards" className="m-0 p-0">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="diadia"
          titulo="Falar sobre tecnologia e uso da internet em uma base regular."
          porque={
            <p>
              A tecnologia pode ser um desafio para discutir. Os pais às vezes
              se sentem culpados ou não sabem sobre o assunto e as crianças
              podem se sentir frustradas quando os limites são colocados. Temas
              como violência, golpes, racismo ou conteúdo sexual na internet
              podem ser particularmente difíceis de se falar. Ajude seus filhos
              a se sentirem à vontade para falar com você sobre o que veem
              online. A comunicação aberta e livre de julgamentos é a melhor
              maneira de tornar o uso das tecnologias um tópico acessível.
            </p>
          }
          como={
            <div>
              <p>
                Depois de terminar de definir seus objetivos, pergunte aos seus
                filhos como gostariam de conversar sobre telas e a internet.
                Algumas ideias incluem:
              </p>
              <br></br>
              <ul className="marcadores">
                <li>
                  <p>
                    No jantar, pergunte qual foi a coisa mais inspiradora,
                    animada, assustadora ou frustrante que viram online naquele
                    dia.
                  </p>
                </li>
                <li>
                  <p>
                    Depois da escola, pergunte aos seus filhos que tipo de
                    ferramentas tecnológicas usaram na escola naquele dia e o
                    que pensaram sobre eles.
                  </p>
                </li>
                <br></br>
                <li>
                  <p>
                    Incentive todos na família a rastrear seu tempo e uso dos
                    dispositivos e ter ideias para obter mais equilíbrio,
                    conseguir fazer o que realmente querem e perder menos a
                    noção do tempo enquanto usam as telas.
                  </p>
                </li>
                <br></br>
                <li>
                  <p>
                    Verifique como criar sua família nos emails do{" "}
                    <u>
                      <a
                        href="https://families.google/intl/pt-BR/families/"
                        title="https://families.google/intl/pt-BR/families/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        google
                      </a>{" "}
                      e da{" "}
                      <a
                        href="https://www.microsoft.com/pt-br/microsoft-365/family-safety"
                        title="https://www.microsoft.com/pt-br/microsoft-365/family-safety"
                        target="_blank"
                        rel="noreferrer"
                      >
                        microsoft
                      </a>{" "}
                      no nosso{" "}
                      <a
                        href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                        title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                        target="_blank"
                        rel="noreferrer"
                      >
                        guia de controle parental
                      </a>
                      .
                    </u>
                  </p>
                </li>
              </ul>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="brigar"
          titulo="Ouvir uns aos outros sem brigar impulsivamente."
          porque={
            <div>
              <p>
                Queremos que as crianças e os adolescentes saibam que podem vir
                até nós quando estiverem sendo desrespeitados, maltratados ou
                intimidados na internet. Também queremos que se sintam seguros
                em contar se receberem mensagens ou fotos, além de pedidos, que
                os deixem desconfortáveis online. Os pais podem querer reagir e
                dizer o que fazer mas construir confiança significa dedicar um
                tempo para ouvir com atenção, entender e trabalhar em conjunto
                para encontrar soluções.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Quando seu filho estiver te contando sobre o que tem acontecido
                com ele na internet, mesmo que o que esteja sendo contado a você
                seja estressante, respire fundo e considere como deve ser para
                ele essa situação e faça perguntas.
              </p>
              <br></br>
              <p>
                Ao fazer perguntas, você entende melhor como orientar e guiar a
                situação para interromper o problema e tomar soluções. Procure
                pelo seu problema na biblioteca de soluções do nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                  .
                </u>
              </p>
              <br></br>
              <p>
                Atente-se para não punir seu filho por compartilhar com você.
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="consciente"
          titulo="Tornar-se mais consciente de como a tecnologia e nossas emoções se conectam."
          porque={
            <div>
              <p>
                Cada vez mais estamos{" "}
                <u>
                  <a
                    href="https://g1.globo.com/jornal-da-globo/noticia/2023/05/27/nao-tenho-paciencia-43percent-da-populacao-brasileira-vive-em-ritmo-muito-acelerado-diz-datafolha.ghtml"
                    title="https://g1.globo.com/jornal-da-globo/noticia/2023/05/27/nao-tenho-paciencia-43percent-da-populacao-brasileira-vive-em-ritmo-muito-acelerado-diz-datafolha.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    nos tornando impacientes
                  </a>
                </u>{" "}
                e evitamos o tédio usando tecnologias, por exemplo.
              </p>
              <p>
                <u>
                  <a
                    href="https://brasil.elpais.com/brasil/2019/03/23/actualidad/1553363424_494890.html"
                    title="https://brasil.elpais.com/brasil/2019/03/23/actualidad/1553363424_494890.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adolescentes podem se distrair de seus sentimentos negativos
                    correndo por mais tempo usando as tecnologias
                  </a>
                </u>
                .
              </p>
              <p>
                Perceba e converse com seus filhos quando o uso de internet pode
                estar prejudicando (ou ajudando) suas emoções.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.cnnbrasil.com.br/saude/dar-uma-tela-ao-seu-filho-pode-atrapalhar-a-regulacao-emocional-diz-estudo/"
                    title="https://www.cnnbrasil.com.br/saude/dar-uma-tela-ao-seu-filho-pode-atrapalhar-a-regulacao-emocional-diz-estudo/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Em vez de distração, aproveite quando houver desregulação
                    emocional como oportunidades para ensinar as crianças a
                    identificar e responder às emoções de maneiras úteis.
                  </a>
                </u>{" "}
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="reconhecer"
          titulo="Aprender a reconhecer melhor os anúncios na internet."
          porque={
            <div>
              <p>
                A publicidade está em quase toda parte na internet – é como
                muitos sites ou aplicativos gratuitos ganham dinheiro.
              </p>
              <p>
                Esteja ciente de que os anúncios podem ser direcionados e/ou
                manipuladores. Eles podem aparecer de maneiras ou lugares sutis,
                e principalmente,{" "}
                <u>
                  <a
                    href="https://criancaeconsumo.org.br/noticias/7-dicas-internet-segura-para-criancas/#:~:text=A%20publicidade%20direcionada%20%C3%A0s%20crian%C3%A7as,e%20do%20Adolescente%20(Conanda)."
                    title="https://criancaeconsumo.org.br/noticias/7-dicas-internet-segura-para-criancas/#:~:text=A%20publicidade%20direcionada%20%C3%A0s%20crian%C3%A7as,e%20do%20Adolescente%20(Conanda)."
                    target="_blank"
                    rel="noreferrer"
                  >
                    mesmo a publicidade infantil sendo ilegal na internet também
                  </a>
                </u>
                .
              </p>
              <p>
                As crianças precisam de ajuda para identificar e resistir a
                mensagens publicitárias, especialmente para situações como
                álcool e outras drogas, além de dietas, informações falsas e
                desafios com brincadeiras perigosas.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Fale sobre os anúncios que você vê e explique caso discorde
                deles.
              </p>
              <p>
                Ajude seu filho a ter um olhar crítico quando se trata de
                empresas de tecnologia rastreando seu comportamento, colocando
                anúncios personalizados. Caso não saiba explicar, consulte nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                  .
                </u>
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="consumir"
          titulo="Falar sobre as maneiras como o conteúdo que consumimos pode afetar nossa saúde mental."
          porque={
            <div>
              <p>
                ​​As plataformas de redes sociais e a forma como as usamos e
                consumimos podem ter efeitos positivos e{" "}
                <u>
                  <a
                    href="https://www.bbc.com/portuguese/articles/c9x9dzl5p4po"
                    title="https://www.bbc.com/portuguese/articles/c9x9dzl5p4po"
                    target="_blank"
                    rel="noreferrer"
                  >
                    negativos na nossa saúde mental.
                  </a>
                </u>
              </p>
              <p>
                É importante falar sobre formas saudáveis, dar o exemplo e
                conhecer os sinais em que o uso inadequado pode estar
                contribuindo para sentimentos de estresse, ansiedade e tristeza.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Dê o exemplo para o uso das tecnologias e da internet de forma
                positiva, saudável e responsável. Mantenha a comunicação aberta
                para que seu filho possa pedir ajuda caso esteja precisando.
              </p>
              <p>
                Além disso, faça com ele as configurações de controle parental,
                respeite os limites de idade mínima, acompanhe as redes sociais
                do seu filho e esteja pronto para saber o que fazer caso ele
                tenha experiências negativas. Todas essas orientações, você pode
                encontrar no nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                  >
                    guia de controle parental
                  </a>
                  .
                </u>
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="prejudicial"
          titulo="Saber quando e como o uso é inadequado ou prejudicial."
          porque={
            <div>
              <p>
                A maioria das plataformas tem requisito de idade mínima, mas
                tente verificar o tipo de conteúdo nos sites para ter certeza de
                que são apropriados para a idade do seu filho.
              </p>
              <p>
                Algumas plataformas que usam filtros de contorno de rosto e
                corpo{" "}
                <u>
                  <a
                    href="https://www.bbc.com/portuguese/vert-fut-47625592"
                    title="https://www.bbc.com/portuguese/vert-fut-47625592"
                    target="_blank"
                    rel="noreferrer"
                  >
                    podem afetar a autoestima
                  </a>
                </u>{" "}
                de adolescentes em risco.
              </p>
              <p>
                Esses tipos de plataformas geralmente se concentram fortemente
                na beleza e promovem dietas e exercícios extremos.{" "}
                <u>
                  <a
                    href="https://exame.com/saude/efeito-zoom-faz-aumentar-as-operacoes-plasticas-no-rosto-no-pais/"
                    title="https://exame.com/saude/efeito-zoom-faz-aumentar-as-operacoes-plasticas-no-rosto-no-pais/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Isso causa preocupações
                  </a>
                </u>{" "}
                e pode ter efeitos prejudiciais sobre como os adolescentes veem
                seus corpos.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Se o objetivo do seu filho adolescente é a conexão, experimente
                o bate-papo por vídeo ou mensagens de texto.
              </p>
              <p>
                Pergunte ao seu filho se já tentou fazer uma pausa no próprio
                uso de redes sociais e diga que outras pessoas também recorrem a
                ela{" "}
                <u>
                  <a
                    href="https://revistaquem.globo.com/noticias/noticia/2023/06/chris-evans-anuncia-pausa-em-redes-sociais-menos-tempo-de-tela.ghtml"
                    title="https://revistaquem.globo.com/noticias/noticia/2023/06/chris-evans-anuncia-pausa-em-redes-sociais-menos-tempo-de-tela.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    quando querem descansar
                  </a>
                </u>{" "}
                ou{" "}
                <u>
                  <a
                    href="https://www.cnnbrasil.com.br/entretenimento/tom-holland-anuncia-que-vai-se-afastar-das-redes-sociais-para-cuidar-da-saude-mental/"
                    title="https://www.cnnbrasil.com.br/entretenimento/tom-holland-anuncia-que-vai-se-afastar-das-redes-sociais-para-cuidar-da-saude-mental/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    se cuidar
                  </a>
                </u>
                . Quem costuma dar essa pausa diz que isso ajuda a entender
                melhor por que as usam e como elas se sentem.
              </p>
              <p>
                Você pode ver outras sugestões{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CclSUr-ub3w/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    title="https://www.instagram.com/p/CclSUr-ub3w/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                  .
                </u>
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="respeitar"
          titulo="Respeitar os outros, tanto online quanto pessoalmente."
          porque={
            <div>
              <p>
                A comunicação na internet tem sido uma parte básica da vida
                social da maioria das crianças e{" "}
                <u>
                  <a
                    href="https://dol.com.br/noticias/brasil/614293/crianca-sofre-bullying-de-amigas-em-grupo-de-whatsapp-e-mae-denuncia?d=1"
                    title="https://dol.com.br/noticias/brasil/614293/crianca-sofre-bullying-de-amigas-em-grupo-de-whatsapp-e-mae-denuncia?d=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    elas podem ser muito agressivas
                  </a>
                </u>
                . Ao compartilhar informações online, pode ser mais fácil
                expressar raiva ou crueldade em relação a outras pessoas, pois
                elas não estão fisicamente. Essa situação tem muitos nomes, como
                cyberbullying e/ou assédio online, por exemplo.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Ensine seus filhos a respirar fundo e reconhecer quando estão
                enviando mensagens de texto ou postando emoções fortes. Isso
                lhes dará a chance de repensar essa decisão.
              </p>
              <p>
                Ensine a eles: "Antes de dizer algo em um texto ou online, pare
                um momento para pensar: eu diria isso para alguém na minha
                frente?"
              </p>
              <p>
                Em suas conversas o uso da tecnologia e da internet de vocês,
                fale sobre comentários, tópicos de texto ou conversas que você
                viu online. Pergunte aos seus filhos como acham que a situação
                poderia ser resolvida de forma menos danosa ou agressiva.
                Oriente também quando este tipo de situação acontecer com eles.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="cyberbullying"
          titulo="Ter um plano de como lidar com o cyberbullying."
          porque={
            <div>
              <p>
                Existem diferentes tipos de conflito online e já temos
                <u>
                  <a
                    href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13185.htm"
                    title="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13185.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    legislação para quando se trata de crianças e adolescentes
                  </a>
                </u>
                . Seu filho pode ser o alvo de um cyberbullying, pode ser a
                pessoa que está fazendo o cyberbullying ou pode testemunhar
                outras crianças sendo intimidadas. É importante falar sobre o
                que fazer em cada uma dessas situações.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Se seu filho for alvo de comportamento cruel online, mostre seu
                apoio e ouça suas experiências. Esteja pronto para protegê-lo e
                tomar ações preservando as evidências antes de serem removidas,
                excluídas ou editadas. Para saber o passo a passo do que os pais
                podem e devem fazer, consulte nosso{" "}
                <u>
                  <a
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
              <p>
                Se seu filho testemunhar crueldade online, ajude-o a encontrar
                maneiras de apoiar a pessoa que está sendo alvo. Defender essa
                criança ou redirecionar a situação para um tópico diferente são
                opções a serem consideradas.{" "}
                <u>
                  <a
                    href="https://www.bemparana.com.br/tecnologia/dona-de-grupo-de-whatsapp-e-condenada-por-permitir-bullying/"
                    title="https://www.bemparana.com.br/tecnologia/dona-de-grupo-de-whatsapp-e-condenada-por-permitir-bullying/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    As pessoas responsáveis já tem sido condenadas
                  </a>
                </u>
                .
              </p>
              <p>
                Se você precisar de ajuda imediata e especializada por algum
                problema na internet, você pode procurar a central de ajuda da
                Safernet{" "}
                <u>
                  <a
                    href="https://new.safernet.org.br/helpline"
                    title="https://new.safernet.org.br/helpline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />

        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="internet"
          valor="educado"
          titulo="Ser educado e atencioso com os outros online e pessoalmente."
          porque={
            <div>
              <p>
                Ser educado e atencioso com as pessoas ao seu redor deve
                continuar sendo uma prioridade, mesmo ao usar uma tela.
              </p>
              <p>
                O uso da internet pode te consumir tanto que às vezes{" "}
                <u>
                  <a
                    href="https://www.instagram.com/p/CZXO-fElkvG/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    title="https://www.instagram.com/p/CZXO-fElkvG/?igshid=YzcxN2Q2NzY0OA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    nem percebemos o que está acontecendo ao nosso redor
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Definir lugares e horários ou momentos livres dos nossos
                dispositivos pode reduzir a frequência com que eles atrapalham
                nossas interações com as pessoas ao nosso redor.
              </p>
              <p>
                Para ajudar seus filhos a fazer a transição para deixar seus
                dispositivos, deixe seu filho salvar seu progresso ou dizer “até
                depois” online antes de desligá-los.
              </p>
              <p>
                As crianças podem criar uma palavra de código engraçada para
                desviar a atenção dos pais de um dispositivo. E caso queira
                ajuda nessas atividades, consulte nosso{" "}
                <u>
                  <a
                    data-testid="link-with-safety"
                    href="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    title="https://hotmart.com/pt-br/marketplace/produtos/guia-de-controle-parental-2-0/D81424545F?preview=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.internet.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="internet"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};

export default Step6;

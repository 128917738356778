export class Tempo {
  [key: string]: any;
  distracao: boolean;
  sono: boolean;
  dirigir: boolean;
  ambiente: boolean;
  adiar: boolean;
  escola: boolean;
  dia: boolean;
  plano: boolean;
  tela: boolean;
  evitar: boolean;
  semana: boolean;
  silenciar: boolean;
  impedir: boolean;
  dormir: boolean;
  intencional: boolean;
  priorizar: boolean;
  orientar: boolean;
  experimento: boolean;
  cinema: boolean;
  vizualizar: boolean;
  assistir: boolean;
  dispositivos: boolean;
  carregar: boolean;
  naousar: boolean;
  horario: boolean;
  acordar: boolean;
  conteudo: boolean;
  consumir: boolean;
  proibir: boolean;
  adicionais: string[];

  constructor() {
    this.distracao = true;
    this.sono = true;
    this.dirigir = false;
    this.ambiente = false;
    this.adiar = false;
    this.escola = false;
    this.dia = false;
    this.plano = false;
    this.tela = true;
    this.evitar = false;
    this.semana = true;
    this.silenciar = false;
    this.impedir = true;
    this.dormir = true;
    this.intencional = true;
    this.priorizar = false;
    this.orientar = false;
    this.experimento = false;
    this.cinema = true;
    this.vizualizar = false;
    this.assistir = false;
    this.dispositivos = true;
    this.carregar = true;
    this.naousar = true;
    this.horario = true;
    this.acordar = false;
    this.conteudo = false;
    this.consumir = true;
    this.proibir = true;
    this.adicionais = [];
  }
}

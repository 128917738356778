import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step9Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step9: React.FC<Step9Props> = ({ dados, setDados }) => {
  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };
  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.conversar;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.conversar.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.conversar.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: {
        ...dados.pergunta,
        conversar: [...dados.pergunta.conversar, ""],
      },
    });
  };
  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
    <div className="flex mt-10">
      <div>
        <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
      </div>

      <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Notas e exceções para conversar
        </span>
      </div>

      <div id="cards" className="m-0 p-0">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="frequencia"
          titulo="Falar com frequência sobre o nosso tempo de tela."
          porque={
            <div>
              <p>
                O tempo de tela pode oferecer às crianças oportunidades de
                aprender e desenvolver novas habilidades com o toque de um
                botão, mas, como tudo, muito tempo de tela pode ter um efeito
                negativo no bem-estar delas.
              </p>
              <p>
                À medida que as crianças ficam mais velhas e mais independentes
                on-line, encontrar o equilíbrio certo para sua família pode ser
                um desafio, mas a chave é pensar nisso logo no início e
                estabelecer alguns limites claros em torno do uso das
                tecnologias e do acesso a internet.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                A gente, enquanto{" "}
                <u><a
                  href="https://www.instagram.com/pro.teka/"
                  title="https://www.instagram.com/pro.teka/"
                  target="_blank"
                  rel="noreferrer"
                >
                  proteka
                </a></u>{" "}
                , acredita que o tempo de tela pode ser uma ótima maneira para
                crianças e adolescentes, que já usam a internet, aprenderem,
                explorarem e criarem engajando com novos conhecimentos, testando
                suas habilidades de resolução de problemas com jogos e
                produzindo novas obras de arte (com vídeos, música, culinária,
                pinturas - por exemplo).
              </p>
              <p>
                Existe uma grande diferença entre a rolagem infinita e atividade
                online produtiva, por isso é importante que os pais realmente
                entendam o que seu filho está fazendo na internet. Fale com eles
                sobre suas atividades na internet. Eles estão usando seu tempo
                de tela para aprender e construir experiências ou eles estão
                passando horas e horas assistindo jogadores no YouTube? Para
                nós, existe diferença.
              </p>
              <p>
                Mesmo que a gente goste de ver crianças e adolescentes fazendo
                as coisas que gostam - e pode ser assistindo um filme ou o
                TikToker favorito, nós também podemos os incentivar a explorar
                alguns aplicativos, vídeos, jogos ou falar com pessoas que eles
                gostam que pode ser mais engrandecedor. Se precisar de ajuda
                para aprender sobre o tema, proteger seu filho na internet e
                
                lidar com isso, consulte nosso{" "}
                <u><a
                  href="https://novoguiadecontroleparental.club.hotmart.com/"
                  title="https://novoguiadecontroleparental.club.hotmart.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  guia de controle parental
                </a></u>{" "}  .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="favorito"
          titulo="Entender quais são os sites e jogos favoritos do seu filho."
          porque={
            <div>
              <p>
                Jogos online são os jogos eletrônicos que podem ser jogados pela
                internet, conectando vários jogadores ao mesmo tempo. O que pode
                ser administrado, além dos conteúdos dos jogos, é a quantidade
                de interação com outros jogadores, a quantidade deles e com
                quantas pessoas seus filhos interagem que são desconhecidos
                reais.
              </p>
              <p>
                Os pais devem estar cientes constantemente porque mesmo que
                jogos possam oferecer diversão, prazer, trabalho em equipe,
                colaboração, aventura imaginativa, é preciso que os pais possam
                incentivar hábitos seguros e saudáveis e serem capazes de
                proteger seus filhos enquanto jogam porque{" "}
                <u>
                  <a
                    href="https://www.metropoles.com/distrito-federal/pais-denunciam-pedofilos-que-se-infiltram-em-jogos-on-line-para-aliciar-criancas-do-df"
                    title="https://www.metropoles.com/distrito-federal/pais-denunciam-pedofilos-que-se-infiltram-em-jogos-on-line-para-aliciar-criancas-do-df"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aliciadores que tem interesse em crianças e adolescentes
                    podem usar os jogos como caminho até seus filhos
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Existem realmente ótimas habilidades que você adquirir e treinar
                jogando, uma delas é como você socializa com essas pessoas de
                todo o mundo, como você constrói times, como você fortalece
                alianças enquanto também pode realmente trabalhar a sua
                criatividade pensando em soluções e pode ser bastante divertido
                se feito com moderação.
              </p>
              <p>
                Alguns sinais que jogar online pode estar saindo de controle é
                se seu filho estiver pensando nisso absolutamente o tempo
                inteiro: é como se fosse a única coisa sobre a qual ele quer
                falar. Jogar se torna a principal coisa que ele deseja fazer
                quando acorda de manhã, antes de ir para a cama a noite e,
                especialmente, se estiver negligenciando questões como trabalhos
                escolares, a sua vida social, sua higiene, além de estar
                esquecendo de comer e não dormindo o suficiente.
              </p>

              <p>
                Se o jogo online é a principal coisa da vida do seu filho, então
                realmente há algo com que preocupar.
              </p>
              <p>
                <u>
                  <a
                    href="https://www.instagram.com/tv/CPFzPuXr9x5/"
                    title="h"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                tem uma palestra sobre o uso problemático de jogos eletrônicos.
              </p>
              <p>
                Se o caso for de limitar por configurações, criar um ambiente
                seguro para seu filho, acesse nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="h"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="atencao"
          titulo="Existem certos dias ou semanas em que podemos fazer uma pausa na tecnologia e dar ainda mais atenção aos outros, amigos e família?"
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8905397/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                a gente pode compreender como um aumento na quantidade de tempo
                de tela e uma idade precoce de início da visualização têm
                efeitos negativos no desenvolvimento da linguagem. Inclusive
                você pode fazer um teste se seu filho está com atraso na fala{" "}
                <u>
                  <a
                    href="https://falabebe.com/quiz/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
              <p>
                Quando a gente determina um dia da semana ou períodos de tempo
                como na semana de carnaval, páscoa ou natal que podemos receber
                a família ou viajar ou durante a semana de provas, a gente tira
                uma potencial distração e pode focar no que nos é mais
                importante, como focar em algum livro, esporte ou ficar com a
                família que vem nos visitar.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Converse com a família quando as pessoas, amigos ou compromissos
                podem ser mais importantes que as telas e determinem que durante
                o período não usarão. Muitas famílias ainda podem querer
                registrar os momentos e é possível usar{" "}
                <u>
                  <a
                    href="https://a.co/d/gHHOWru"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    câmeras infantis
                  </a>
                </u>{" "}
                que podem até emprestar para as crianças registrarem também.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="carro"
          titulo="Quais serão as nossas regras para uso de telas quando estivermos no carro (Crie regras para viagens curtas e longas)?"
          porque={
            <div>
              <p>
                Quanto menor a criança é, menos noção do tempo ela tem e o que
                normalmente tem acontecido é submetermos as crianças as
                distrações passivas das telas.
              </p>
              <p>
                Cada vez que precisamos fazer algo que leva tempo, enfiamos uma
                tela nas crianças para fazermos o que queremos/precisamos e
                quando a criança sai da tela, ela apenas vê pronto. Um passeio
                de carro é uma excelente oportunidade para não cansarmos das
                perguntas de “estamos chegando” e exercitar essa noção.
              </p>
              <p>
                O problema é que como consequência{" "}
                <u>
                  <a
                    href="https://www.psicoedu.com.br/2017/05/por-que-as-criancas-estao-impacientes-entediadas-frustradas.html"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    as crianças estão mais impacientes, entediadas e facilmente
                    frustradas
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Use momentos como fazer caminhos de carro, dentro do elevador e
                os preparos das refeições para conversar com a criança durante a
                atividade para que ela veja os processos. O antes, o durante e o
                depois. Cada momento desses conta no aprendizado da noção do
                tempo.
              </p>
              <p>
                Para mais sobre noção do tempo, telas e crianças, assista o
                módulo Tempo de Tela, no nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="publico"
          titulo="Definir quais serão as nossas regras para uso de telas na escola, na casa de amigos ou em outros locais públicos (Crie regras para cada um desses cenários). "
          porque={
            <div>
              <p>
                Como vimos anteriormente,{" "}
                <u>
                  <a
                    href="http://theatlantic.com/ideas/archive/2023/06/ban-smartphones-phone-free-schools-social-media/674304/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    os celulares podem atrapalhar o aprendizado
                  </a>
                </u>
                ,{" "}
                <u>
                  <a
                    href="https://www.estadao.com.br/emais/comportamento/phubbing-e-nomofobia-os-comportamentos-do-mundo-digital/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    fazer as pessoas se sentirem esnobadas
                  </a>
                </u>{" "}
                e pode ser{" "}
                <u>
                  <a
                    href="https://gauchazh.clicrbs.com.br/comportamento/noticia/2015/12/perigo-cerca-os-zumbis-digitais-pedestres-distraidos-pelo-celular-4938838.html"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    perigoso para quem está andando distraído
                  </a>
                </u>
                , por exemplo.
              </p>
              <p>
                Definir formas de usar os telefones nessas situações, facilita
                para a criança iluminar a melhor forma de lidar e agir.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Fazer um acordo da família definindo regras de utilização
                precisas nos ambientes e limitando as oportunidades de acesso,
                como por exemplo não levando o telefone para a escola - ou um
                que não tenha acesso a internet para que consiga receber
                ligações dos pais, por exemplo. 
              </p>
              <p>
                Para que funcione a longo prazo, é preciso reforçar sempre que
                necessário e dando exemplo por um ambiente favorável a seguir as
                regras definidas.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="cotidiana"
          titulo="Ter conversas cotidianas sobre informações que compartilhamos ou não na internet. "
          atencao={
            <div>
              <p>
                <strong>Dê atenção aos seguintes tópicos:</strong>
                <ul className="marcadores">
                  <li>
                    Teremos cuidado ao compartilhar nome, telefone, endereço,
                    escola onde estudamos, onde o pai e/ou mãe trabalha ou
                    qualquer informação importante da família com pessoas na
                    internet. A fim de evitar{" "}
                    <u>
                      <a
                        href="https://www.osul.com.br/alerta-aos-pais-homem-mostra-como-e-facil-aliciar-criancas-na-internet/"
                        title=""
                        target="_blank"
                        rel="noreferrer"
                      >
                        aliciadores
                      </a>
                    </u>
                    , por exemplo.
                  </li>
                  <li>
                    Não compartilhamos fotos ou vídeos íntimas ou privadas,
                    mesmo que sejam de outra pessoa. Afinal é{" "}
                    <u>
                      <a
                        href="https://www.jusbrasil.com.br/topicos/204961035/artigo-218c-do-decreto-lei-n-2848-de-07-de-dezembro-de-1940"
                        title="https://www.jusbrasil.com.br/topicos/204961035/artigo-218c-do-decreto-lei-n-2848-de-07-de-dezembro-de-1940"
                        target="_blank"
                        rel="noreferrer"
                      >
                        crime
                      </a>
                    </u>
                    .
                  </li>
                  <li>
                    Contaremos para os nossos pais caso vejamos mensagens,
                    vídeos, jogos ou filmes que nos deixam chateados,
                    desconfortáveis ou com medo.
                  </li>
                  <li>
                    Não cometeremos bullying contra ninguém e contaremos aos
                    nossos pais e professores caso sejamos vítimas - mesmo que
                    pela internet.
                  </li>
                  <li>
                    Daremos atenção à pessoa que estiver pessoalmente com a
                    gente ao invés de dar mais atenção ao telefone.
                  </li>
                  <li>
                    Sempre contaremos aos nossos pais quando adicionarmos alguma
                    pessoa que não conhecemos pessoalmente nas redes sociais ou
                    nos jogos online.
                  </li>
                  <li>
                    Verificar antes de repassar uma informação que pode ser
                    falsa,{" "}
                    <u>
                      <a
                        href="https://canalcienciascriminais.com.br/homem-espancado-falso-pega-ladrao/"
                        title="https"
                        target="_blank"
                        rel="noreferrer"
                      >
                        a fim de não expor terceiros a riscos
                      </a>
                    </u>
                    .
                  </li>
                </ul>
              </p>
              <p>
                Inclua outros tópicos de acordo com o que tem acompanhado sobre
                o uso da sua família.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="recreativo"
          titulo="Ter conversas sobre quando o uso de telas for recreativo."
          atencao={
            <div>
              <p>
                <strong>
                  Dê atenção aos seguintes tópicos que forem adequados para sua
                  família:
                </strong>
              </p>
              <ul className="marcadores">
                <li>
                  Não vamos jogar videogames que sejam contra as regras da nossa
                  família, tanto em casa quanto na casa de outra pessoa.
                </li>
                <li>
                  Não baixaremos aplicativos, filmes, jogos sem permissão e
                  perguntar a um adulto se eles são apropriados para minha idade
                  ou pedir autorização caso não sejam.
                </li>
                <li>
                  Evitaremos ou não entraremos em novos sites sem pedir
                  permissão.
                </li>
                <li>
                  Usaremos as tecnologias para ser criativos - aprender a
                  cozinhar, tocar, cantar…
                </li>
                <li>
                  Não iremos perder muito tempo assistindo a programas ou
                  aplicativos em ritmo acelerado com muitos sinos e assobios.
                </li>
                <li>
                  Usaremos as telas para nos conectar com os outros que amamos
                  sempre que possível.
                </li>
              </ul>
              <p>
                Inclua outros tópicos de acordo com o que tem acompanhado sobre
                o uso da sua família.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="inadequado"
          titulo="Conversar sobre conteúdo inadequado e formas de lidar com isso."
          porque={
            <div>
              <p>
                À medida que as crianças se tornam mais independentes na
                internet, é possível que encontrem coisas que são inadequadas
                para sua idade ou fase do desenvolvimento. 
              </p>
              <p>
                As atividades na internet que podem aumentar a possibilidade e a
                probabilidade de seu filho ver conteúdo impróprio?
              </p>
              <ul className="marcadores">
                <li>
                  Usando redes sociais antes de atingir a idade mínima
                  recomendada.
                </li>
                <li>
                  Jogando jogos e usando aplicativos que não são adequados à
                  idade.
                </li>
                <li>
                  Assistindo a transmissões ao vivo que podem mostrar conteúdo
                  inadequado ou participar delas e, inconscientemente, serem
                  exploradas.
                </li>
              </ul>
              <p>
                É importante lembrar que conteúdo inapropriado não é apenas
                conteúdos violentos e/ou pornografia. Podem ser mais e
                travestidos de:
              </p>
              <ul className="marcadores">
                <li>Material pornográfico.</li>
                <li>Conteúdo contendo palavrões.</li>
                <li>
                  Sites que incentivam o vandalismo, o crime, o terrorismo, o
                  racismo, os transtornos alimentares e até o suicídio.
                </li>
                <li>
                  Imagens, vídeos ou jogos que mostram imagens de violência ou
                  crueldade para com outras pessoas ou animais.
                </li>
                <li>Sites de jogos de azar.</li>
                <li>
                  Salas de bate-papo não moderadas - onde não há ninguém
                  supervisionando a conversa e impedindo comentários
                  inadequados.
                </li>
                <li>
                  Vídeos, anúncios e propagandas que estimulam o consumismo.
                </li>
              </ul>
              <p>
                O que você acha que é material impróprio para seu filho
                provavelmente difere da visão de seu filho ou de outros pais.
                Dependerá também da idade e do nível de maturidade do seu filho.
              </p>
              <p>
                Conteúdo impróprio inclui informações ou imagens que incomodam
                seu filho, material direcionado a adultos, informações
                imprecisas ou informações que possam levar ou levar seu filho a
                um comportamento ilegal ou perigoso.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Usar filtros para bloquear conteúdo explícito e ter conversas
                francas são apenas algumas maneiras de equipá-los com
                ferramentas para lidar com isso.
              </p>
              <p>
                Use nossos guias para configurar os controles corretos e fazer
                as configurações nas redes, aplicativos e sites que as crianças
                usam.{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saiba mais aqui
                  </a>
                </u>
                . 
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="noticia"
          titulo="Conversar sobre notícias falsas e desinformação."
          porque={
            <div>
              <p>
                O nosso acesso a internet e as redes sociais mudaram muito a
                forma como vemos o mundo. 
              </p>
              <p>
                Com tantas fontes de informação, pode ser difícil acompanhar o
                que é real e falso online.
              </p>
              <p>
                Ter facilidade no acesso{" "}
                <u>
                  <a
                    href="https://g1.globo.com/educacao/noticia/2021/05/06/67percent-dos-estudantes-de-15-anos-do-brasil-nao-sabem-diferenciar-fatos-de-opinioes-afirma-relatorio-da-ocde.ghtml"
                    title="https://g1.globo.com/educacao/noticia/2021/05/06/67percent-dos-estudantes-de-15-anos-do-brasil-nao-sabem-diferenciar-fatos-de-opinioes-afirma-relatorio-da-ocde.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    não nos torna expert em separar o que é verdadeiro ou não
                  </a>
                </u>{" "}
                e quanto menor o seu filho é, mais ele precisa da sua ajuda.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                À medida que as crianças se tornam mais ativas no mundo digital,
                é importante ajudá-las a desenvolver a alfabetização digital e o
                pensamento crítico para identificar a diferença entre fato e
                ficção na internet.
              </p>
              <p>
                Está cada vez mais difícil detectar informações falsas na
                internet e até mesmo{" "}
                <u>
                  <a
                    href="https://noticiasdatv.uol.com.br/noticia/televisao/cnn-brasil-perde-acao-contra-medico-cotado-para-ser-ministro-de-bolsonaro-51594"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    organizações de notícias bem estabelecidas se encontram
                    reportando  histórias baseadas em informações falsas
                  </a>
                </u>
                .
              </p>
              <p>
                Com tanta informação vinda de uma ampla gama de fontes pode ser
                difícil saber quais são confiáveis.
              </p>
              <p>
                Alfabetização digital crítica significa ter cuidado com o que
                você lê, compartilha e escreve na internet.
              </p>
              <p>
                Há várias maneiras diferentes sugeridas para melhorar a
                alfabetização digital.
              </p>
              <p>
                 Aqui vai uma forma simples para instruir seus filhos e que
                funcionará na maioria das vezes:
              </p>
              <p>
                <strong>Leia, verifique e espere</strong>.
              </p>
              <ul className="marcadores">
                <li>
                  Leia: As manchetes costumam ser enganosas para chamar sua
                  atenção. Então certifiquem de que vocês leram tudo.
                </li>
                <li>
                  Verifique: qualquer um pode ser apresentado como especialista
                  em "batata" hoje em dia, mas não é difícil de verificar na
                  internet e ver se eles são realmente quem dizem ser.
                </li>
                <li>
                  Espere: se algo aparecer suspeito no que leu, não compartilhe.
                </li>
              </ul>
              <p>
                Quanto mais nova a criança é, mais fácil dela acreditar no que
                vê na internet e é por isso que os pais precisam estar cientes e
                explicar sempre que nem tudo que você vê na internet é real. 
              </p>
              <p>
                <u>
                  <a
                    href="https://www.instagram.com/reel/CmZHlk5g_o2/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                tem um vídeo muito legal que mostra sobre o assunto.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="reputacao"
          titulo="Conversar sobre reputação na internet."
          porque={
            <div>
              <p>
                As informações de alguém, inclusive perfis de redes sociais,
                cada vez mais são levadas em consideração para{" "}
                <u>
                  <a
                    href="https://www12.senado.leg.br/noticias/materias/2019/12/12/redes-sociais-influenciam-voto-de-45-da-populacao-indica-pesquisa-do-datasenado"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    votos
                  </a>
                </u>
                ,{" "}
                <u>
                  <a
                    href="https://g1.globo.com/economia/concursos-e-emprego/noticia/empresas-monitoram-comportamento-nas-redes-sociais-para-contratar-ou-demitir-veja-cuidados.ghtml"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    contratação
                  </a>
                </u>{" "}
                e até{" "}
                <u>
                  <a
                    href="https://www.correiobraziliense.com.br/app/noticia/revista/2019/06/09/interna_revista_correio,761048/conheca-a-historia-de-relacionamentos-a-distancia-que-deram-certo.shtml"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    relacionamentos
                  </a>
                </u>
                . Então fica claro que publicar online pode ter um impacto real
                em nossas vidas offline. 
              </p>
              <p>
                Portanto, ajudar as crianças a entender os efeitos duradouros do
                que elas compartilham e capacitá-las a assumir o controle de
                como sua reputação online é criada é fundamental.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Identidade online é a maneira como escolhemos nos retratar na
                internet e parece até que é uma coisa simples, porém a maneira
                como estamos fora da internet nem sempre é a maneira como nos
                apresentamos nela.
              </p>
              <p>
                A nossa identidade na internet nos permite mostrar versões
                editadas das nossas vidas destacando características que
                consideramos ter maior valor para os outros, então
                compartilhamos algo sobre um feriado em família por exemplo,
                nossa postagem não reflete apenas quem somos e o que  também
                pode ser importante para nós, mas também o que acreditamos que
                os outros esperam da gente ou que vão gostar. 
              </p>
              <p>
                Ter uma identidade na internet pode ser bom porque nos incentiva
                a autoexpressão, nos permite conectar com pessoas que também
                podem gostar das mesmas coisas que a gente, no entanto pode
                criar muita pressão.{" "}
                <u>
                  <a
                    href="https://www.nbcnews.com/health/health-news/social-media-mental-health-anxiety-depression-teens-surgeon-general-rcna85575"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    As crianças podem esconder aspectos de quem são, afinal tem
                    direito a nossa intimidade, ou tentar não corresponder a
                    certas expectativas para se sentir mais aceitas e isso pode
                    ser prejudicial ao seu senso de valor próprio e impactar
                    questões como de imagem corporal e auto estima
                  </a>
                </u>
                . 
              </p>
              <p>
                Os pais podem permitir que seus filhos se expressem  na
                internet, no entanto, é importante oferecer suporte para
                ajudá-los a navegar nisso.
              </p>
              <p>Existem algumas maneiras de fazer isso:</p>
              <ul className="marcadores">
                <li>
                  <u>
                    <a
                      href="https://www.forbes.com/sites/jessicabaron/2018/12/16/parents-who-post-about-their-kids-online-could-be-damaging-their-futures/?sh=1f77d5327b71"
                      title=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vocês podem começar uma conversa sobre suas identidades na
                      internet. O que eles querem associados a eles e o que não
                      gostariam que estivessem disponível para uma infinidade de
                      pessoas
                    </a>
                  </u>
                  .
                </li>
                <li>
                  Façam check-in regulares e avaliem o que isso estar
                  publicamente significa para eles e como eles sentem que isso
                  reflete quem eles realmente são.
                </li>
                <li>
                  É importante também falar sobre como e com quem eles
                  compartilham suas vidas na internet, certificando-se de sempre
                  abordar o que devem ou não compartilhar, conversando também
                  sobre questões relacionadas à segurança e privacidade deles,
                  da família e das pessoas que querem preservar na internet.
                </li>
                <li>
                  Ajude-os a pensar criticamente sobre como as influências na
                  internet impactam na forma como eles percebem a si mesmos como
                  o mundo ao redor deles.
                </li>
                <li>
                  Incentive-os a pensar sobre as intenções por trás do que as
                  pessoas compartilham na internet. 
                </li>
              </ul>
              <p>
                A identidade online do seu filho é uma autoexpressão de quem ele
                é e muitas vezes evolui e muda conforme o tempo passa. Com sua
                ajuda, ele pode fazer isso de maneiras que beneficiam seu
                bem-estar à medida que crescem e se desenvolvem. 
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="identidade"
          titulo="Conversar sobre privacidade e roubo de identidade."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://www.instagram.com/p/CZOoIlTskz7/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    As crianças, assim como os adultos, correm o risco de terem
                    seus dados e sua identidade roubados e mal utilizados
                  </a>
                </u>
                .
              </p>
              <p>
                Os pais são os primeiros responsáveis a proteger a privacidade
                de uma criança porque ela pode não entender o que é seguro nem
                as implicações de ter suas informações expostas ou vazadas.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Crianças e adultos podem, inadvertidamente, revelar muitos dados
                pessoais, deixando-os vulneráveis a terem suas identidades
                roubadas. O problema é que quando se trata de crianças pode não
                ser notado por anos e resultar em chantagem, aliciamento ou
                bullying, contas inexplicáveis, e-mails de organizações não
                reconhecidas assim como cadastros em benefícios do governo ou
                pagamentos de impostos.  
              </p>
              <p>
                É ótimo para seu filho construir relacionamentos e compartilhar
                interesses na internet, mas também é muito importante falar com
                eles caso o que pode acontecer se eles compartilharem além do
                seguro.
              </p>
              <p>
                Conversem sobre privacidade e informações que vocês não deveriam
                compartilhar na internet - ou fora dela. Isso inclui o nome
                completo, endereço, número de telefone, escola, cidade em que
                moram, horários que saem e onde costumam ir com frequência, bem
                como informações de trabalho dos pais.
              </p>
              <p>
                Incentive seus filhos a pensarem sobre quais amigos eles
                compartilham informações específicas e se serão pessoas que vão
                compartilhar a informação com outras pessoas.
              </p>
              <p>
                Se seu filho for encontrar um estranho, seja dentro ou fora da
                internet, ele deve evitar e não revelar nenhuma informação
                pessoal.
              </p>
              <p>
                Ensine-os a permanecer anônimo sempre que possível e usar um
                username (ou @) que não seja seu nome verdadeiro.
              </p>
              <p>
                Verifique as configurações de privacidade com o seu filho em
                cada rede social que ele usar e alinhe com ele sobre o que eles
                vão compartilhar e com quem.
              </p>
              <p>
                Verifique as configurações de privacidade nos dispositivos que
                eles usam e limite os aplicativos que podem ver suas
                localizações em tempo real. 
              </p>
              <p>
                Pesquise o nome do seu filho completo com ele em vários
                mecanismos de pesquisa e veja quais informações e imagens são
                públicas. Retire as que acharem necessário.
              </p>
              <p>
                Se você achar algum comentário inapropriado ou fotos e imagens
                que podem violar a reputação do seu filho, peça para que os
                sites removam - além de tirar as informações direto no{" "}
                <u>
                  <a
                    href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTE0ODMwMDg5MTI4MjQ1?story_media_id=2834538715613259144&amp;igshid=MzRlODBiNWFlZA=="
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    formulário do Google
                  </a>
                </u>
                . 
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="aliciamento"
          titulo="Conversar sobre aliciamento online."
          porque={
            <div>
              <p>
                Normalmente, aliciamento é muito utilizado para falar de abuso
                infantil. No entanto, aliciadores também têm crianças como alvo
                a fim de incentivar comportamentos radicais e violentos, tráfico
                de trocas, ganhos financeiros como mostrou essa r
                <u>
                  <a
                    href="https://g1.globo.com/fantastico/noticia/2023/05/01/crueldade-com-animais-menina-de-13-anos-coloca-fogo-em-gato-ao-vivo-em-transmissao-no-discord-e-causa-incendio-em-casa.ghtml"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    eportagem do Fantástico
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Aliciadores podem primeiro fazer amizade com uma criança ou
                adolescente - e até mesmo um adulto. Pode ser alguém que eles
                nunca conheceram pessoalmente. Pode ser alguém que finge ter a
                mesma idade do seu filho.
              </p>
              <p>
                Como a pessoa está atrás de uma tela, seu filho não tem como
                garantir que a pessoa é realmente quem diz ser.
              </p>
              <p>
                O comportamento pode variar, o como também, mas o foco está em
                ganhar a confiança da criança e poder manipulá-la para fazer o
                que quiserem.
              </p>
              <p>
                Crianças e adolescentes podem ter mais problemas para dizer não
                com quem eles construíram um relacionamento, tornando mais fácil
                o aliciamento acontecer.
              </p>
              <p>
                Aqui tem alguns sinais de que seu filho pode estar sendo
                aliciado. Observe.
              </p>
              <p>
                É por isso que é tão importante você ter acesso às contas do seu
                filho. Para conseguir preservar as evidências e entrar em
                contato com a polícia.
              </p>
              <p>
                <u>
                  <a
                    href="https://new.safernet.org.br/content/delegacias-cibercrimes"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui{" "}
                  </a>
                </u>
                uma lista de delegacias de crimes digitais. E no nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>{" "}
                você tem o passo a passo bem como as opções de lidar com isso
                protegendo seu filho.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="sexting"
          titulo="Conversar sobre sexting."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://new.safernet.org.br/content/sexting-%C3%A9-uma-express%C3%A3o-da-sexualidade-na-adolesc%C3%AAncia"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sexting é um exemplo de uso da Internet para expressão da
                    sexualidade na adolescência. É um fenômeno no qual os
                    adolescentes e jovens usam redes sociais, aplicativos e
                    dispositivos móveis para produzir e compartilhar imagens de
                    nudez e sexo. Envolve também mensagens de texto eróticas com
                    convites e insinuações sexuais para namorado(a),
                    pretendentes e/ou amigos(as). A palavra sexting já indica um
                    gap entre o discurso adulto e a experiência dos jovens.
                    Quando se pergunta aos adolescentes sobre sexting, nem
                    sempre eles conhecem ou usam essa palavra.
                  </a>
                </u>
              </p>
              <p>
                <u>
                  <a
                    href="https://new.safernet.org.br/content/sexting-%C3%A9-uma-express%C3%A3o-da-sexualidade-na-adolesc%C3%AAncia"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    É a junção da palavra sex (sexo) + texting (torpedo), tem
                    origem inglesa e surgiu quando a Internet nem era 3G e as
                    pessoas enviavam mensagens de texto por sms (Short Message
                    Service) de caráter erótico e sexual, hoje as mensagem são
                    fotos e vídeos por mms (multimedia messagem service).
                  </a>
                </u>
              </p>
              <p>
                O problema é que depois quem envia a mensagem com esse conteúdo
                perde o controle da imagem e podem usar esse conteúdo e
                compartilhar essas imagens de forma não consensual.
                <u>
                  <a
                    href="https://www.jusbrasil.com.br/artigos/nudes-vazar-foto-intima-sem-autorizacao-e-crime-saiba-como-proceder/607738095"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Lembrando que o compartilhamento de imagens íntimas sem
                    autorização é crime.
                  </a>
                </u>
              </p>
            </div>
          }
          como={
            <div>
              <p>
                É preciso conversar com seu adolescente sobre como isso acontece
                e fazer ele pensar:
              </p>
              <ul className="marcadores">
                <li>O que pode acontecer depois que ele envia ou recebe?</li>
                <li>Quem pode ver?</li>
                <li>Quais são os riscos?</li>
                <li>
                  Ele confia verdadeiramente para quem está enviando ou
                  recebendo?
                </li>
                <li>Por que ele quer mandar?</li>
                <li>E se ele mandar e isso vazar, o que ele pode fazer?</li>
              </ul>
              <p>
                Lembre-o de que se ele for menor de idade e se alguém vazar as
                imagens sem autorização dele, ele precisa pedir ajuda do
                responsável porque somente o responsável pode registrar a
                ocorrência e tomar as providências no site. 
              </p>
              <p>
                Denunciando as imagens e pedindo remoção. Aqui tem o link para
                caso as imagens estejam circulando nos ambiente do
                <u>
                  <a
                    href="https://about.meta.com/pt_BR/actions/safety/topics/bullying-harassment/ncii/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Meta
                  </a>
                </u>
                .
              </p>
              <p>
                Para educação sexual, vale a pena acompanhar este conteúdo{" "}
                <u>
                  <a
                    href="https://www.instagram.com/leilianerochapsicologa/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
              <p>
                Aprenda a fazer configurações de segurança para afastar
                desconhecidos nas redes sociais e jogos do seu filho no nosso{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="cyberbullying"
          titulo="Conversar sobre cyberbullying."
          porque={
            <div>
              <p>
                O bullying se caracteriza por ser um comportamento com intenção
                de perturbar, intimidar e/ou agredir, ser repetido ao longo do
                tempo e ter desequilíbrio de poder.
              </p>
              <p>
                O que aumenta o problema é que ele também pode acontecer pela
                internet. No Brasil, nós temos uma
                <u>
                  <a
                    href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13185.htm"
                    title="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13185.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    lei de combate ao bullying e ou cyberbullying
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                A família e a escola precisam identificar e tratar juntos porque
                podem ter crianças e adolescentes sofrendo nos seus ambientes e
                tem mais acesso a verificar as mudanças e traços no seu
                comportamento.
              </p>
              <p>
                O cyberbullying pode ser denunciado e os agressores punidos
                quando provado ato infracional caso não solucionado de forma
                mais simples, com a mediação dos conflitos ou com a remoção do
                conteúdo que prejudica alguém - e a maioria das plataformas já
                possuem formas de denúncia desse tipo de conteúdo.
              </p>
              <p>
                <u>
                  <a
                    href="https://www.terra.com.br/noticias/brasil-e-o-2-pais-com-mais-casos-de-cyberbullying-no-mundo-segundo-pesquisa,35ba9c6294b9001064c48b892eb65cfdvn0vwms8.html"
                    title=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Com o Brasil nos ranking de casos de cyberbullying
                  </a>
                </u>
                , é preciso conversar sobre o assunto, preparar as crianças,
                realizar atividades preventivas e estar pronto para lidar quando
                (e se) acontecer.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="odio"
          titulo="Conversar sobre discursos de ódio na internet."
          porque={
            <div>
              <p>
                <u>
                  <a
                    href="https://new.safernet.org.br/content/crimes-de-odio-tem-crescimento-de-ate-650-no-primeiro-semestre-de-2022"
                    title="https://new.safernet.org.br/content/crimes-de-odio-tem-crescimento-de-ate-650-no-primeiro-semestre-de-2022"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Segundo a Safernet
                  </a>
                </u>
                , crimes de ódio nas redes sociais triplicaram nos últimos 6
                anos. O maior aumento foi nos crimes de ódio contra as mulheres,
                mas também houve agressões envolvendo intolerância religiosa,
                racismo e aversão a estrangeiros.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Como o ódio na internet pode ser contra uma pessoa ou grupo com
                característica normalmente protegida por lei sendo raça ou
                etnia, idade, sexo, orientação sexual, religião, por exemplo,
                isso significa que seu filho pode encontrar esse tipo de
                conteúdo violento na internet. 
              </p>
              <p>
                Incentive o{" "}
                <u>
                  <a
                    href="https://new.safernet.org.br/denuncie"
                    title="https://new.safernet.org.br/denuncie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    denunciar
                  </a>
                </u>{" "}
                ou você pode revisar os conteúdos disponíveis nas configurações
                de controle parental para que você crie um espaço mais positivo
                na internet para ele.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="radicalizacao"
          titulo="Conversar sobre radicalização online."
          porque={
            <div>
              <p>
                Com acesso livre a internet há uma chance do seu filho conhecer
                pessoas online ou entrar em sites que possam levá-lo a ter
                visões extremas.
              </p>
              <p>
                A curiosidade pode levar seu filho a procurar essas pessoas ou
                ele pode ser aliciado. 
              </p>
              <p>
                A radicalização é um processo pelo qual as pessoas, geralmente
                jovens, passam a apoiar visões ideológicas extremas. Ela torna
                as pessoas em risco mais propensas a apoiar o terrorismo e os
                atos violentos de extremismo, e{" "}
                <u>
                  <a
                    href="https://noticias.r7.com/sao-paulo/autor-de-ataque-em-escola-falou-do-crime-em-rede-social-quem-curtiu-sera-investigado-diz-secretario-22082023"
                    title="https://noticias.r7.com/sao-paulo/autor-de-ataque-em-escola-falou-do-crime-em-rede-social-quem-curtiu-sera-investigado-diz-secretario-22082023"
                    target="_blank"
                    rel="noreferrer"
                  >
                    possivelmente até cometer tais atos criminosos
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Como nós tivemos ameaças pelas redes sociais nos últimos
                períodos, o Ministério da Justiça e Segurança Pública, em
                parceria com SaferNet Brasil, criou um canal exclusivo para
                recebimento de informações sobre ameaças e ataques contra as
                escolas.
              </p>
              <p>
                Você pode acessar{" "}
                <u>
                  <a
                    href="https://www.gov.br/mj/pt-br/canais-de-denuncias/escolasegura"
                    title="https://www.gov.br/mj/pt-br/canais-de-denuncias/escolasegura"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="conversar"
          valor="automutilacao"
          titulo="Conversar sobre automutilação, brincadeiras perigosas e suicídio."
          porque={
            <div>
              <p>
                Como vimos até aqui, a internet pode ter uma série de conteúdos
                perigosos para crianças e adolescentes em que elas não percebem
                o perigo ou procuram por ele. 
              </p>
              <p>
                A automutilação é frequentemente entendida como uma resposta
                física a algum tipo de dor emocional e pode ser muito viciante.
              </p>
              <p>
                E outros vídeos com{" "}
                <u>
                  <a
                    href="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    title="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    brincadeiras entre crianças e adolescentes, podem ser jogos
                    com desafios perigosos como cortar a passagem de ar para o
                    cérebro, provocando o desmaio. 
                  </a>
                </u>
              </p>
              <p>
                <u>
                  <a
                    href="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    title="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    O objetivo da prática é a busca de uma sensação de euforia
                    ou alucinatória. 
                  </a>
                </u>
              </p>
              <p>
                <u>
                  <a
                    href="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    title="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    As brincadeiras abordam ainda outras formas de desafios
                    propostas a jovens via internet e/ou entre amigos e grupos,
                    vistas como seguras, sendo as mesmas de alto risco,
                    agravadas pela falta de informação e conscientização das
                    consequências
                  </a>
                </u>
                .
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Existem muitas razões pelas quais os jovens podem começar a se
                autoagredir. Razões familiares, como não se dar bem com outros
                membros da família ou com os pais se divorciarem, podem ser o
                gatilho.
              </p>
              <p>
                Mas também a mais{" "}
                <u>
                  <a
                    href="https://www.techtudo.com.br/listas/2023/09/live-npc-xixi-e-mais-8-vezes-em-que-as-trends-do-tiktok-foram-longe-demais.ghtml"
                    title="https://www.techtudo.com.br/listas/2023/09/live-npc-xixi-e-mais-8-vezes-em-que-as-trends-do-tiktok-foram-longe-demais.ghtml"
                    target="_blank"
                    rel="noreferrer"
                  >
                    nova trend onde ficaram curiosos
                  </a>
                </u>
                .
              </p>
              <p>
                Como pai, pode ser bastante perturbador se você suspeitar que
                seu filho está se machucando. No entanto, é importante manter a
                calma e tentar falar abertamente com ele sobre isso.
              </p>
              <p>
                Veja alguns sinais de brincadeiras perigosas{" "}
                <u>
                  <a
                    href="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    title="http://www.institutodimicuida.org.br/brincadeiras-perigosas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>
                , identifique os de suicídio{" "}
                <u>
                  <a
                    href="https://www.gov.br/saude/pt-br/assuntos/saude-de-a-a-z/s/suicidio-prevencao"
                    title="https://www.gov.br/saude/pt-br/assuntos/saude-de-a-a-z/s/suicidio-prevencao"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </u>{" "}
                e não hesite em procurar{" "}
                <u>
                  <a
                    href="https://www.cvv.org.br/wp-content/uploads/2017/05/guia_CVV_pais_educadores_DIGITAL.pdf"
                    title="https://www.cvv.org.br/wp-content/uploads/2017/05/guia_CVV_pais_educadores_DIGITAL.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    mais informações
                  </a>
                </u>{" "}
                no{" "}
                <u>
                  <a
                    href="https://www.cvv.org.br/"
                    title="https://www.cvv.org.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CVV
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.conversar.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="conversar"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};
export default Step9;

import React, { useState } from 'react';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.css';
import { Membro } from './pojos/Membro';

interface FaixaEtariaProps {
    membro: Membro;
    atualizarMembro: (membro: Membro) => void;
    deletaMembro: () => void;
  }
 
  const FaixaEtaria: React.FC<FaixaEtariaProps> = ({ membro, atualizarMembro, deletaMembro }) => {
  const [selectedButton, setSelectedButton] = useState(membro.faixa.toString());
  const handleButtonClick = (value:any) => {
    if (value === null) {
      return;
    }
    setSelectedButton(value);
    membro.faixa = +value;
    atualizarMembro(membro)
   
  };

  return (
    <>
    <div className='flex '>
    <div className='flex flex-col'>
        <span className='text-[1rem] text-Marinho font-bold'>Nome do familiar</span>
        <input type='text' id='familiar'     defaultValue={membro.nome}
        onChange={(e) => {
            membro.nome = e.target.value
            atualizarMembro(membro)
        }} className='border-RoyalRoxo border-solid border-[2px] rounded-none w-[20vw] h-[45px] pl-3 sm:w-[20vw]  
        tablet:w-[25vw] tablet:mr-1
        mobile-lg:w-[90vw]  mobile-lg:mr-1
        mobile-md:w-[80vw]  mobile-md:mr-1
        '></input>
        
    </div>

    <ToggleButtonGroup className='mt-4 sm:mt-4 
    mobile-lg:relative mobile-lg:w-[90vw] mobile-lg:mt-[8vh] mobile-lg:ml-[-92vw]
    mobile-md:relative mobile-md:w-[90vw] mobile-md:mt-[10.5vh] mobile-md:ml-[-83vw]'
          value={selectedButton}
          exclusive
          onChange={(event, value) => handleButtonClick(value)}
          aria-label="seleção de botões"
      >
          <ToggleButton
              value="0"
              style={{
                  backgroundColor: selectedButton === '0' ? '#351e98' : '#ada2db',
                  color: selectedButton === '0' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
                  
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[8vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[13vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`} 
          >
              <span className='text-[0.7rem]'>0 a 24 meses</span>
          </ToggleButton>

          <ToggleButton
              value="1"
              style={{
                  backgroundColor: selectedButton === '1' ? '#351e98' : '#ada2db',
                  color: selectedButton === '1' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[8vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[13vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`}
          >
              <span className='text-[0.7rem]'>2 a 5 anos</span>
          </ToggleButton>

          <ToggleButton
              value="2"
              style={{
                  backgroundColor: selectedButton === '2' ? '#351e98' : '#ada2db',
                  color: selectedButton === '2' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[8vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[13vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`}
          >
              <span className='text-[0.7rem]'>6 a 11 anos</span>
          </ToggleButton>

          <ToggleButton
              value="3"
              style={{
                  backgroundColor: selectedButton === '3' ? '#351e98' : '#ada2db',
                  color: selectedButton === '3' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[9vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[15vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`}
          >
              <span className='text-[0.7rem]'>12 a 17 anos</span>
          </ToggleButton>

          <ToggleButton
              value="4"
              style={{
                  backgroundColor: selectedButton === '4' ? '#351e98' : '#ada2db',
                  color: selectedButton === '4' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[10vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[15vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`}
          >
              <span className='text-[0.7rem]'>18 a 59 anos</span>
          </ToggleButton>

          <ToggleButton
              value="5"
              style={{
                  backgroundColor: selectedButton === '5' ? '#351e98' : '#ada2db',
                  color: selectedButton === '5' ? 'white' : 'black',
                  fontFamily: 'codec-pro-extrabold',
                  borderRadius: '15px',
                  margin: '5px 2px',
                  lineHeight: '1',
              }}
              className={`w-[7vw] sm:w-[8vw] sm:h-[7vh] tablet:w-[8vw] tablet:h-[6vh] mobile-lg:h-[6vh] mobile-lg:w-[10vw] mobile-md:h-[6vh] mobile-md:w-[13vw]`}
          >
              <span className='text-[0.7rem]'>60+</span>
          </ToggleButton>

      </ToggleButtonGroup>
          <IconButton aria-label="delete" className='hover:bg-white ' onClick={deletaMembro}>
          <DeleteIcon  className="icon-red mt-3 mobile-lg:mt-[8vh] mobile-lg:ml-[-1vw] mobile-md:mt-[-4vh] mobile-md:ml-[-20vw]"/>
      </IconButton>
      </div>
      </>

  );
}
export default FaixaEtaria;

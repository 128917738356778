import proteka from "../home/proteka.png";
import CardEquilibrio from "../cards/CardEquilibrio";
import { Dados } from "../pojos/Dados";
import { Membro } from "../pojos/Membro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardAdicional from "../cards/CardAdicional";

interface Step10Props {
  dados: Dados;
  setDados: React.Dispatch<React.SetStateAction<Dados>>;
}

const Step10: React.FC<Step10Props> = ({ dados, setDados }) => {
  const atualizarMembro = (mebros: Membro[]) => {
    setDados({ ...dados, membros: mebros });
  };

  const setPergunta = (perguntaOld: string, perguntaNew: string) => {
    const pergunta = dados.pergunta.folga;
    const index = pergunta.indexOf(perguntaOld);
    pergunta[index] = perguntaNew;
    dados.membros.forEach((membro) => {
      const indexM = membro.folga.adicionais.indexOf(perguntaOld);
      if (indexM >= 0) {
        membro.folga.adicionais[indexM] = perguntaNew;
      }
    });
    setDados({ ...dados });
  };

  const adicionaCard = () => {
    setDados({
      ...dados,
      pergunta: { ...dados.pergunta, folga: [...dados.pergunta.folga, ""] },
    });
  };

  return (
    <div className="min-h-[46vh] w-max-[100vw] sm:ml-[-10vw] tablet:ml-[-14vw] mobile-lg:ml-[-14vw] mobile-md:ml-[-14vw]">
    <div className="flex mt-10">
      <div>
        <img className="max-w-[10vw] h-auto ml-[2vw] mobile-lg:max-w-[25vw] mobile-md:max-w-[25vw]" src={proteka} alt="" />
      </div>

      <span className="flex justify-center m-auto ml-10 text-5xl font-codecProBold text-Marinho pt-6 max-w-[100%] mobile-lg:text-[2.2rem] mobile-lg:ml-[5vw] mobile-md:text-[2.2rem] mobile-md:ml-[5vw]">
          Feriados, recessos e férias
        </span>
      </div>

      <div id="cards" className="m-0 p-0">
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="atividade"
          titulo="Ter  a lista de atividades mais importantes que gostamos mais do que ficar de frente a tela. Ao diminuir o tempo de tela, teremos mais tempo para incluir atividades que seu filho e cada pessoa da sua família gosta."
          porque={
            <div>
              <p>
                Nas férias as crianças ganham um tempo adicional: os momentos
                que elas levariam para se arrumar para a escola, fazer dever de
                casa, ir para a escola e dentro da escola passa a ser
                direcionado apenas para a casa, se a família não tiver planos
                para viajar ou mais nada.
              </p>
              <p>
                Esse tempo normalmente é usado de frente a alguma tela se a
                gente não tiver um plano prévio para isso.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Faça uma lista com seus filhos sobre tudo o que gostariam de
                fazer e é possível.
              </p>
              <p>
                Consulte
                <u>
                  <a
                    href="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    title="https://proteka.notion.site/Atividades-sem-telas-990a1ab74c6d4e02b3c41dae699bc3c6#bcacc93020a5437781eef85ede6693b5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    nossa lista de atividades
                  </a>
                </u>{" "}
                e veja o que dá para fazer.
              </p>
              <p>
                Prepare com antecedência atividades que eles queiram mais para
                depois do tempo de tela. Como logo em seguida vem algo que eles
                querem muito fazer, mais fácil será sair da tela.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="sequencia"
          titulo="Definir uma sequência de atividades que precisam ser feitas antes de ligar a tv ou usar o tablet ou o computador."
          porque={
            <div>
              <p>
                Para não perder todo o dia de frente a uma tela,{" "}
                <u>
                  <a
                    href="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                    title="https://www.rcpch.ac.uk/news-events/news/build-screen-time-around-family-activities-not-other-way-round-parents-told"
                    target="_blank"
                    rel="noreferrer"
                  >
                    crie tempo de tela em torno das atividades familiares, e não
                    o contrário
                  </a>
                </u>
                , para garantir um equilíbrio saudável e priorizar as
                experiências e momentos compartilhados em família. Aqui nós
                vamos deixar espaços preferenciais para as atividades essenciais
                para a saúde. Sono, higiene pessoal, alimentação, brincadeiras,
                atividades físicas.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Defina em tarefas sequenciais tudo o que seu filho precisa fazer
                antes de ter acesso às telas.
              </p>
              <p>
                Não faça da tela uma coisa aleatória e{" "}
                <u>
                  <a
                    href="https://www.instagram.com/reel/CyMjjSwgE75/?igshid=MTc4MmM1YmI2Ng=="
                    title="https://www.instagram.com/reel/CyMjjSwgE75/?igshid=MTc4MmM1YmI2Ng=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    veja o vídeo
                  </a>
                </u>{" "}
                para entender o porquê.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="essencial"
          titulo="Definir as atividades essenciais para a manutenção da saúde das crianças e adolescentes durante os dias da semana (de segunda a sexta-feira)."
          porque={
            <div>
              <p>
                Muitas vezes a semana dá o tom também do final de semana porque
                se logo na segunda-feira a rotina tem atividades mais exigentes,
                naturalmente a ideia de diminuir o ritmo no domingo pode vir a
                acontecer.
              </p>
              <p>
                <u>
                  <a
                    href="https://www.fmcsv.org.br/pt-BR/biblioteca/primeirissima-infancia---da-gestacao-aos-3-anos/"
                    title="https://www.fmcsv.org.br/pt-BR/biblioteca/primeirissima-infancia---da-gestacao-aos-3-anos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A rotina exerce influência fundamental no desenvolvimento
                    infantil
                  </a>
                </u>
                . Entre outros fatores, ela ensina a criança a conviver com a
                realidade familiar além de contribuir para o fortalecimento da
                autonomia.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Determine as atividades comuns da família e individuais que cada
                um dos seus filhos têm. Em seguida, atribua o tempo necessário
                para seus filhos realizarem cada uma de suas atividades.
              </p>
              <ul className="pl-3 ml-2 list-disc mt-5 mb-5">
                <li>Dormir</li>
                <li>Escola</li>
                <li>Leitura</li>
                <li>Atividade física ou esportes</li>
                <li>
                  Cuidado pessoal (banho, trocar de roupa, escovar os dentes)
                </li>
                <li>Refeições</li>
                <li>Trabalho de casa</li>
                <li>Tempo para a família</li>
                <li>Atividades depois da escolha</li>
                <li>Tempo livre</li>
                <li>Tempo de tela</li>
                <li>Outros</li>
              </ul>
              <p>
                Perceba que muitas das atividades podem não caber no tempo
                disponível de um dia, bem como na importância das tarefas
                durante a semana.
              </p>
              <p>
                O que for importante e possível de ser feito no final de semana,
                transfira para ele.
              </p>
              <p>
                Lembre-se que na formação dos bons hábitos é preciso repetição e
                flexibilidade para incluir a dinâmica da vida.
              </p>
              <p>
                Se quiser, assista a aula de rotina do{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="final"
          titulo="Definir as atividades essenciais para a manutenção da saúde das crianças e adolescentes durante o final de semana (de sábado a domingo)."
          porque={
            <div>
              <p>
                Não é porque é final de semana que nós deixamos de fazer algumas
                coisas básicas como dormir, ir ao banheiro, escovar os dentes ou
                comer.
              </p>
              <p>
                Definir o que é importante de se manter, mesmo aos finais de
                semana, ajuda.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Inclua todas as coisas que precisam ser mantidas durante a
                semana e, quanto menor for seu filho mais claro você pode ser em
                relação a ordem de execução das tarefas.
                <br />
                Exemplo:
                <br />
                Acordar, dar um cheirinho na mamãe e no papai, ir escovar os
                dentes, fazer xixi - se quiser, (inclua a ordem a seguir ou crie
                a sua).
              </p>
              <p>
                Dê também tempo para incluir o que não fizeram durante a semana,
                como ir ao parque, por exemplo.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="evento"
          titulo="Definir quais serão as nossas regras para uso de telas quanto aos eventos especiais e datas festivas ao longo do ano - reuniões familiares, localizadores para festas de final de ano."
          porque={
            <div>
              <p>
                Eventualmente nós temos datas especiais ao longo do ano e é bom
                estar preparado para não se tornar mais tempo de tela - e mais
                isolamento.
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Como falamos no passo anterior, inclua todas as coisas que
                precisam ser mantidas durante a semana e, quanto menor for seu
                filho mais claro você pode ser em relação a ordem de execução
                das tarefas.
                <br />
                Dê também tempo para incluir todas as exceções que as datas ou
                momentos incluem.
              </p>
            </div>
          }
        />
        <CardEquilibrio
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
          objetivo="folga"
          valor="logica"
          titulo="Definir qual será a consequência lógica relacionada, proporcional e educativa caso seu filho não siga o combinado."
          porque={
            <div>
              <p>
                Nós chegamos até aqui com o objetivo de aproximar a família para
                conversar sobre o uso de tecnologia e a gente ensina a usar para
                que futuramente, as crianças e adolescentes tenham liberdade
                autonomia e segurança na internet quando se tornarem adultos.
              </p>
              <p>
                No entanto sabemos que nem sempre as crianças e adolescentes
                podem ter bons comportamentos online e um dizer antigo que vai
                se aplicar também na internet é "liberdade com
                responsabilidade". E isso significa que ao longo do nosso dia a
                dia, nós fazemos escolhas e essas escolhas têm consequências.
              </p>
              <p>
                <u>
                  <a
                    href="https://www.instagram.com/p/Cf_X9WwhHy6/"
                    title="https://www.instagram.com/p/Cf_X9WwhHy6/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aqui
                  </a>
                </u>{" "}
                eu diferencio um artigo que fala dos tipos de parentalidade em
                relação às telas e no
                <u>
                  <a
                    href="https://www.theatlantic.com/technology/archive/2015/11/why-parents-shouldnt-feel-technology-shame/414163/"
                    title="https://www.theatlantic.com/technology/archive/2015/11/why-parents-shouldnt-feel-technology-shame/414163/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    artigo fonte
                  </a>
                </u>
                , os pais que limitam a todo custo perdem muitas oportunidades
                de preparar seus filhos para o mundo que habitarão (o mundo
                digital).
              </p>
            </div>
          }
          como={
            <div>
              <p>
                Não use a tecnologia para punir seu filho. Como crianças e
                adolescentes ainda estão aprendendo, considere que seu filho vai
                errar.
              </p>
              <p>
                Reduza as possibilidades dos erros. Como pais e responsáveis
                escolham um hábito que gostariam que seu filho adquirisse e
                facilite em detalhes.
              </p>
              <p>
                Lembre-se da consequência negativa uma vez que seu filho fizer
                uma escolha ruim. Mas que seja uma consequência relacionada ao
                erro, proposital e com objetivo educativo para ele.
              </p>
              <p>
                Você pode consultar a aula de rotina - porque falamos de
                consequências lógicas relacionadas - do{" "}
                <u>
                  <a
                    href="https://novoguiadecontroleparental.club.hotmart.com/"
                    title="https://novoguiadecontroleparental.club.hotmart.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guia de controle parental
                  </a>
                </u>
                .
              </p>
            </div>
          }
        />
      </div>
      {dados.pergunta.folga.map((pergunta, index) => (
        <CardAdicional
          key={index}
          objetivo="folga"
          setPergunta={setPergunta}
          pergunta={pergunta}
          atualizarMembros={atualizarMembro}
          membros={dados.membros}
        />
      ))}

      <button
        onClick={adicionaCard}
        className="ml-[2vw] mt-16 mb-2 p-2 pt-3 pl-6 pr-6 text-[0.8rem] font-codecProBold bg-RoyalRoxo text-white hover:bg-[#4020c9]"
      >
        ADICIONAR OUTRO <AddCircleOutlineIcon className="ml-2 mb-1" />
      </button>
    </div>
  );
};

export default Step10;

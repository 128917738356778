export class Conversar {
  [key: string]: any;
  frequencia: boolean;
  favorito: boolean;
  atencao: boolean;
  carro: boolean;
  publico: boolean;
  cotidiana: boolean;
  recreativo: boolean;
  inadequado: boolean;
  noticia: boolean;
  reputacao: boolean;
  identidade: boolean;
  aliciamento: boolean;
  sexting: boolean;
  cyberbullying: boolean;
  odio: boolean;
  radicalizacao: boolean;
  automutilacao: boolean;
  adicionais: string[];

  constructor() {
    this.frequencia = true;
    this.favorito = true;
    this.atencao = true;
    this.carro = true;
    this.publico = true;
    this.cotidiana = true;
    this.recreativo = true;
    this.inadequado = true;
    this.noticia = true;
    this.reputacao = true;
    this.identidade = true;
    this.aliciamento = true;
    this.sexting = true;
    this.cyberbullying = true;
    this.odio = true;
    this.radicalizacao = true;
    this.automutilacao = true;
    this.adicionais = [];
  }
}

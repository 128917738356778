import { Link } from 'react-router-dom'
import instagram from './instagram.svg'
export default function Rodape(){
    return (

     

        <div className='flex max-w-[100vw] h-auto bg-BgHome pb-10 '>


      <div className='block t40px items-center text-white text-xl font-codecProBold ml-[10vw] leading-none 
      tablet:text-[1.5rem] tablet:w-[40vw] 
      mobile-lg:text-[1.0rem] mobile-lg:w-[40vw]
      mobile-md:text-[1.0rem] mobile-md:w-[40vw]

      '>
        <div className='flex'><span>TEMPO DE TELA</span></div>     
        <br/>
        <div> <hr/><br/>
          <span className='text-xl mt-3 font-sans'>
          <Link to={'/Termos'} className="nav-link">Termos e Condições de Uso</Link>
          </span></div>
         
      </div>
     
      <div className='flex flex-col text-white text-2xl font-codecProBold mt-10 ml-[40%] 
      sm:ml-[35%] 
      tablet:ml-[35%] 
      mobile-lg:ml-[20%] 
      mobile-md:ml-[20%] 
      md:ml-[45%] 
      lg:ml-[35%] 
      xl:ml-[35%]
      '>
          <div className='flex'><span>@ 2023 PROTEKA</span>
          
          <a href="https://www.instagram.com/gabriellakohatsu/" target="_blank" rel="noreferrer">
          <img className='w-8 ml-5 note:mr-[10vw] tablet:mr-[10vw] mobile-lg:mr-[15vw] mobile-md:mr-[15vw]  ' src={instagram} alt='Instagram' />
          </a>
          </div>
          <span className='text-xl mt-3 font-sans'><a href="https://satty.com.br" target="_blank" rel="noreferrer">Powered by Satty</a></span>
      </div>

</div>

     
)}